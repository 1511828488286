import FaqQuestion from './FaqQuestion'

const FaqSection = ({ data }) => {
    const { sectionTitle, sectionContent } = data

    return (
        <div className="faq_section">
            <p className="faq_section_title">{sectionTitle}</p>
            <ul className="faq_section_list">
                {Object.entries(sectionContent).map(([key, element]) => {
                    return <FaqQuestion questionElement={element} key={key} />
                })}
            </ul>
        </div>
    )
}
export default FaqSection
