export const paymentAPICredentials = (apiObj) => {
    return {
        tenant: apiObj.data.results[0].tenant,
        is_payment_enabled: apiObj.data.results[0].is_payment_enabled,
        p24_pos_id: apiObj.data.results[0].p24_pos_id,
        p24_crc: apiObj.data.results[0].p24_crc,
        p24_secret_id: apiObj.data.results[0].p24_secret_id
    }
}

export default paymentAPICredentials
