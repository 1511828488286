import { Table } from 'evergreen-ui'

import { useSelector } from 'react-redux'
import dateConvert from '../../utils/formatDate'
import { renderState } from '../../utils/renderState'

import renderMeterIconType from '../../utils/renderMeterIconType'

const MeasurementUserRow = ({ row }) => {
    const size = 16
    const meter = useSelector((state) => state.meter.meters)
    const withoutTime = () => {
        return dateConvert(row.updatedAt).split(',')[0]
    }

    const renderValue = () => {
        const value = row.value.replace('.', ',')
        return `${value} ${meter[row.meter]?.unit || ''}`
    }

    return (
        <Table.Row className="row">
            <Table.TextCell>
                <span className="meter_type">{renderMeterIconType(meter[row.meter], size)}</span>
            </Table.TextCell>
            <Table.TextCell>{renderValue()}</Table.TextCell>
            <Table.TextCell>
                <span>{renderState(row.state, 16)}</span>
            </Table.TextCell>
            <Table.TextCell>{withoutTime()}</Table.TextCell>
        </Table.Row>
    )
}

export default MeasurementUserRow
