import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { useSelector } from 'react-redux'

import { Table, Tooltip, Pane, Dialog, Checkbox } from 'evergreen-ui'
import { BiDownArrowAlt, BiUpArrowAlt, BiFilterAlt } from 'react-icons/bi'
import { FaFile } from 'react-icons/fa'
import { BsCameraFill, BsFillPersonFill } from 'react-icons/bs'
import { MdOutlineImage, MdOutlineImageNotSupported } from 'react-icons/md'
import Zoom from 'react-medium-image-zoom'

import MeterExpandedRow from './MeterExpandedRow'

import dateConvert from '../../../utils/formatDate'
import renderMeterIconType from '../../../utils/renderMeterIconType'

const MeterDetailRow = ({
    row,
    isExpanded,
    onExpand,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate
}) => {
    const size = 16
    const navigate = useNavigate()

    const user = useSelector((state) => state.user.users[row.tenantMember])
    const tenant = useSelector((state) => state.tenant.tenants[user?.tenant])
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    const [isImageShown, setIsImageShown] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const isPrivate = row.tenantMember === null

    const handleShowImageClick = () => {
        setIsImageShown(true)
    }

    useEffect(() => {
        if (isSelectedEveryRow) setIsSelected(isSelectedEveryRow)
        else if (!isSelectedEveryRow && !isSelectedEveryRowIndeterminate) setIsSelected(false)
    }, [isSelectedEveryRow, isSelectedEveryRowIndeterminate])

    const renderImageButton = () => {
        const renderName = () => {
            if (row.serialNumber) return row.serialNumber
            if (!row.serialNumber && row.image) return 'Zdjęcie'
            return 'Brak'
        }

        if (row.image) return [<MdOutlineImage size="18" fill="green" key="image" />, renderName()]
        return [<MdOutlineImageNotSupported size="18" fill="orange" key="image" />, renderName()]
    }

    const renderUserName = () => {
        if (isPrivate) {
            return '-'
        }
        if (user?.isCompany && user?.companyName === '' && user?.nameSurname === '') {
            return '-'
        }
        if (user?.isCompany && user?.companyName === '') {
            return user?.nameSurname
        }
        if (user?.isCompany) {
            return user?.companyName
        }
        return user?.nameSurname
    }

    const handleSwitchView = (view) => {
        navigate(view)
    }

    const handleSelectClick = (e) => {
        const newState = e.target.checked
        setIsSelected(newState)
        onRowSelected(row.pk)
    }

    return (
        <Table.Row
            className={`row ${isSelected ? 'selected' : ''} ${isExpanded ? 'in_color' : ''}`}
        >
            {isModerator && (
                <Table.TextCell flexBasis="40px" flexGrow={0} flexShrink={0}>
                    <Checkbox checked={isSelected} onChange={(e) => handleSelectClick(e)} />
                </Table.TextCell>
            )}
            {!isModerator && <Table.TextCell>{row.name}</Table.TextCell>}
            <Table.TextCell>
                <Tooltip content="Przejdź do odbiorcy" showDelay={500}>
                    <Link to={`/users?user_id=${row.tenantMember}`}>{renderUserName()}</Link>
                </Tooltip>
            </Table.TextCell>
            {isModerator && (
                <Table.TextCell>
                    {user?.isCompany ? 'Instytucjonalny' : 'Indywidualny'}
                </Table.TextCell>
            )}
            {!isModerator && <Table.TextCell>{tenant?.name ? tenant?.name : '-'}</Table.TextCell>}
            <Table.TextCell>
                <span className="meter_type">{renderMeterIconType(row, size)}</span>
            </Table.TextCell>
            <Table.TextCell>
                <button
                    type="button"
                    className="pictureButton"
                    onClick={handleShowImageClick}
                    disabled={!row.image}
                >
                    {renderImageButton()}
                </button>
            </Table.TextCell>
            <Table.TextCell>
                <span> {dateConvert(row.createdAt)}</span>
            </Table.TextCell>
            <Table.TextCell flexBasis="180px" flexShrink={0} flexGrow={0}>
                <div className={`button_container ${isExpanded ? 'container_expanded' : ''}`}>
                    {!isExpanded && (
                        <>
                            <Tooltip content="Filtruj po odbiorcy" showDelay={500}>
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() =>
                                        handleSwitchView(`/meters?user_id=${row.tenantMember}`)
                                    }
                                >
                                    <BiFilterAlt size="18" />
                                </button>
                            </Tooltip>
                            <Tooltip content="Przejdź do odbiorcy" showDelay={500}>
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() =>
                                        handleSwitchView(`/users?user_id=${row.tenantMember}`)
                                    }
                                >
                                    <BsFillPersonFill size="18" />
                                </button>
                            </Tooltip>
                            <Tooltip content="Przejdź do odczytów" showDelay={500}>
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() => handleSwitchView(`/readings?meter_id=${row.pk}`)}
                                >
                                    <BsCameraFill size="18" />
                                </button>
                            </Tooltip>
                            <Tooltip content="Przejdź do dokumentów" showDelay={500}>
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() =>
                                        handleSwitchView(`/documents?meter_id=${row.pk}`)
                                    }
                                >
                                    <FaFile size="18" />
                                </button>
                            </Tooltip>
                        </>
                    )}
                </div>
            </Table.TextCell>
            <Table.TextCell className="gap" />
            <Table.TextCell className="alone_row">
                <Tooltip
                    content="Szczegóły"
                    showDelay={500}
                    key={!isExpanded ? 0 : 1}
                    position="top"
                >
                    <button type="button" onClick={onExpand} className="clickable">
                        {isExpanded ? (
                            <BiUpArrowAlt size="20" className="icon_white" />
                        ) : (
                            <BiDownArrowAlt size="20" />
                        )}
                    </button>
                </Tooltip>
            </Table.TextCell>

            <Pane>
                <Dialog
                    isShown={isImageShown}
                    title="Zdjęcie licznika"
                    onCloseComplete={() => setIsImageShown(false)}
                    hasFooter={false}
                >
                    <Zoom>
                        <div className="dialog_image_container">
                            <img src={row.image} alt="meter" />
                        </div>
                    </Zoom>
                </Dialog>
            </Pane>
        </Table.Row>
    )
}

const MeterRow = ({ row, onRowSelected, isSelectedEveryRow, isSelectedEveryRowIndeterminate }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const [urlParams, setUrlParams] = useSearchParams()

    const handleExpandChange = () => {
        setIsExpanded((current) => !current)
    }

    useEffect(() => {
        const userParam = parseInt(urlParams.get('meter_id'), 10)
        if (!Number.isNaN(userParam)) {
            if (userParam === row.pk) setIsExpanded(true)
        }
    }, [])

    return (
        <>
            <MeterDetailRow
                row={row}
                isExpanded={isExpanded}
                onExpand={handleExpandChange}
                onRowSelected={onRowSelected}
                isSelectedEveryRow={isSelectedEveryRow}
                isSelectedEveryRowIndeterminate={isSelectedEveryRowIndeterminate}
            />
            <MeterExpandedRow row={row} isExpanded={isExpanded} />
        </>
    )
}

export default MeterRow
