import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import Footer from '../../components/Footer'

import useApi from '../../hooks/useApi'

import { setStatistics } from '../../redux/slices/statisticSlice'
import { setUserData } from '../../redux/slices/profileSlice'

import { fromGetStatisticApi } from '../../utils/Adapters/StatisticApiAdapter'
import { fromGetUserDataApi } from '../../utils/Adapters/ProfileApiAdapter'

import '../../styles/views/home.scss'
import '../../styles/components/table.scss'
import UserCards from '../../components/homeCards/UserCards'
import MeterCards from '../../components/homeCards/MeterCards'
import MeasurementCards from '../../components/homeCards/MeasurementCards'

const HomeModerator = () => {
    const dispatch = useDispatch()

    const profile = useSelector((state) => state.profile.profile)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const userID = useSelector((state) => state.profile.profile.id)
    const statistics = useSelector((state) => state.statistic.statistics)

    const [timeNow, setTimeNow] = useState(new Date().toLocaleTimeString().slice(0, 5))
    const dateNow = new Date().toLocaleDateString()

    useApi({
        url: `/app/tenants/user/${userID}/`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetUserDataApi,
        queryName: ['UserData'],
        onSuccess: (data) => dispatch(setUserData(data))
    })

    useApi({
        url: `/app/meters/moderator/statistic/`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetStatisticApi,
        queryName: ['statiscits'],
        onSuccess: (data) => dispatch(setStatistics(data))
    })

    useEffect(() => {
        const time = setInterval(() => {
            setTimeNow(new Date().toLocaleTimeString().slice(0, 5))
        }, 1000)

        return () => clearInterval(time)
    }, [])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="home" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Witaj, {profile.name}</div>
                    <div className="title_locales">
                        <span> {dateNow} </span>
                        <span> {timeNow} </span>
                    </div>
                </div>

                <div className="container">
                    <div className="home">
                        <div className="statistic_container">
                            <div className="title">
                                <span>Statystyki systemu:</span>
                            </div>
                            <div className="card_box">
                                <UserCards statistics={statistics} />
                                <div className="meters_and_measurements_box">
                                    <MeterCards statistics={statistics} />
                                    <MeasurementCards statistics={statistics} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomeModerator
