/* eslint-disable camelcase */
class NotificationLog {
    constructor(values = {}) {
        const {
            pk,
            created_by,
            title,
            body,
            image,
            data,
            channel,
            recipient,
            tenant_members,
            created_at,
            document,
            index
        } = values

        this.pk = pk || null
        this.createdBy = created_by || null
        this.title = title || ''
        this.body = body || ''
        this.image = image || ''
        this.data = data || {}
        this.channel = channel || []
        this.recipient = recipient || ''
        this.users = tenant_members || []
        this.createdAt = created_at || ''
        this.document = document || null
        this.index = index
    }
}

export default NotificationLog
