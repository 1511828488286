import '../../styles/components/sides.scss'

import dateConvert from '../../utils/formatDate'

const ProfileSide = ({ onEditInfoClick, onChangePasswordClick, user }) => {
    return (
        <div className="profileSide_content">
            <div className="title">
                <span> Twój profil </span>
            </div>
            <div className="optionsBox">
                <div className="optionsBox_info">
                    <div className="text">
                        <span className="text_title">Login:</span>
                        <span className="text_value">{user.login} </span>
                    </div>
                    <div className="text">
                        <span className="text_title">Imię i nazwisko:</span>
                        <span className="text_value">
                            {user.name} {user.surname}
                        </span>
                    </div>
                    <div className="text">
                        <span className="text_title">E-mail:</span>
                        <span className="text_value">{user.email} </span>
                    </div>
                    <div className="text">
                        <span className="text_title">Data dołączenia:</span>
                        <span className="text_value">{dateConvert(user.dateJoined)}</span>
                    </div>
                </div>
                <div className="optionsBox_buttons">
                    <button type="button" onClick={onEditInfoClick}>
                        Edytuj informacje
                    </button>
                    <button type="button" onClick={onChangePasswordClick}>
                        Zmień hasło
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProfileSide
