import {
    Dialog,
    Tablist,
    Tab,
    Checkbox,
    TextInputField,
    SelectField,
    FilePicker,
    toaster
} from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useApi from '../../hooks/useApi'
import fromGetUserTenantApi from '../../utils/Adapters/UserApiAdapter'
import Meter from '../../redux/models/Meter'
import { toMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import { MeterErrors } from '../../models/Auth/Errors'

import eventEmitter from '../../utils/eventEmitter'

export const AddMeterDialog = ({ isShown, setIsShown }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [tabs] = useState(['Rozliczeniowy', 'Nierozliczeniowy'])

    const [isBillingMeter, setIsBillingMeter] = useState(true)
    const [isAdressSameAsUser, setIsAdressSameAsUser] = useState(false)
    const [tenantMembers, setTenantMembers] = useState({})
    const [newMeter, setNewMeter] = useState(new Meter())
    const [meterErrors, setMeterErrors] = useState(new MeterErrors())

    const tenants = useSelector((state) => state.tenant.tenants)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)

    useEffect(() => {
        setMeterErrors(new MeterErrors())

        switch (selectedTabIndex) {
            case 0:
                setIsBillingMeter(true)
                setNewMeter((prev) => ({ ...prev, type: '' }))
                break
            case 1:
            default:
                setIsBillingMeter(false)
                setIsAdressSameAsUser(false)
                break
        }
    }, [selectedTabIndex])

    const BILLING_SELECT_OPTIONS = [
        {
            label: 'Woda zimna',
            value: 'cold_water'
        },
        {
            label: 'Woda ciepła',
            value: 'hot_water'
        },
        {
            label: 'Woda ogrodowa',
            value: 'garden_water'
        }
    ]

    const NON_BILLING_SELECT_OPTIONS = [
        {
            label: 'Woda zimna',
            value: 'cold_water'
        },
        {
            label: 'Woda ciepła',
            value: 'hot_water'
        },
        {
            label: 'Woda ogrodowa',
            value: 'garden_water'
        },
        {
            label: 'Gaz',
            value: 'gas'
        },
        {
            label: 'Ciepło',
            value: 'heat'
        },
        {
            label: 'Prąd',
            value: 'electricity'
        }
    ]

    useApi({
        url: '/app/tenants/standard/tenant_member/',
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: ['users'],
        onSuccess: (data) => setTenantMembers(data.users)
    })

    const addMeterCall = useApi({
        url: '/app/meters/meter/',
        method: 'POST',
        apiKey,
        isFile: true,
        toApiAdapter: toMeterApi
    })

    const getTenantMembers = () => {
        return Object.values(tenantMembers).map((tenantMember) => {
            return {
                label: `${tenantMember.nameSurname} | ${tenants[tenantMember.tenant].name}`,
                value: tenantMember.pk,
                disabled: !isBillingMeter
            }
        })
    }

    const handleDialogClose = () => {
        setIsShown(false)
    }

    const handleTabChange = (newIndex) => {
        setSelectedTabIndex(newIndex)
        setNewMeter((prev) => ({
            ...prev,
            tenantMember: ''
        }))
    }

    const handleTenantMemberSelect = (e) => {
        const tenantMember = e.target.value

        setNewMeter((prev) => ({
            ...prev,
            tenantMember,
            city: tenantMembers[tenantMember].city,
            street: tenantMembers[tenantMember].street,
            zipCode: tenantMembers[tenantMember].zipCode
        }))
        setIsAdressSameAsUser(true)
    }

    const handleSetAdressSameAsUserClick = (e) => {
        const isChecked = e.target.checked
        setIsAdressSameAsUser(isChecked)

        if (isChecked && newMeter.tenantMember) {
            setNewMeter((prev) => ({
                ...prev,
                city: tenantMembers[newMeter.tenantMember].city,
                street: tenantMembers[newMeter.tenantMember].street,
                zipCode: tenantMembers[newMeter.tenantMember].zipCode
            }))
        }
    }

    const handleAddMeter = () => {
        const errors = new MeterErrors()
        errors.validate(newMeter, isBillingMeter)
        setMeterErrors(errors)

        if (!errors.isError) {
            addMeterCall.mutateAsync({ data: newMeter }).then(() => {
                toaster.success('Pomyślnie dodano nowy licznik')
                setNewMeter(new Meter())
                eventEmitter.emit('reload')
            })
        }
    }

    const customDialogFooter = () => {
        return (
            <div className="dialogCustomFooter">
                <button type="button" className="button" onClick={handleAddMeter}>
                    Dodaj licznik
                </button>
                <button type="button" className="rejectButton" onClick={handleDialogClose}>
                    Anuluj
                </button>
            </div>
        )
    }

    return (
        <Dialog
            isShown={isShown}
            title="Dodaj nowy licznik"
            onCloseComplete={handleDialogClose}
            cancelLabel="Anuluj"
            width={700}
            footer={customDialogFooter}
        >
            <TextInputField
                label="Nazwa licznika"
                value={newMeter.name}
                onChange={(e) => setNewMeter((prev) => ({ ...prev, name: e.target.value }))}
                required
                isInvalid={!!meterErrors.name}
                validationMessage={meterErrors.name || null}
            />
            <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
                {tabs.map((tab, index) => (
                    <Tab
                        aria-controls={`panel-${tab}`}
                        isSelected={index === selectedTabIndex}
                        key={tab}
                        onSelect={() => handleTabChange(index)}
                    >
                        {tab}
                    </Tab>
                ))}
            </Tablist>
            <div className="dialog__tab">
                <div className="dialog__row dialog__row">
                    <div className={`dialog__rowItem ${!isBillingMeter && 'dialog__disabled'}`}>
                        <SelectField
                            value={newMeter.tenantMember}
                            onChange={handleTenantMemberSelect}
                            label="Jedn. rozliczeniowa"
                            disabled={!isBillingMeter}
                            required={isBillingMeter}
                            isInvalid={!!meterErrors.tenantMember}
                            validationMessage={meterErrors.tenantMember || null}
                        >
                            <option value="" hidden>
                                Brak
                            </option>
                            {getTenantMembers().map((tenantMember) => (
                                <option key={tenantMember.value} value={tenantMember.value}>
                                    {tenantMember.label}
                                </option>
                            ))}
                        </SelectField>
                    </div>
                    <div className="dialog__rowItem">
                        <SelectField
                            value={newMeter.type}
                            onChange={(e) =>
                                setNewMeter((prev) => ({ ...prev, type: e.target.value }))
                            }
                            label="Typ licznika"
                            required={isBillingMeter}
                            isInvalid={!!meterErrors.type}
                            validationMessage={meterErrors.type || null}
                        >
                            <option value="" hidden>
                                Brak
                            </option>
                            {isBillingMeter
                                ? BILLING_SELECT_OPTIONS.map((type) => (
                                      <option key={type.value} value={type.value}>
                                          {type.label}
                                      </option>
                                  ))
                                : NON_BILLING_SELECT_OPTIONS.map((type) => (
                                      <option key={type.value} value={type.value}>
                                          {type.label}
                                      </option>
                                  ))}
                        </SelectField>
                    </div>
                </div>
                <TextInputField
                    label="Numer seryjny"
                    value={newMeter.serialNumber}
                    onChange={(e) =>
                        setNewMeter((prev) => ({ ...prev, serialNumber: e.target.value }))
                    }
                    required={isBillingMeter}
                    isInvalid={!!meterErrors.serialNumber}
                    validationMessage={meterErrors.serialNumber || null}
                />
                <FilePicker
                    placeholder="Zdjęcie licznika"
                    onChange={(files) => setNewMeter((prev) => ({ ...prev, image: files[0] }))}
                    browseOrReplaceText={() => 'Wybierz plik'}
                />
                <Checkbox
                    checked={isAdressSameAsUser}
                    onChange={handleSetAdressSameAsUserClick}
                    disabled={!newMeter.tenantMember}
                    label="Adres licznika jest taki sam jak w wybranej jednostce rozliczeniowej"
                />
                <TextInputField
                    value={newMeter.street}
                    label="Ulica"
                    disabled={isAdressSameAsUser}
                    onChange={(e) => setNewMeter((prev) => ({ ...prev, street: e.target.value }))}
                    required={isBillingMeter}
                    isInvalid={!!meterErrors.street}
                    validationMessage={meterErrors.street || null}
                />
                <TextInputField
                    value={newMeter.city}
                    label="Miasto"
                    disabled={isAdressSameAsUser}
                    onChange={(e) => setNewMeter((prev) => ({ ...prev, city: e.target.value }))}
                    required={isBillingMeter}
                    isInvalid={!!meterErrors.city}
                    validationMessage={meterErrors.city || null}
                />
                <TextInputField
                    value={newMeter.zipCode}
                    label="Kod pocztowy"
                    disabled={isAdressSameAsUser}
                    onChange={(e) => setNewMeter((prev) => ({ ...prev, zipCode: e.target.value }))}
                    required={isBillingMeter}
                    isInvalid={!!meterErrors.zipCode}
                    validationMessage={meterErrors.zipCode || null}
                />
            </div>
        </Dialog>
    )
}

export default AddMeterDialog
