const LeftButtonBox = ({ currentStep, handleNextStepButtonClick, disabled }) => {
    return (
        <div className="left_box_next_button">
            <button
                type="button"
                className={currentStep === 0 ? 'highlightedButton' : ''}
                name="1"
                onClick={handleNextStepButtonClick}
                disabled={disabled}
            >
                Dalej
            </button>
        </div>
    )
}
export default LeftButtonBox
