import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'
import FaqSection from '../components/Faq/FaqSection'

import faqData from '../static/faquestions'

import '../styles/views/faq.scss'

const Faq = () => {
    return (
        <div className="main">
            <div className="main_content">
                <div className="faq_header">
                    <Link to="/">
                        <div className="backButton">
                            <IoMdArrowBack />
                        </div>
                    </Link>
                    <h1>Często zadawane pytania</h1>
                </div>
                <div className="container">
                    <div className="faq">
                        <div className="faq_section">
                            {Object.entries(faqData).map(([key, element]) => {
                                return <FaqSection key={key} data={element} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq
