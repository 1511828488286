import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Dialog, Table } from 'evergreen-ui'

import DataGridRow from './DataGridRow'

import useApi from '../../hooks/useApi'

import NoResultsRow from '../ListRows/NoResultsRow'

import { addUsers } from '../../redux/slices/userSlice'

import fromGetUserTenantApi from '../../utils/Adapters/UserApiAdapter'
import { renderState } from '../../utils/renderState'

const TableRows = ({ users, onRowClick }) => {
    if (Object.values(users).length === 0) {
        return <NoResultsRow />
    }

    return Object.values(users).map((user) => {
        return (
            <Table.Row
                className="row documents__UserDialog__Row"
                key={user.pk}
                onClick={() => onRowClick(user.pk)}
            >
                <Table.TextCell>
                    {user.isCompany ? user.companyName : user.nameSurname}
                </Table.TextCell>
                <Table.TextCell>
                    {user.isCompany ? 'Instytucjonalny' : 'Indywidualny'}
                </Table.TextCell>
                <Table.TextCell>
                    {user.isCompany
                        ? `${user.companyName}, NIP: ${user.nip}`
                        : `${user.street}, ${user.zipCode}, ${user.city}`}
                </Table.TextCell>
                <Table.TextCell>{renderState(user.state)}</Table.TextCell>
            </Table.Row>
        )
    })
}

export const SelectUserDialog = ({ isUserDialogOpen, setIsUserDialogOpen, XMLdata, onSelect }) => {
    const dispatch = useDispatch()

    const [searchText, setSearchText] = useState(null)

    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const users = useSelector((state) => state.user.users)

    const apiLimit = 20

    const buildURL = () => {
        let url = `/app/tenants/moderator/tenant_member/?limit=${apiLimit}&state=verified`

        if (searchText) url += `&search=${searchText}`

        return url
    }

    useApi({
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetUserTenantApi,
        enabled: isUserDialogOpen,
        queryName: ['users', isUserDialogOpen, searchText],
        keepPreviousData: true,
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const handleSearch = (text) => {
        if (text.length <= 2) {
            setSearchText(null)
            return
        }
        setSearchText(text)
    }

    const handleRowClick = (userPK) => {
        onSelect(userPK)
        setIsUserDialogOpen(false)
    }

    useEffect(() => {
        if (isUserDialogOpen) setSearchText(null)
    }, [isUserDialogOpen])

    return (
        <Dialog
            isShown={isUserDialogOpen}
            hasFooter={false}
            onCloseComplete={() => setIsUserDialogOpen(false)}
            title="Wybór odbiorcy"
            width="80%"
        >
            {XMLdata?.KodSystemowy === 'FA' && (
                <div className="documents__UserDialog">
                    <p>
                        <b>Dane z systemu KSeF:</b>
                    </p>
                    <div className="data_grid">
                        <DataGridRow name="NIP" data={XMLdata?.Podatnik?.NIP} />
                        <DataGridRow name="Nazwa firmy" data={XMLdata?.Podatnik?.PelnaNazwa} />
                        <DataGridRow name="Imię" data={XMLdata?.Podatnik?.ImiePierwsze} />
                        <DataGridRow name="Nazwisko" data={XMLdata?.Podatnik?.Nazwisko} />
                        <span id="spacer_row">Dane rozliczeniowe:</span>
                        <DataGridRow name="Ulica" data={XMLdata?.Podatnik?.Adres?.Ulica} />
                        <DataGridRow name="Nr domu" data={XMLdata?.Podatnik?.Adres?.NrDomu} />
                        <DataGridRow
                            name="Miejscowosc"
                            data={XMLdata?.Podatnik?.Adres?.Miejscowosc}
                        />
                        <DataGridRow
                            name="KodPocztowy"
                            data={XMLdata?.Podatnik?.Adres?.KodPocztowy}
                        />
                        <DataGridRow name="Poczta" data={XMLdata?.Podatnik?.Adres?.Poczta} />
                    </div>
                </div>
            )}

            <Table className="table">
                <Table.Head className="header">
                    <Table.HeaderCell>Nazwa odbiorcy</Table.HeaderCell>
                    <Table.HeaderCell>Typ odbiorcy</Table.HeaderCell>
                    <Table.HeaderCell>Dane rozliczeniowe</Table.HeaderCell>
                    <Table.SearchHeaderCell placeholder="Szukaj..." onChange={handleSearch} />
                </Table.Head>
                <Table.Body>
                    <TableRows users={users} onRowClick={handleRowClick} />
                </Table.Body>
            </Table>
        </Dialog>
    )
}

export default SelectUserDialog
