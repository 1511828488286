import { createSlice } from '@reduxjs/toolkit'

import Meter from '../models/Meter'

export const meterSlice = createSlice({
    name: 'meter',
    initialState: {
        meters: {},
        count: 0,
        nextPage: null,
        previousPage: null
    },
    reducers: {
        addMeters: (state, action) => {
            state.meters = action.payload.meters
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        },
        addOneMeter: (state, action) => {
            state.meters = action.payload
            state.count = 1
            state.nextPage = null
            state.previousPage = null
        },
        verifyMeter: (state, action) => {
            state.meters[action.payload.pk] = new Meter({ ...action.payload })
        },
        bulkVerifyMeter: (state, action) => {
            action.payload.ids.forEach((id) => {
                state.meters[id].isVerified = action.payload.isVerified
            })
        }
    }
})

export const { addMetersToVerified, addMeters, addOneMeter, verifyMeter, bulkVerifyMeter } =
    meterSlice.actions
export default meterSlice.reducer
