import { Combobox, Dialog, Textarea } from 'evergreen-ui'

import '../../styles/components/button.scss'
import '../../styles/components/dialog.scss'

export const MeasurementDeclineDialog = ({
    isShown,
    setIsShown,
    onSendClick,
    handleTopicChanged,
    topics,
    setRejectContent,
    rejectContent
}) => {
    const handleDialogClose = () => {
        setIsShown(false)
    }

    const handleVerifyClick = () => {
        setIsShown(false)
        setRejectContent('')
        onSendClick()
    }

    const customDialogFooter = () => {
        return (
            <div className="dialogCustomFooter">
                <button type="button" className="cancelButton" onClick={handleDialogClose}>
                    Anuluj
                </button>
                <button type="button" className="button" onClick={handleVerifyClick}>
                    Potwierdź
                </button>
            </div>
        )
    }

    return (
        <Dialog
            isShown={isShown}
            title="Odrzucenie prośby o weryfikację"
            onCloseComplete={handleDialogClose}
            confirmLabel="Wybierz plik"
            cancelLabel="Anuluj"
            width={700}
            topOffset={60}
            footer={customDialogFooter}
        >
            <div className="decline_box">
                <Combobox
                    openOnFocus
                    width="100%"
                    height={40}
                    items={topics.map((el) => el.title)}
                    placeholder="Wybierz powód odrzucenia"
                    onChange={(selected) => handleTopicChanged(selected)}
                />
                <Textarea
                    placeholder="Wpisz treść"
                    className="textArea"
                    value={rejectContent}
                    onChange={(e) => {
                        setRejectContent(e.target.value)
                    }}
                />
            </div>
        </Dialog>
    )
}

export default MeasurementDeclineDialog
