import { useSelector } from 'react-redux'

import HistoryModerator from './History.moderator.view'
import HistoryUser from './History.user.view'

const Meters = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? <HistoryModerator /> : <HistoryUser />
}

export default Meters
