import { Link } from 'react-router-dom'
import '../styles/views/privacyPolicy.scss'
import { IoMdArrowBack } from 'react-icons/io'

const PrivacyPolicy = () => {
    return (
        <div className="privacyContainer">
            <div className="header">
                <Link to="/">
                    <div className="backButton">
                        <IoMdArrowBack />
                    </div>
                </Link>
                <h1>POLITYKA PRYWATNOŚCI</h1>
            </div>
            <h3>Kto jest Administratorem danych?</h3>
            <span>
                Administratorem danych osobowych jest NG IT SERVICES z siedzibą przy ul.
                Wrocławskiej 41-43, 56-400 Oleśnica, wpisaną do rejestru przedsiębiorców
                prowadzonego przez Sąd Rejonowy dla Wrocławia Fabrycznej we Wrocławiu, IX Wydział
                Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS: 0000920093, NIP:
                9112039710, REGON: 389902089
            </span>

            <h3>Czy podczas odwiedzania Serwisu pobierane są jakieś dane?</h3>
            <span>
                Odwiedzając naszą stronę nie jest konieczne podawanie swoich danych. Zapisujemy
                jedynie dane dostępowe, które odnoszą się do konkretnej osoby. Pobrane dane są
                jedynie wykorzystywane do zapewnienia prawidłowego działania aplikacji.
            </span>

            <h3>Jakie dane są gromadzone w Serwisie?</h3>
            <span>
                Aby założyć konto Użytkownika wymagane jest podanie adresu poczty elektronicznej
                oraz hasła.
            </span>

            <h3>Jak wykorzystywane są zgromadzone dane?</h3>
            <span>
                Istotne jest, iż gromadzone przez nas dane wykorzystywane są wyłącznie do celów, na
                które wyraziłeś zgodę. Informacje gromadzone przez nas w procesie rejestracji konta
                Użytkownika i zamawiania usług służą jedynie prawidłowej realizacji zamówienia oraz
                wykonania zamówionej usługi. Nadto informacje, które zostały zgromadzone podczas
                rejestracji konta Użytkownika mają umożliwić nam skontaktowanie się z nim poprzez
                pocztę elektroniczną bądź też telefonicznie.
            </span>

            <h3>Jak zapewniamy na naszym Serwisie bezpieczeństwo danych osobowych?</h3>
            <span>
                Nasz Serwis zabezpieczony jest przed utratą, zniszczeniem, dostępem, zmianami oraz
                rozpowszechnianiem danych naszych Użytkowników przez osoby do tego nieupoważnione.
                Nadto dane osobowe każdego Użytkownika chronione są indywidualnym hasłem, które jest
                tylko dostępne dla konkretnego Użytkownika. Dlatego prosimy pamiętać o wylogowaniu
                się z Serwisu i zamknięciu przeglądarki po zakończeniu korzystania z Serwisu, aby
                uniemożliwić dostępu osób trzecich do konta i do danych osobowych.
            </span>

            <h3>Czy jest możliwa modyfikacja wprowadzonych danych osobowych?</h3>
            <span>
                Użytkownik ma możliwość edytowania swoich danych po zalogowaniu się na swoje Konto w
                Serwisie.
            </span>

            <h3>Czy można usunąć konto Użytkownika?</h3>
            <span>
                Użytkownik ma możliwość usunięcia konta po zalogowaniu się na swoje Konto w
                Serwisie.
            </span>

            <h3>Co ze zmianami w Polityce Prywatności?</h3>
            <span>
                W związku z planowanym rozwojem naszego Serwisu i usług przez nas świadczonych,
                zmianie będą ulegać również inne czynniki, takie jak technologie, wymogi prawne,
                dlatego też Polityka Prywatności może ulec zmianie. Korzystanie z Serwisu po wejściu
                zmian w życie będzie oznaczało ich akceptację.Korzystając ze strony internetowej pod
                adresem: www.panel.bezlicznik.pl wyrażasz zgodę na zasady i warunki przetwarzania
                danych opisane w niniejszej Polityce Prywatności.
            </span>

            <h3>Dane Kontaktowe:</h3>
            <span>
                <p>NG IT SERVICES</p>
                <p>ul. Wrocławska 41-43; 56-400 Oleśnica,</p>
                <p>NIP: 9112039710, REGON: 389902089</p>
                <p>adres email: kontakt@ngits.pl, </p>
                <p>telefon: +48 785 775 275</p>
            </span>

            <h3>POLITYKA COOKIES</h3>
            <span>
                Niniejsza polityka dotycząca plików cookie stanowi integralną część Polityki
                Prywatności Serwisu. Pliki Cookies wykorzystywane są by możliwe było korzystanie z
                niektórych funkcji systemu, jak i do tego, by lepiej wyglądała nasza strona
                internetowa. Pliki Cookies są małymi fragmentami tekstu, które przechowuje Twoja
                przeglądarka. Owe pliki zawierają informacje, które są zapisywane na Twoim
                komputerze, podczas odwiedzania Serwisu. Natomiast podczas kolejnej wizyty na
                stronie Serwisu są one z powrotem wysyłane, do tego Serwisu lub innego, który
                rozpoznaje poliki cookies. Identyfikacja odbywa się bezosobowo, pobierane są jedynie
                dane, które wskazują na sposób korzystania z Serwisu. Pliki cookies pozwalają na
                ulepszanie naszej oferty i wychodzenie naprzeciw oczekiwaniom naszym Użytkownikom
                oraz odwiedzającym nasz Serwis. Pliki cookies nie mają żadnego szkodliwego wpływu na
                Twój komputer, nie niszczą posiadanych przez Ciebie plików, zgromadzonych danych
                poza tym nie mają żadnego wpływu na jego działanie. Ograniczenie stosowania plików
                cookies może wpłynąć na niektóre funkcjonalności, które są dostępne w Serwisie.
            </span>
        </div>
    )
}

export default PrivacyPolicy
