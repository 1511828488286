export const fromRegisterApiCallError = (errObj) => {
    if (errObj.response?.data?.password?.[0] === 'This password is too common.') {
        return { error: 'Użyte hasło jest zbyt popularne' }
    }

    if (
        errObj.response?.data?.non_field_errors?.[0] ===
        'User associated with this email already exists!'
    ) {
        return { error: 'Ten adres e-mail jest już zarejestrowany w naszym systemie' }
    }

    return { error: 'Błąd serwera' }
}

export const fromLoginApiCallError = (errObj) => {
    if (errObj.response.status === 401) {
        return { error: 'Błąd autoryzacji' }
    }
    if (errObj.response.status === 400) {
        return { error: 'Hasło lub email są niepoprawne' }
    }
    return { error: 'Błąd serwera' }
}

export const fromRemindPasswordApiCallError = (errObj) => {
    if (errObj.response.status === 401) {
        return { error: 'Błąd autoryzacji' }
    }
    if (errObj.response.status === 400) {
        return { error: 'Użytkownik powiązany z tym adresem email, nie istnieje' }
    }
    return { error: 'Błąd serwera' }
}
