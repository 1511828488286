import { createSlice } from '@reduxjs/toolkit'
import Profile from '../models/Profile'

export const ProfileSlice = createSlice({
    name: 'profile',
    initialState: {
        profile: new Profile(),
        editedProfile: new Profile(),
        loggedIn: false,
        loginStatus: 'idle',
        rowLimit: 10,
        isMobile: false
    },
    reducers: {
        changeEditedProfile: (state, action) => {
            state.editedProfile = {
                ...state.editedProfile,
                ...action.payload
            }
        },
        logoutUser: (state) => {
            state.loggedIn = false
            state.loginStatus = 'idle'
        },
        setUserData: (state, action) => {
            state.profile = {
                ...state.profile,
                ...action.payload
            }
        },
        setLoginData: (state, action) => {
            state.profile = {
                ...state.profile,
                ...action.payload
            }
            state.loggedIn = true
            state.loginStatus = 'success'
        },
        setRowLimit: (state, action) => {
            state.rowLimit = action.payload
        },
        setIsMobile: (state, action) => {
            state.isMobile = action.payload
        }
    }
})

export const {
    changeEditedProfile,
    logoutUser,
    setUserData,
    setLoginData,
    setRowLimit,
    setIsMobile
} = ProfileSlice.actions
export default ProfileSlice.reducer
