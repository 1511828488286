/* eslint-disable camelcase */
class HistoryLog {
    constructor(values = {}) {
        const {
            pk,
            type,
            tenant,
            title,
            body,
            image,
            data,
            channel,
            recipient,
            tenant_members,
            createdAt,
            createdBy,
            tenantMember,
            measurement,
            document,
            changes
        } = values

        this.pk = pk || null
        this.type = type || ''
        this.tenant = tenant || null
        this.title = title || ''
        this.body = body || ''
        this.image = image || ''
        this.data = data || {}
        this.channel = channel || []
        this.recipient = recipient || ''
        this.users = tenant_members || []
        this.createdAt = createdAt || new Date().toLocaleDateString()
        this.createdBy = createdBy || null
        this.tenantMember = tenantMember || null
        this.measurement = measurement || null
        this.document = document || null
        this.changes = changes || {}
    }
}

export default HistoryLog
