class Profile {
    constructor(data = {}) {
        const { id, apiKey, login, email, name, surname, password, dateJoined } = data

        this.id = id || ''
        this.apiKey = apiKey || ''
        this.login = login || ''
        this.name = name || ''
        this.surname = surname || ''
        this.dateJoined = dateJoined || ''
        this.email = email || ''
        this.password = password || ''
    }
}

export default Profile
