import { useSelector } from 'react-redux'

import DocumentsModerator from './Documents.moderator.view'
import DocumentsUser from './Documents.user.view'

const Meters = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? <DocumentsModerator /> : <DocumentsUser />
}

export default Meters
