import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

import { Table } from 'evergreen-ui'
import { FiPlus } from 'react-icons/fi'

import ActiveFilters from '../../components/activeFilters'
import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import PaginationButtons from '../../components/PaginationButtons'
import Footer from '../../components/Footer'
import LoadingRows from '../../components/ListRows/LoadingRows'
import RowLimit from '../../components/RowLimit'
import NoResultsRow from '../../components/ListRows/NoResultsRow'
import NotificationDetailDialog from '../../components/NotificationDetailDialog'

import useApi from '../../hooks/useApi'

import { addUsers } from '../../redux/slices/userSlice'
import { addNotificationLogs } from '../../redux/slices/notificationLogSlice'

import fromNotificationLogApi from '../../utils/Adapters/NotificationLogApiAdapter'
import { fromGetUserTenantApi } from '../../utils/Adapters/UserApiAdapter'

import PaginationInput from '../../components/PaginationInput'
import NotificationRow from '../../components/ListRows/NotificationsRows/NotificationRow'
import fromDocumentApi from '../../utils/Adapters/DocumentApiAdapter'
import { addDocuments } from '../../redux/slices/documentSlice'
import { addMeasurements } from '../../redux/slices/measurementSlice'
import { fromMeasurementApi } from '../../utils/Adapters/MeasurementApiAdapter'
import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import { addMeters } from '../../redux/slices/meterSlice'

const renderTableRows = (notificationLogs, isLoaded, setIsDetailsShown, setNotificationRowId) => {
    if (!isLoaded) {
        return <LoadingRows elements={notificationLogs} />
    }
    if (Object.values(notificationLogs).length === 0) {
        return <NoResultsRow />
    }

    return Object.entries(notificationLogs)
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => {
            return (
                <NotificationRow
                    key={key}
                    log={value}
                    setIsDetailsShown={setIsDetailsShown}
                    setNotificationRowId={setNotificationRowId}
                />
            )
        })
}

const NotificationsModerator = () => {
    const dispatch = useDispatch()

    const notificationLogs = useSelector((state) => state.notificationLog.notificationLogs)
    const notificationNextPage = useSelector((state) => state.notificationLog.nextPage)
    const notificationPreviousPage = useSelector((state) => state.notificationLog.previousPage)
    const notificationResultsCount = useSelector((state) => state.notificationLog.count)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const rowLimit = useSelector((state) => state.profile.rowLimit)
    const documents = useSelector((state) => state.document.documents)
    const measurements = useSelector((state) => state.measurement.measurements)

    const [urlParams, setUrlParams] = useSearchParams()

    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [isDetailsShown, setIsDetailsShown] = useState(false)
    const [notificationRowId, setNotificationRowId] = useState(null)
    const [usersToFetch, setUsersToFetch] = useState(null)
    const [documentsToFetch, setDocumentsToFetch] = useState(null)
    const [measurementsToFetch, setMeasurementsToFetch] = useState(null)
    const [metersToFetch, setMetersToFetch] = useState(null)

    // url params - filters
    const [isAutomaticParam, setIsAutomaticParam] = useState(null)
    const [channelParam, setChannelParam] = useState(null)
    const [recipientParam, setRecipientParam] = useState(null)
    const [startDateParam, setStartDateParam] = useState(null)
    const [endDateParam, setEndDateParam] = useState(null)
    const [userParam, setUserParam] = useState(null)

    // url params - sorting
    const [sortingParam, setSortingParam] = useState(null)

    const buildURL = () => {
        let baseURL = `/app/notifications/notification/?limit=${rowLimit}&offset=${offset}`

        // filters
        if (userParam !== null) baseURL += `&tenant_member__in=${userParam}`
        if (isAutomaticParam !== null) baseURL += `&is_automatic=${isAutomaticParam}`
        if (channelParam !== null) baseURL += `&channel=${channelParam}`
        if (recipientParam !== null) baseURL += `&recipient=${recipientParam}`
        if (startDateParam !== null && endDateParam !== null) {
            baseURL += `&created_at_after=${startDateParam}`
            baseURL += `&created_at_before=${endDateParam}`
        }

        // sorting
        if (sortingParam !== null) baseURL += `&ordering=${sortingParam}`

        return baseURL
    }

    const fetchLogs = useApi({
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromNotificationLogApi,
        queryName: [
            'notification',
            offset,
            rowLimit,
            isAutomaticParam,
            channelParam,
            recipientParam,
            startDateParam,
            endDateParam,
            userParam,
            sortingParam
        ],
        onSuccess: (data) => dispatch(addNotificationLogs(data)),
        keepPreviousData: true
    })

    useApi({
        url: `/app/tenants/moderator/tenant_member/?id__in=${usersToFetch}`,
        method: 'GET',
        apiKey,
        enabled: fetchLogs.isSuccess,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: ['users', usersToFetch],
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const fetchDocuments = useApi({
        url: `/app/meters/moderator/document/?id__in=${documentsToFetch}`,
        method: 'GET',
        apiKey,
        enabled: fetchLogs.isSuccess,
        fromApiAdapter: fromDocumentApi,
        queryName: ['document', documentsToFetch],
        onSuccess: (data) => dispatch(addDocuments(data))
    })

    const fetchMeasurements = useApi({
        url: `/app/meters/moderator/measurement/?id__in=${measurementsToFetch}`,
        method: 'GET',
        apiKey,
        enabled: fetchDocuments.isSuccess,
        fromApiAdapter: fromMeasurementApi,
        queryName: ['measurements', measurementsToFetch],
        onSuccess: (data) => dispatch(addMeasurements(data))
    })

    useApi({
        url: `/app/meters/moderator/meter/?id__in=${metersToFetch}`,
        method: 'GET',
        apiKey,
        enabled: fetchMeasurements.isSuccess,
        fromApiAdapter: fromMeterApi,
        queryName: ['meters', metersToFetch],
        onSuccess: (data) => dispatch(addMeters(data))
    })

    useEffect(() => {
        let userIDs = []
        let documentIDs = []

        Object.values(notificationLogs).forEach((log) => {
            userIDs = userIDs.concat(log.users)
            if (log.document) documentIDs = documentIDs.concat(log.document)
        })

        setUsersToFetch([...new Set(userIDs)].join(','))
        setDocumentsToFetch([...new Set(documentIDs)].join(','))
    }, [notificationLogs])

    useEffect(() => {
        let measurementIDs = []

        Object.values(documents).forEach((document) => {
            measurementIDs = measurementIDs.concat(document.measurement)
        })

        setMeasurementsToFetch([...new Set(measurementIDs)].join(','))
    }, [documents])

    useEffect(() => {
        let meterIDs = []

        Object.values(measurements).forEach((measurement) => {
            meterIDs = meterIDs.concat(measurement.meter)
        })

        setMetersToFetch([...new Set(meterIDs)].join(','))
    }, [measurements])

    useEffect(() => {
        setIsAutomaticParam(null)
        setChannelParam(null)
        setRecipientParam(null)
        setStartDateParam(null)
        setEndDateParam(null)

        // filters
        const isAutomaticParamGET = urlParams.get('is_automatic')
        const channelParamGET = urlParams.get('channel')
        const recipientParamGET = urlParams.get('recipient')
        const createdAtParamGET = urlParams.get('created_at') || null
        const userParamGET = urlParams.get('user_id')
        // sorting
        const sortingParamGET = urlParams.get('sorting') || null

        // filters
        if (isAutomaticParamGET !== null) setIsAutomaticParam(isAutomaticParamGET)
        if (channelParamGET !== null) setChannelParam(channelParamGET)
        if (recipientParamGET !== null) setRecipientParam(recipientParamGET)
        if (isAutomaticParamGET !== null) setIsAutomaticParam(isAutomaticParamGET)
        if (userParamGET !== null) setUserParam(userParamGET)
        if (createdAtParamGET !== null) {
            const endDate = new Date()
            let startDate = null
            setEndDateParam(endDate.toISOString().split('T')[0])

            // 1000 * 60 * 60 * 24 - stands for one day in ms
            switch (createdAtParamGET) {
                case 'last_24h': {
                    startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_week': {
                    startDate = new Date(endDate.getTime() - 7 * 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_month': {
                    startDate = new Date(endDate.getTime() - 30 * 1000 * 60 * 60 * 24)
                    break
                }
                default:
                    break
            }

            setStartDateParam(startDate.toISOString().split('T')[0])
        }

        // sorting
        if (sortingParamGET !== null) {
            switch (sortingParamGET) {
                case 'created_at_asc':
                    setSortingParam('created_at')
                    break
                case 'created_at_desc':
                    setSortingParam('-created_at')
                    break
                default:
                    break
            }
        }
    }, [urlParams])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
    }, [rowLimit])

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCount((old) => old + 1)
            else setPageCount((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1) setOffset(page.substring(offsetPosition + 7, page.length))
            else setOffset(0)
        }
    }

    const handlePaginationInputonBlur = (e) => {
        setOffset(rowLimit * e - rowLimit)
        setPageCount(e)
    }

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="notifications" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">
                        <span>Powiadomienia</span>
                        <Link to="send/">
                            <button type="button" name="meters" id="addInvoice">
                                Wyślij nowe powiadomienie <FiPlus size={16} />
                            </button>
                        </Link>
                    </div>
                    <ActiveFilters notificationFilters />
                </div>

                <div className="container">
                    <div className="container_main">
                        <div className="container_cards">
                            <Table className="table">
                                <Table.Head className="header">
                                    <Table.TextHeaderCell className="table_title_col">
                                        Nadawca
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        className="table_title_col"
                                        flexBasis={350}
                                        flexGrow={0}
                                    >
                                        Kanał
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Grupa odbiorców
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Odbiorcy
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Data utworzenia
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Tytuł
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        flexBasis="50px"
                                        flexShrink={0}
                                        flexGrow={0}
                                    />
                                </Table.Head>
                                <Table.Body>
                                    {renderTableRows(
                                        notificationLogs,
                                        fetchLogs.isSuccess,
                                        setIsDetailsShown,
                                        setNotificationRowId
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="pagination_box">
                            <PaginationInput
                                pageCount={pageCount}
                                onBlur={handlePaginationInputonBlur}
                                resultCount={notificationResultsCount}
                                apiLimit={rowLimit}
                            />
                            <RowLimit />
                        </div>
                        <PaginationButtons
                            pageCount={pageCount}
                            onPageClick={onPageClick}
                            prevPage={notificationPreviousPage}
                            nextPage={notificationNextPage}
                            resultCount={notificationResultsCount}
                            apiLimit={rowLimit}
                        />
                    </div>
                </div>
            </div>
            <NotificationDetailDialog
                isShown={isDetailsShown}
                setIsShown={setIsDetailsShown}
                notificationRowId={notificationRowId}
            />
            <Footer />
        </div>
    )
}

export default NotificationsModerator
