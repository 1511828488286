import { createSlice } from '@reduxjs/toolkit'

export const HistoryLogSlice = createSlice({
    name: 'historyLog',
    initialState: {
        historyLog: {},
        count: 0,
        nextPage: null,
        previousPage: null
    },
    reducers: {
        addHistoryLogs: (state, action) => {
            state.historyLog = action.payload.historyLogs
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        }
    }
})

export const { addHistoryLogs } = HistoryLogSlice.actions
export default HistoryLogSlice.reducer
