import { Table } from 'evergreen-ui'

import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'

export const UserMobileRow = ({ user, isModerator, isExpanded, onExpandChange }) => {
    const renderRowClassName = () => {
        let className = 'row'

        if (isModerator && user.state === 'new') className = className.concat(' not_verified')
        if (!isModerator && user.state === 'rejected') className = className.concat(' not_verified')

        if (isExpanded) className = className.concat(' in_color')

        return className
    }

    return (
        <Table.Row className={renderRowClassName()}>
            <Table.TextCell>{user.isCompany ? user.companyName : user.nameSurname}</Table.TextCell>
            <Table.TextCell className="alone_row">
                <button type="button" onClick={onExpandChange} className="clickable">
                    {isExpanded ? (
                        <BiUpArrowAlt size="20" className="icon_white" />
                    ) : (
                        <BiDownArrowAlt size="20" />
                    )}
                </button>
            </Table.TextCell>
        </Table.Row>
    )
}

export default UserMobileRow
