export const toLoginApi = (uiObj) => {
    return {
        email: uiObj.email.toLowerCase(),
        password: uiObj.password
    }
}

export const toRegisterApi = (uiObj) => {
    return {
        email: uiObj.email.toLowerCase(),
        password: uiObj.password,
        repeat_password: uiObj.repeatPassword
    }
}

export const toSocialLoginApi = (uiObj) => {
    return { token: uiObj.token }
}

export const fromLoginApi = (apiObj) => {
    return {
        apiKey: apiObj.data.key,
        id: apiObj.data.user_id,
        isModerator: apiObj.data.is_moderator
    }
}

export const fromSocialLoginApi = (apiObj) => {
    return {
        apiKey: apiObj.data.key,
        id: apiObj.data.user_id,
        isModerator: false
    }
}

export const toResetPasswordApi = (uiObj) => {
    return {
        current_password: uiObj.actualPassword,
        new_password: uiObj.newPassword,
        repeat_new_password: uiObj.repeatNewPassword
    }
}

export const fromResetPasswordApi = () => {
    return { message: 'Hasło zmieniono pomyślnie' }
}

export const toRemindPasswordApi = (uiObj) => {
    return { email: uiObj.email }
}

export const fromGetUserDataApi = (apiObj) => {
    return {
        name: apiObj.data.first_name,
        surname: apiObj.data.last_name,
        dateJoined: apiObj.data.date_joined,
        email: apiObj.data.email,
        login: apiObj.data.email.split('@')[0]
    }
}

export const toUpdateUserDataApi = (uiObj) => {
    return {
        first_name: uiObj.name,
        last_name: uiObj.surname
    }
}
