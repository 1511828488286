import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

import { Table, Checkbox, toaster } from 'evergreen-ui'
import { FiPlus } from 'react-icons/fi'

import ActiveFilters from '../../components/activeFilters'
import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import PaginationButtons from '../../components/PaginationButtons'
import Footer from '../../components/Footer'
import LoadingRows from '../../components/ListRows/LoadingRows'
import DocumentRow from '../../components/ListRows/DocumentRows/DocumentRow'
import RowLimit from '../../components/RowLimit'
import NoResultsRow from '../../components/ListRows/NoResultsRow'

import useApi from '../../hooks/useApi'

import { addMeters } from '../../redux/slices/meterSlice'
import { addUsers } from '../../redux/slices/userSlice'
import { addMeasurements } from '../../redux/slices/measurementSlice'
import { addDocuments } from '../../redux/slices/documentSlice'

import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import { fromGetUserTenantApi } from '../../utils/Adapters/UserApiAdapter'
import { fromMeasurementApi } from '../../utils/Adapters/MeasurementApiAdapter'
import { fromDocumentApi } from '../../utils/Adapters/DocumentApiAdapter'
import getPathArguments from '../../utils/pathIDs'
import PaginationInput from '../../components/PaginationInput'

const TableRows = ({
    documents,
    meters,
    users,
    measurements,
    isLoaded,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate,
    setIsDetailsShown,
    setDocumentDetailId
}) => {
    if (!isLoaded) {
        return <LoadingRows elements={documents} />
    }
    if (Object.values(documents).length === 0) {
        return <NoResultsRow />
    }

    return Object.entries(documents)
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => {
            const invoiceMeter = measurements[value.measurement[0]]?.meter
            return (
                <DocumentRow
                    document={value}
                    user={users[meters[invoiceMeter]?.tenantMember]}
                    meter={meters[invoiceMeter]}
                    key={key}
                    index={key}
                    onRowSelected={onRowSelected}
                    isSelectedEveryRow={isSelectedEveryRow}
                    isSelectedEveryRowIndeterminate={isSelectedEveryRowIndeterminate}
                    setIsDetailsShown={setIsDetailsShown}
                    setDocumentDetailId={setDocumentDetailId}
                />
            )
        })
}

const DocumentsModerator = () => {
    const dispatch = useDispatch()

    const [urlParams, setUrlParams] = useSearchParams()

    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [isDetailsShown, setIsDetailsShown] = useState(false)
    const [documentDetailId, setDocumentDetailId] = useState(null)

    // mass actions
    const [selectedRows, setSelectedRows] = useState([])
    const [isSelectedEveryRow, setIsSelectedEveryRow] = useState(false)
    const [isSelectedEveryRowIndeterminate, setIsSelectedEveryRowIndeterminate] = useState(false)

    // url params - filters
    const [userParam, setUserParam] = useState(null)
    const [meterParam, setMeterParam] = useState(null)
    const [startDateParam, setStartDateParam] = useState(null)
    const [endDateParam, setEndDateParam] = useState(null)
    const [paymentStatusParam, setPaymentStatusParam] = useState(null)

    // url params - sorting
    const [sortingParam, setSortingParam] = useState(null)

    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const documents = useSelector((state) => state.document.documents)
    const measurements = useSelector((state) => state.measurement.measurements)
    const users = useSelector((state) => state.user.users)
    const meters = useSelector((state) => state.meter.meters)
    const rowLimit = useSelector((state) => state.profile.rowLimit)

    const documentsNextPage = useSelector((state) => state.document.nextPage)
    const documentsPreviousPage = useSelector((state) => state.document.previousPage)
    const documentsResultsCount = useSelector((state) => state.document.count)

    const buildURL = () => {
        let baseURL = `/app/meters/moderator/document/?limit=${rowLimit}&offset=${offset}`

        // filters
        if (userParam !== null) baseURL += `&measurements__meter__tenant_member=${userParam}`
        if (meterParam !== null) baseURL += `&measurements__meter=${meterParam}`
        if (startDateParam !== null && endDateParam !== null) {
            baseURL += `&created_at_after=${startDateParam}`
            baseURL += `&created_at_before=${endDateParam}`
        }
        if (paymentStatusParam !== null) baseURL += `&is_paid=${paymentStatusParam.toString()}`

        // sorting
        if (sortingParam !== null) baseURL += `&ordering=${sortingParam}`

        return baseURL
    }

    const fetchDocuments = useApi({
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromDocumentApi,
        queryName: [
            'document',
            offset,
            rowLimit,
            userParam,
            meterParam,
            startDateParam,
            endDateParam,
            paymentStatusParam,
            sortingParam
        ],
        onSuccess: (data) => dispatch(addDocuments(data)),
        keepPreviousData: true
    })

    const fetchMeasurements = useApi({
        url: `/app/meters/moderator/measurement/?id__in=${getPathArguments(
            documents,
            'measurement'
        )}`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeasurementApi,
        enabled: fetchDocuments.isSuccess,
        queryName: ['measurement', documents],
        onSuccess: (data) => dispatch(addMeasurements(data)),
        keepPreviousData: true
    })

    const fetchMeters = useApi({
        url: `/app/meters/moderator/meter/?id__in=${getPathArguments(measurements, 'meter')}`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeterApi,
        enabled: fetchMeasurements.isSuccess,
        queryName: ['meters', measurements],
        onSuccess: (data) => dispatch(addMeters(data)),
        keepPreviousData: true
    })

    const fetchUserTenantList = useApi({
        url: `/app/tenants/moderator/tenant_member/?id__in=${getPathArguments(
            meters,
            'tenantMember'
        )}`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetUserTenantApi,
        enabled: fetchMeters.isSuccess,
        queryName: ['users', meters],
        onSuccess: (data) => dispatch(addUsers(data)),
        keepPreviousData: true
    })

    const handleRowSelectChanged = (pk) => {
        if (selectedRows.indexOf(pk) !== -1)
            setSelectedRows((current) => current.filter((elem) => elem !== pk))
        else setSelectedRows((current) => [...current, pk])
    }

    const handeSelectEveryRowClick = () => {
        if (isSelectedEveryRowIndeterminate) {
            setSelectedRows([])
            setIsSelectedEveryRowIndeterminate(false)
            return
        }

        if (isSelectedEveryRow) setSelectedRows([])
        else {
            const ids = Object.values(documents).map((document) => document.id)
            setSelectedRows(ids)
        }
        setIsSelectedEveryRow((current) => !current)
    }

    const handleMassActionClick = (actionObject) => {
        if (selectedRows.length === 0) {
            toaster.notify('Nie wybrano żadnego wiersza')
            return
        }

        switch (actionObject.value) {
            case 'preview':
                selectedRows.forEach((id) => {
                    window.open(documents[id].file)
                })
                break
            default:
                break
        }
    }

    // logika zaznaczania wierszy
    useEffect(() => {
        if (Object.values(selectedRows).length === 0) {
            setIsSelectedEveryRow(false)
            setIsSelectedEveryRowIndeterminate(false)
        } else if (selectedRows.length === Object.values(documents).length) {
            setIsSelectedEveryRow(true)
            setIsSelectedEveryRowIndeterminate(false)
        } else {
            setIsSelectedEveryRow(false)
            setIsSelectedEveryRowIndeterminate(true)
        }
    }, [selectedRows])

    useEffect(() => {
        setUserParam(null)
        setMeterParam(null)
        setStartDateParam(null)
        setEndDateParam(null)
        setPaymentStatusParam(null)

        // filters
        const userParamGET = parseInt(urlParams.get('user_id'), 10)
        const meterParamGET = parseInt(urlParams.get('meter_id'), 10)
        const createdAtParamGET = urlParams.get('created_at') || null
        const paymentStatusParamGET = urlParams.get('payment_status') || null
        // sorting
        const sortingParamGET = urlParams.get('sorting') || null

        // filters
        if (!Number.isNaN(userParamGET)) setUserParam(userParamGET)
        else if (!Number.isNaN(meterParamGET)) setMeterParam(meterParamGET)

        if (paymentStatusParamGET)
            switch (paymentStatusParamGET) {
                case 'is_paid_false':
                    setPaymentStatusParam(false)
                    break
                case 'is_paid_true':
                    setPaymentStatusParam(true)
                    break
                default:
                    setPaymentStatusParam(null)
            }

        if (createdAtParamGET !== null) {
            const endDate = new Date()
            let startDate = null
            setEndDateParam(endDate.toISOString().split('T')[0])

            // 1000 * 60 * 60 * 24 - stands for one day in ms
            switch (createdAtParamGET) {
                case 'last_24h': {
                    startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_week': {
                    startDate = new Date(endDate.getTime() - 7 * 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_month': {
                    startDate = new Date(endDate.getTime() - 30 * 1000 * 60 * 60 * 24)
                    break
                }
                default:
                    break
            }

            setStartDateParam(startDate.toISOString().split('T')[0])
        }

        // sorting
        if (sortingParamGET !== null) {
            switch (sortingParamGET) {
                case 'created_at_asc':
                    setSortingParam('created_at')
                    break
                case 'created_at_desc':
                    setSortingParam('-created_at')
                    break
                default:
                    break
            }
        }
    }, [urlParams])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
    }, [rowLimit])

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCount((old) => old + 1)
            else setPageCount((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1) setOffset(page.substring(offsetPosition + 7, page.length))
            else setOffset(0)
        }
    }
    const handlePaginationInputonBlur = (e) => {
        setOffset(rowLimit * e - rowLimit)
        setPageCount(e)
    }

    const handleOnRemoveFilterClick = () => {
        if (!Number.isNaN(meterParam)) setUserParam(null)
        if (!Number.isNaN(meterParam)) setMeterParam(null)
    }
    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="messages" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">
                        <span>Dokumenty</span>
                        <Link to="add/:id">
                            <button type="button" name="meters" id="addInvoice">
                                Dodaj nowy dokument <FiPlus size={16} />
                            </button>
                        </Link>
                    </div>
                    <ActiveFilters
                        onRemoveClick={handleOnRemoveFilterClick}
                        onMassActionSelect={handleMassActionClick}
                        documentFilters
                    />
                </div>

                <div className="container">
                    <div className="container_main">
                        <div className="container_cards">
                            <Table className="table">
                                <Table.Head className="header">
                                    <Table.TextHeaderCell
                                        flexBasis="40px"
                                        flexShrink={0}
                                        flexGrow={0}
                                    >
                                        <Checkbox
                                            checked={isSelectedEveryRow}
                                            indeterminate={isSelectedEveryRowIndeterminate}
                                            onChange={handeSelectEveryRowClick}
                                        />
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Typ
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Odbiorca
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Nadawca
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Data utworzenia
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Płatność
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        className="table_title_col"
                                        flexBasis={270}
                                        flexShrink={0}
                                        flexGrow={0}
                                    >
                                        Działania
                                    </Table.TextHeaderCell>
                                </Table.Head>
                                <Table.Body>
                                    <TableRows
                                        documents={documents}
                                        meters={meters}
                                        users={users}
                                        measurements={measurements}
                                        isLoaded={fetchDocuments.isSuccess}
                                        onRowSelected={handleRowSelectChanged}
                                        isSelectedEveryRow={isSelectedEveryRow}
                                        isSelectedEveryRowIndeterminate={
                                            isSelectedEveryRowIndeterminate
                                        }
                                        setIsDetailsShown={setIsDetailsShown}
                                        setDocumentDetailId={setDocumentDetailId}
                                    />
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="pagination_box">
                            <PaginationInput
                                pageCount={pageCount}
                                onBlur={handlePaginationInputonBlur}
                                resultCount={documentsResultsCount}
                                apiLimit={rowLimit}
                            />
                            <RowLimit />
                        </div>
                        <PaginationButtons
                            pageCount={pageCount}
                            onPageClick={onPageClick}
                            prevPage={documentsPreviousPage}
                            nextPage={documentsNextPage}
                            resultCount={documentsResultsCount}
                            apiLimit={rowLimit}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DocumentsModerator
