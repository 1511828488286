import axios from 'axios'
import { useQuery, useMutation } from 'react-query'
import { fromApiCallError } from '../utils/Adapters/errorAdpters/ErrorApiAdapter'

const apiUrl = process.env.REACT_APP_API_URL

const isUrlValid = (url) => {
    return !!url
}

const headers = (key, isFile) => {
    if (isFile && key !== '') {
        return {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${key}`
        }
    }
    if (key !== '') {
        return {
            'Content-Type': 'application/json',
            Authorization: `Token ${key}`
        }
    }

    return null
}

const apiGetData = async (apiKey, url) => {
    if (isUrlValid(url)) {
        return axios.get(`${apiUrl}${url}`, { headers: headers(apiKey) })
    }

    return null
}

const apiPostData = async (mutation, method, toApiAdapter, isFile, url = '', apiKey = '') => {
    const { data } = mutation
    const uri = url === '' ? mutation.url : url

    if (isUrlValid(uri)) {
        return axios({
            url: `${apiUrl}${uri}`,
            headers: headers(apiKey, isFile),
            data: toApiAdapter(data),
            method
        })
    }

    return null
}

const useApi = ({
    url = '',
    apiKey = '',
    queryName = '',
    refetchOnWindowFocus = false,
    enabled = true,
    method = '',
    keepPreviousData = false,
    isFile = false,
    cacheTime = 200,
    staleTime = 100,
    onSuccess = () => {},
    toApiAdapter = () => {},
    fromApiAdapter = () => {},
    fromApiErrorAdapter = fromApiCallError
}) => {
    if (method === 'GET') {
        return useQuery(queryName, () => apiGetData(apiKey, url), {
            onError: fromApiErrorAdapter,
            refetchOnWindowFocus,
            select: (apiObj) => fromApiAdapter(apiObj),
            staleTime,
            enabled,
            keepPreviousData,
            cacheTime,
            onSuccess
        })
    }

    return useMutation((data) => apiPostData(data, method, toApiAdapter, isFile, url, apiKey), {
        onError: fromApiErrorAdapter
    })
}

export default useApi
