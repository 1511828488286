import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { Tooltip } from 'evergreen-ui'
import countPages from '../utils/countPages'

import '../styles/components/pagination.scss'

const PaginationButtons = ({
    pageCount,
    onPageClick,
    prevPage,
    nextPage,
    resultCount,
    apiLimit
}) => {
    return (
        <div className="container_main_pagination">
            <hr />
            <div className="paginationInfo">
                <Tooltip content="Porzednia strona" showDelay={500}>
                    <button
                        type="button"
                        data-testid="prev-button"
                        onClick={() => onPageClick(prevPage, -1)}
                    >
                        <BiLeftArrowAlt size={17} />
                    </button>
                </Tooltip>

                <div>
                    <span>strona&nbsp;</span>
                    <span>
                        {pageCount} z {resultCount === 0 ? 1 : countPages(apiLimit, resultCount)}
                    </span>
                </div>
                <Tooltip content="Następna strona" showDelay={500}>
                    <button
                        type="button"
                        data-testid="next-button"
                        onClick={() => onPageClick(nextPage, 1)}
                    >
                        <BiRightArrowAlt size={17} />
                    </button>
                </Tooltip>
            </div>
            <hr />
        </div>
    )
}

export default PaginationButtons
