class Tenant {
    constructor(data = {}) {
        const { uuid, name } = data

        this.UUID = uuid || null
        this.name = name || ''
    }
}

export default Tenant
