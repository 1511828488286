import { Link } from 'react-router-dom'

import { Table, Pane, Dialog, Tooltip } from 'evergreen-ui'
import { useSelector } from 'react-redux'

const renderTableRows = (notificationRowId, users) => {
    const checkingCompany = (user) => {
        if (!user?.isCompany) return user?.nameSurname
        if (user?.isCompany && user?.companyName !== '') return user?.companyName
        if (user?.isCompany && user?.companyName === '') return user?.nameSurname
        return '-'
    }

    return notificationRowId?.map((item) => {
        const user = users?.[item]

        return (
            <Tooltip key={item} content="Przejdź do odbiorcy" showDelay={500} position="right">
                <Link to={`/users/?user_id=${item}`}>
                    <Table.Row className="user-detail-row">
                        <Table.TextCell flexBasis={50} flexGrow={0} flexShrink={0}>
                            {item}
                        </Table.TextCell>
                        <Table.TextCell>{checkingCompany(user)}</Table.TextCell>
                        <Table.TextCell>{user?.email}</Table.TextCell>
                    </Table.Row>
                </Link>
            </Tooltip>
        )
    })
}

const NotificationDetailDialog = ({ isShown, setIsShown, notificationRowId }) => {
    const users = useSelector((state) => state.user.users)

    return (
        <Pane>
            <Dialog
                isShown={isShown}
                title="Lista odbiorców"
                onCloseComplete={() => setIsShown(false)}
                hasFooter={false}
            >
                <Table.Body>
                    <Table.Head>
                        <Table.TextCell flexBasis={50} flexGrow={0} flexShrink={0}>
                            ID
                        </Table.TextCell>
                        <Table.TextCell>Imię Nazwisko / Nazwa firmy</Table.TextCell>
                        <Table.TextCell>E-mail</Table.TextCell>
                    </Table.Head>
                    {renderTableRows(notificationRowId, users)}
                </Table.Body>
            </Dialog>
        </Pane>
    )
}

export default NotificationDetailDialog
