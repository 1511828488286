import { Position, Tooltip } from 'evergreen-ui'
import { Link } from 'react-router-dom'
import { GoPrimitiveDot } from 'react-icons/go'
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs'
import { PieChart } from 'react-minimal-pie-chart'
import Card from '../Card'
import '../../styles/views/home.scss'
import '../../styles/components/table.scss'

const MeterCards = ({ statistics }) => {
    return (
        <Card type="small">
            <div className="card_title">
                <Tooltip content="Przejdź do wszystkich liczników" showDelay={500}>
                    <Link to="/meters">
                        <span>Liczniki</span>
                    </Link>
                </Tooltip>
                <Tooltip
                    content="Przejdź do wszystkich liczników"
                    showDelay={500}
                    position={Position.RIGHT}
                >
                    <Link to="/meters">
                        <BsFillArrowUpRightCircleFill size="17" className="arrow" />
                    </Link>
                </Tooltip>
            </div>
            <div className="card_content">
                <div
                    style={{
                        height: '40%',
                        width: '40%',
                        alignSelf: 'center',
                        display: 'flex'
                    }}
                >
                    <PieChart
                        data={[
                            {
                                title: 'Woda zimna',
                                value: statistics.coldWaterMeters,
                                color: '#5274CC'
                            },
                            {
                                title: 'Woda ciepła',
                                value: statistics.hotWaterMeters,
                                color: '#F84F31'
                            }
                        ]}
                        lineWidth={40}
                        animate
                    />
                </div>
                <p>
                    <span className="color_blue dot">
                        <GoPrimitiveDot />
                    </span>
                    <Tooltip
                        content="Przejdź do liczników wody zimnej"
                        showDelay={500}
                        position={Position.RIGHT}
                    >
                        <Link to="/meters?meter_type=cold_water">
                            Liczniki wody zimnej: {statistics?.coldWaterMeters}
                        </Link>
                    </Tooltip>
                </p>
                <p>
                    <span className="color_red dot">
                        <GoPrimitiveDot />
                    </span>
                    <Tooltip
                        content="Przejdź do liczników wody ciepłej"
                        showDelay={500}
                        position={Position.RIGHT}
                    >
                        <Link to="/meters?meter_type=hot_water">
                            Liczniki wody ciepłej: {statistics?.hotWaterMeters}
                        </Link>
                    </Tooltip>
                </p>
            </div>
        </Card>
    )
}

export default MeterCards
