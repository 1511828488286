/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsArrowLeftCircle, BsFacebook, BsGoogle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import { toaster } from 'evergreen-ui'
import { useGoogleLogin } from '@react-oauth/google'
import FacebookLogin from '@greatsumini/react-facebook-login'

import Input from '../components/Input'
import Button from '../components/Button'
import Footer from '../components/Footer'

import useApi from '../hooks/useApi'

import { setLoginData as setLoginDataAction } from '../redux/slices/profileSlice'

import logo from '../assets/logo-bezlicznik-white.svg'

import { RegisterErrors, LoginErrors, RemindPasswordErrors } from '../models/Auth/Errors'
import RegisterData from '../models/Auth/Register'
import LoginData from '../models/Auth/Login'
import RemindPasswordData from '../models/Auth/RemindPassword'

import {
    fromLoginApi,
    fromSocialLoginApi,
    toLoginApi,
    toSocialLoginApi,
    toRemindPasswordApi,
    toRegisterApi
} from '../utils/Adapters/ProfileApiAdapter'
import {
    fromLoginApiCallError,
    fromRegisterApiCallError,
    fromRemindPasswordApiCallError
} from '../utils/Adapters/errorAdpters/LoginErrorApiAdapter'

import '../styles/views/login.scss'

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const loggedIn = useSelector((state) => state.profile.loggedIn)

    const [animationClass, setAnimationClass] = useState('')
    // form data models
    const [registerData, setRegisterData] = useState(new RegisterData())
    const [loginData, setLoginData] = useState(new LoginData())
    const [remindPasswordData, setRemindPasswordData] = useState(new RemindPasswordData())
    // form errors models
    const [remindPasswordErrors, setRemindPasswordErrors] = useState(new RemindPasswordErrors())
    const [loginErrors, setLoginErrors] = useState(new LoginErrors())
    const [registerErrors, setRegisterErrors] = useState(new RegisterErrors())

    const VALID_EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g

    // validators
    const validateRegisterFields = () => {
        const errors = new RegisterErrors()

        if (!registerData.email.match(VALID_EMAIL_REGEX)) {
            errors.email = 'Adres e-mail jest niepoprawny!'
            errors.isError = true
        }

        if (registerData.password.length < 8) {
            errors.password = 'Hasło musi zawierać conajmniej 8 znaków!'
            errors.isError = true
        }

        if (registerData.password !== registerData.repeatPassword) {
            errors.repeatPassword = 'Hasła nie są identyczne!'
            errors.isError = true
        }

        if (registerData.repeatPassword.length < 8) {
            errors.repeatPassword = 'Hasło musi zawierać conajmniej 8 znaków!'
            errors.isError = true
        }

        return errors
    }

    const validateLoginFields = () => {
        const errors = new LoginErrors()

        if (!loginData.email.match(VALID_EMAIL_REGEX)) {
            errors.email = 'Adres e-mail jest niepoprawny!'
            errors.isError = true
        }

        if (!loginData.password.length) {
            errors.password = 'Wprowadź hasło'
            errors.isError = true
        }

        return errors
    }

    const validateRemindPasswordFields = () => {
        const errors = new RemindPasswordErrors()

        if (!remindPasswordData.email.match(VALID_EMAIL_REGEX)) {
            errors.email = 'Adres e-mail jest niepoprawny!'
            errors.isError = true
        }

        return errors
    }

    // api hooks
    const register = useApi({
        method: 'POST',
        url: '/app/users/registration/',
        toApiAdapter: toRegisterApi,
        fromApiErrorAdapter: fromRegisterApiCallError
    })

    const login = useApi({
        method: 'POST',
        url: '/app/users/login/',
        toApiAdapter: toLoginApi,
        fromApiAdapter: fromLoginApi,
        fromApiErrorAdapter: fromLoginApiCallError
    })

    const googleLogin = useApi({
        method: 'POST',
        url: '/app/users/google_auth/',
        toApiAdapter: toSocialLoginApi,
        fromApiAdapter: fromSocialLoginApi
    })

    const facebookLogin = useApi({
        method: 'POST',
        url: '/app/users/facebook_auth/',
        toApiAdapter: toSocialLoginApi,
        fromApiAdapter: fromSocialLoginApi
    })

    const remindPassword = useApi({
        method: 'POST',
        url: '/app/users/remind_password/',
        toApiAdapter: toRemindPasswordApi,
        fromApiErrorAdapter: fromRemindPasswordApiCallError
    })

    const handleLogin = (response) => {
        dispatch(setLoginDataAction(response))
    }

    // switching tabs
    const handleSwitchToRegister = () => {
        setRegisterErrors(new RegisterErrors())
        setAnimationClass('slide_center_to_left')
    }

    const handleSwitchToRemind = () => {
        setRemindPasswordErrors(new RemindPasswordErrors())
        setAnimationClass('slide_center_to_right')
    }

    const handleSwitchToLoginFromRegister = () => {
        setLoginErrors(new LoginErrors())
        setAnimationClass('slide_left_to_center')
    }

    const handleSwitchToLoginFromRemind = () => {
        setLoginErrors(new LoginErrors())
        setAnimationClass('slide_right_to_center')
    }

    // form buttons click handlers
    const handleRegisterButtonClick = () => {
        const dataErrors = validateRegisterFields()
        setRegisterErrors(dataErrors)

        if (dataErrors.isError) {
            return
        }

        register
            .mutateAsync({
                data: { ...registerData }
            })
            .then(() => {
                setRegisterData(new RegisterData())
                setRegisterErrors(new RegisterErrors())
                toaster.success(
                    'Twoje konto zostało zarejestrowane. Na podany adres email została wysłana wiadomość z linkiem aktywacyjnym. Aktywuj konto, aby zacząć korzystać z serwisu.'
                )
            })
            .catch((error) => {
                const e = fromRegisterApiCallError(error)
                toaster.danger(e.error)
            })
    }

    const handleLoginButtonClick = () => {
        const dataErrors = validateLoginFields()
        setLoginErrors(dataErrors)

        if (dataErrors.isError) {
            return
        }

        login
            .mutateAsync({
                data: { ...loginData }
            })
            .then((response) => {
                handleLogin(fromLoginApi(response))
            })
            .catch((error) => {
                const e = fromLoginApiCallError(error)
                toaster.danger(e.error)
            })
    }

    const handleRemindPasswordButtonClick = () => {
        const dataErrors = validateRemindPasswordFields()
        setRemindPasswordErrors(dataErrors)

        if (dataErrors.isError) {
            return
        }

        remindPassword
            .mutateAsync({
                data: { ...remindPasswordData }
            })
            .then(() => {
                toaster.success('Na podany adres zostały wysłane dalsze instrukcje')
            })
            .catch((error) => {
                const e = fromRemindPasswordApiCallError(error)
                toaster.danger(e.error)
            })
    }

    // key down handlers
    const handleRegisterKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleRegisterButtonClick()
        }
    }

    const handleLoginKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLoginButtonClick()
        }
    }

    const handleRemindKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleRemindPasswordButtonClick()
        }
    }

    useEffect(() => {
        if (loggedIn) {
            navigate('/home')
            toaster.closeAll()
        }
    }, [loggedIn])

    const handleGoogleLogin = (response) => {
        const token = response?.access_token

        if (token) {
            googleLogin.mutateAsync({ data: { token } }).then((res) => {
                handleLogin(fromSocialLoginApi(res))
            })
        } else {
            toaster.error('Błąd logowania')
        }
    }

    const getGoogleCredentials = useGoogleLogin({
        onSuccess: handleGoogleLogin
    })

    const getFacebookCredentials = (response) => {
        const token = response?.accessToken

        if (token) {
            facebookLogin.mutateAsync({ data: { token } }).then((res) => {
                handleLogin(fromSocialLoginApi(res))
            })
        } else {
            toaster.error('Błąd logowania')
        }
    }

    return (
        <div className="main main_background">
            <div className="main_login">
                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className={`animationContainer ${animationClass}`}>
                    <div className="registration_container">
                        <div className="content">
                            <p className="headline">Rejestracja</p>
                            <div
                                className="input_container"
                                tabIndex={0}
                                onKeyDown={handleRegisterKeyDown}
                            >
                                <div className="error_box">
                                    <span>{registerErrors.email}</span>
                                </div>
                                <Input
                                    value={registerData.email}
                                    type="email"
                                    placeholder="E-mail"
                                    name="email"
                                    onChange={(e) =>
                                        setRegisterData((prev) => ({
                                            ...prev,
                                            email: e.target.value
                                        }))
                                    }
                                />
                                <div className="error_box">
                                    <span>{registerErrors.password}</span>
                                </div>
                                <Input
                                    value={registerData.password}
                                    type="password"
                                    placeholder="Hasło"
                                    name="password"
                                    onChange={(e) =>
                                        setRegisterData((prev) => ({
                                            ...prev,
                                            password: e.target.value
                                        }))
                                    }
                                />
                                <div className="error_box">
                                    <span>{registerErrors.repeatPassword}</span>
                                </div>
                                <Input
                                    value={registerData.repeatPassword}
                                    type="password"
                                    placeholder="Powtórz hasło"
                                    name="repeat_password"
                                    onChange={(e) =>
                                        setRegisterData((prev) => ({
                                            ...prev,
                                            repeatPassword: e.target.value
                                        }))
                                    }
                                />
                            </div>
                            <div className="input_container" id="buttons">
                                <Button text="Zarejestruj" onClick={handleRegisterButtonClick} />
                            </div>
                            <button
                                type="button"
                                className="button"
                                onClick={handleSwitchToLoginFromRegister}
                            >
                                <BsArrowLeftCircle size="20" /> Powrót
                            </button>
                        </div>
                    </div>

                    <div className="login_container">
                        <div className="content">
                            <p className="headline">Zaloguj się</p>
                            <div
                                className="input_container"
                                tabIndex={0}
                                onKeyDown={handleLoginKeyDown}
                            >
                                <div className="error_box">
                                    <span>{loginErrors.email}</span>
                                </div>
                                <Input
                                    type="email"
                                    placeholder="E-mail"
                                    name="email"
                                    onChange={(e) =>
                                        setLoginData((prev) => ({ ...prev, email: e.target.value }))
                                    }
                                />
                                <div className="error_box">
                                    <span>{loginErrors.password}</span>
                                </div>
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder="Hasło"
                                    onChange={(e) =>
                                        setLoginData((prev) => ({
                                            ...prev,
                                            password: e.target.value
                                        }))
                                    }
                                />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <button
                                    type="button"
                                    className="button"
                                    onClick={handleSwitchToRemind}
                                >
                                    Zapomniałeś hasła?
                                </button>
                            </div>
                            <div className="input_container" id="buttons">
                                <Button text="Zaloguj się" onClick={handleLoginButtonClick} />
                                <div className="row">
                                    <Button onClick={() => getGoogleCredentials()}>
                                        <BsGoogle size="24" />
                                        Google
                                    </Button>
                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FB_APP_ID}
                                        className="buttonComponent"
                                        fields="name,email"
                                        onSuccess={getFacebookCredentials}
                                        onFail={(error) => toaster.error('Błąd autoryzacji')}
                                    >
                                        <BsFacebook size="24" />
                                        Facebook
                                    </FacebookLogin>
                                </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <button
                                    type="button"
                                    className="button button_register"
                                    onClick={handleSwitchToRegister}
                                >
                                    Nie posiadasz konta? Zarejestruj się!
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="forgot_container">
                        <div className="content">
                            <p className="headline">Przypomnij hasło</p>
                            <div
                                className="input_container"
                                tabIndex={0}
                                onKeyDown={handleRemindKeyDown}
                            >
                                <div className="error_box">
                                    <span>{remindPasswordErrors.email}</span>
                                </div>
                                <Input
                                    type="email"
                                    placeholder="E-mail"
                                    onChange={(e) =>
                                        setRemindPasswordData((prev) => ({
                                            ...prev,
                                            email: e.target.value
                                        }))
                                    }
                                />
                            </div>
                            <div className="input_container" style={{ marginTop: '10px' }}>
                                <Button
                                    text="Przypomnij hasło"
                                    onClick={() => handleRemindPasswordButtonClick()}
                                />
                            </div>
                            <button
                                type="button"
                                className="button"
                                onClick={handleSwitchToLoginFromRemind}
                            >
                                <BsArrowLeftCircle size="20" /> Powrót
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Login
