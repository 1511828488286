import { Position, Tooltip } from 'evergreen-ui'
import { Link } from 'react-router-dom'
import { GoPrimitiveDot } from 'react-icons/go'
import { PieChart } from 'react-minimal-pie-chart'
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs'
import Card from '../Card'
import '../../styles/views/home.scss'
import '../../styles/components/table.scss'

const MeasurementCards = ({ statistics }) => {
    return (
        <Card type="small">
            <div className="card_title">
                <Tooltip content="Przejdź do wszystkich odczytów" showDelay={500}>
                    <Link to="/readings">
                        <span>Odczyty</span>
                    </Link>
                </Tooltip>

                <Tooltip
                    content="Przejdź do wszystkich odczytów"
                    showDelay={500}
                    position={Position.RIGHT}
                >
                    <Link to="/readings">
                        <BsFillArrowUpRightCircleFill size="17" className="arrow" />
                    </Link>
                </Tooltip>
            </div>
            <div className="card_content">
                <div
                    style={{
                        height: '40%',
                        width: '40%',
                        alignSelf: 'center',
                        display: 'flex'
                    }}
                >
                    <PieChart
                        data={[
                            {
                                title: 'Zweryfikowane',
                                value: statistics.verifiedMeasurements,
                                color: '#21b49b'
                            },
                            {
                                title: 'Odrzucone',
                                value: statistics.rejectedMeasurements,
                                color: '#F84F31'
                            },
                            { title: 'Nowe', value: statistics.newMeasurements, color: '#5274CC' }
                        ]}
                        lineWidth={40}
                        animate
                        viewBoxSize={[100, 100]}
                    />
                </div>

                <p>
                    <span className="color_green dot">
                        <GoPrimitiveDot />
                    </span>
                    <Tooltip
                        content="Przejdź do zatwierdzonych odczytów"
                        showDelay={500}
                        position={Position.RIGHT}
                    >
                        <Link to="/readings?state=verified">
                            Zatwierdzone odczyty: {statistics.verifiedMeasurements}
                        </Link>
                    </Tooltip>
                </p>
                <p>
                    <span className="color_red dot">
                        <GoPrimitiveDot />
                    </span>
                    <Tooltip
                        content="Przejdź do odrzuconych odczytów"
                        showDelay={500}
                        position={Position.RIGHT}
                    >
                        <Link to="/readings?state=rejected">
                            Odrzucone odczyty: {statistics.rejectedMeasurements}
                        </Link>
                    </Tooltip>
                </p>
                <p>
                    <span className="color_blue dot">
                        <GoPrimitiveDot />
                    </span>
                    <Tooltip
                        content="Przejdź do nowych odczytów"
                        showDelay={500}
                        position={Position.RIGHT}
                    >
                        <Link to="/readings?state=new">
                            Nowe odczyty: {statistics.newMeasurements}
                        </Link>
                    </Tooltip>
                </p>
            </div>
        </Card>
    )
}

export default MeasurementCards
