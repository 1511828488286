import NotificationLog from '../../redux/models/NotificationLog'

const fromNotificationLogApi = (apiObj) => {
    const responseData = apiObj.data

    const parsedData = Object.assign(
        {},
        ...responseData.results.map((log, index) => {
            return {
                [log.pk]: new NotificationLog({ ...log, index })
            }
        })
    )

    return {
        logs: parsedData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }
}

export default fromNotificationLogApi
