import { useSelector } from 'react-redux'

import { IoMdCopy } from 'react-icons/io'

import { Tooltip, Pill, toaster } from 'evergreen-ui'

import dateConvert from './formatDate'

const handleCopyNipClick = (user) => {
    navigator.clipboard.writeText(user.nip)
    toaster.notify('Skopiowano NIP do schowka')
}

const handleCopyClick = (user) => {
    navigator.clipboard.writeText(user.email)
    toaster.notify('Skopiowano adres do schowka')
}

export const renderUserPills = (usersArg, navigate, fullname = false) => {
    const users = useSelector((state) => state.user.users)
    let usersList = usersArg

    const renderName = (user) => {
        if (!fullname) return user

        if (users[user]?.isCompany && users[user].companyName) return users[user].companyName
        if (users[user]?.nameSurname) return users[user].nameSurname
        return '-'
    }

    if (usersList.length === 0) usersList = ['-']

    return usersList.map((user) => {
        return (
            <Tooltip content="Przejdź do odbiorcy" showDelay={500} key={user + navigate}>
                <Pill
                    className="box-pills__notification"
                    isInteractive
                    onClick={() => navigate(`/users?user_id=${user}`)}
                >
                    {renderName(user)}
                </Pill>
            </Tooltip>
        )
    })
}

export const renderUserData = (user, isModerator = false) => {
    return (
        <div className="data_grid">
            {isModerator && (
                <>
                    <span>ID odbiorcy:</span>
                    <span>{user.pk}</span>
                </>
            )}
            <span>Nr klienta:</span>
            <span>{user.clientNumber ? user.clientNumber : '-'}</span>
            <span>Data rejestracji:</span>
            <span>{dateConvert(user.createdAt)}</span>
            {isModerator && (
                <>
                    <span className="inLine">E-mail:</span>
                    <span className="inLine">
                        {user.email}
                        <Tooltip content="Skopiuj adres email" showDelay={500}>
                            <button
                                type="button"
                                className="clearButton"
                                onClick={() => handleCopyClick(user)}
                            >
                                <IoMdCopy size={20} />
                            </button>
                        </Tooltip>
                    </span>
                </>
            )}
            <span id="spacer_row">Dane rozliczeniowe:</span>
            {user.isCompany ? (
                <>
                    <span className="indent">Nazwa firmy:</span>
                    <span>{user.companyName ? user.companyName : '-'}</span>
                    <span className="inLine email indent">NIP:</span>
                    <span className="inLine">
                        {user.nip ? user.nip : '-'}
                        {user.nip ? (
                            <button
                                type="button"
                                className="clearButton"
                                onClick={() => handleCopyNipClick(user)}
                            >
                                <IoMdCopy size={20} />
                            </button>
                        ) : null}
                    </span>
                </>
            ) : (
                <>
                    <span className="indent">Imię i nazwisko:</span>
                    <span>{user.nameSurname ? user.nameSurname : '-'}</span>
                    <span className="indent">Ulica i numer:</span>
                    <span>{user.street ? user.street : '-'}</span>
                    <span className="indent">Kod pocztowy:</span>
                    <span>{user.zipCode ? user.zipCode : '-'}</span>
                    <span className="indent">Miasto:</span>
                    <span>{user.city ? user.city : '-'}</span>
                </>
            )}
        </div>
    )
}
