const faqData = [
    {
        sectionTitle: 'Poniżej znajdziesz odpowiedzi na często zadawane pytania',
        sectionContent: [
            {
                questionTitle: 'Jak zmienić hasło do mojego konta?',
                questionContent:
                    'Można to zrobić, rozwijając menu w prawym górnym rogu aplikacji obok nazwy użytkownika, następnie, przechodząc do ustawień i klikając przycisk zmień hasło.'
            },
            {
                questionTitle: 'Jak mogę wysłać powiadomienie do użytkownika?',
                questionContent:
                    'Można to zrobić, przechodząc do zakładki powiadomień i następnie w prawym górnym rogu, klikając w przycisk wyślij nowe powiadomienie.'
            },
            {
                questionTitle: 'Jak mogę usunąć konto?',
                questionContent:
                    'Aby usunąć konto należy w aplikacji mobilnej przejść do sekcji ustawień a następnie do zakładki usuń konto.'
            },
            {
                questionTitle:
                    'Czy mogę zobaczyć szczegółowe informacje dotyczące historii zużycia wody?',
                questionContent:
                    'Tak, w tym celu należy przejść do zakładki liczniki. Następnie, rozwijając wybrany wiersz, wykorzystując strzałkę w dół umieszczoną z prawej strony wiersza. Znajdziesz tam wykres prezentujący odczyty w określonym czasie.'
            },
            {
                questionTitle:
                    'Jak skontaktować się z obsługą techniczną w przypadku problemów z aplikacją?',
                questionContent:
                    'Możesz to zrobić w zakładce zgłoś błąd, odnośnik został umieszczony w stopce.'
            },
            {
                questionTitle:
                    'Jak ustawić preferowane formy powiadomień (np. e-mail, powiadomienia mobilne, sms)?',
                questionContent:
                    'Podczas wysyłania powiadomienia, w drugim kroku wyświetlana jest lista kanałów powiadomień, należy zaznaczyć odpowiednie, wykorzystując pola do zaznaczania.'
            },
            {
                questionTitle:
                    'Czy istnieje możliwość zaplanowania automatycznych powiadomień o konieczności odczytu licznika?',
                questionContent:
                    'Nie, natomiast aplikacja mobilna automatycznie wyświetla powiadomienie w okresie 7 dni do końca miesiąca o konieczności wykonania odczytu.'
            },
            {
                questionTitle:
                    'Czy istnieje możliwość pobrania kopii rachunków za wodę w formacie elektronicznym?',
                questionContent:
                    'Tak, jest to jak najbardziej możliwe, przejdź do zakładki dokumenty i w wybranym wierszu kliknij przycisk zapisz.'
            },
            {
                questionTitle: 'Czy mogę zmienić dane osobowe powiązane z moim kontem?',
                questionContent:
                    'Można to zrobić, rozwijając menu w prawym górnym rogu aplikacji obok nazwy użytkownika, następnie, przechodząc do ustawień i klikając przycisk edytuj informacje.'
            },
            {
                questionTitle: 'Jak mogę zweryfikować nowego odbiorcę?',
                questionContent:
                    'W tym celu przejdź do zakładki odbiorcy, następnie w wybranym wierszu kliknij przycisk weryfikuj, pojawi się okienko z przesłanymi danymi. Po sprawdzeniu, czy wszystko jest poprawnie możesz kliknąć weryfikuj.'
            },
            {
                questionTitle: 'Czy można odrzucić już zweryfikowanego odbiorcę?',
                questionContent:
                    'Nie, nie jest to możliwe. W tym celu należy skontaktować się z obsługą serwisu.'
            },
            {
                questionTitle: 'Czy mogę załączyć pliki lub notatki do raportów odczytu licznika?',
                questionContent:
                    'Tak, możesz to zrobić z zakładce dokumenty, wybierając typ dokumentu jako inny.'
            },
            {
                questionTitle:
                    'Czy po zatwierdzeniu odbiorcy za każdym razem muszę wysyłać ręcznie powiadomienie',
                questionContent:
                    'Nie, powiadomienia dotyczące zmiany stanu weryfikacji odbiorcy, czy dodania dokumentu są wysyłane automatycznie.'
            }
        ]
    }
]
export default faqData
