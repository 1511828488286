import { createSlice } from '@reduxjs/toolkit'

import Statistic from '../models/Statistic'

export const StatisticSlice = createSlice({
    name: 'statistic',
    initialState: {
        statistics: new Statistic()
    },
    reducers: {
        setStatistics: (state, action) => {
            state.statistics = action.payload
        }
    }
})

export const { setStatistics } = StatisticSlice.actions
export default StatisticSlice.reducer
