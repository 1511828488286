import { Textarea, TextInput, toaster } from 'evergreen-ui'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import LeftMenu from '../components/LeftMenu'
import TopBar from '../components/TopBar/TopBar'
import useApi from '../hooks/useApi'

import '../styles/views/report.scss'
import toReportApi from '../utils/Adapters/ReportApiAdapter'

const Report = () => {
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const [raportEmail, setRaportEmail] = useState('')
    const [raportContent, setRaportContent] = useState('')
    const [isEmailError, setIsEmailError] = useState(false)
    const [isContentError, setIsContentError] = useState(false)
    const [emailErrorText, setEmailErrorText] = useState('To pole jest wymagane')

    const sendReport = useApi({
        method: 'POST',
        apiKey,
        url: '/app/tickets/tickets/',
        toApiAdapter: toReportApi
    })

    const validateEmailField = (email) => {
        const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g

        if (email.length > 0) {
            if (email.match(isValidEmail)) {
                setIsEmailError(false)
                setEmailErrorText('To pole jest wymagane')
                return true
            }
            setIsEmailError(true)
            setEmailErrorText('Adres e-mail jest niepoprawny')
            return false
        }
        setIsEmailError(true)
        setEmailErrorText('To pole jest wymagane')
        return false
    }

    const handleSendRaportButtonClick = () => {
        if (validateEmailField(raportEmail) && raportContent.length > 0) {
            sendReport
                .mutateAsync({
                    data: {
                        raportEmail,
                        raportContent
                    }
                })
                .then((e) => {
                    setRaportEmail('')
                    setRaportContent('')
                    toaster.success('Zgłoszenie zostało pomyślnie wysłane')
                })
        }
        if (raportEmail.length === 0) setIsEmailError(true)
        if (raportContent.length === 0) setIsContentError(true)
    }

    return (
        <div className="main">
            <TopBar />
            <LeftMenu />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Zgłoś błąd</div>
                </div>
                <div className="container">
                    <div className="report">
                        <div className="report_title">
                            <span>
                                Jeśli znalazłeś/aś jakiś błąd odnośnie funkcjonowania naszego
                                serwisu, wyślij poniższy formularz:
                            </span>
                        </div>
                        <div className="report_form">
                            {isEmailError ? (
                                <div className="report_form_alert">{emailErrorText}</div>
                            ) : (
                                <div className="report_form_no_alert" />
                            )}
                            <TextInput
                                required
                                className={`report_form_title${isEmailError ? '_error' : ''}`}
                                placeholder="Wpisz email*"
                                onChange={(e) => {
                                    setRaportEmail(e.target.value)
                                    setIsEmailError(false)
                                }}
                                value={raportEmail}
                            />
                            {isContentError ? (
                                <div className="report_form_alert">To pole jest wymagane</div>
                            ) : (
                                <div className="report_form_no_alert" />
                            )}
                            <Textarea
                                placeholder="Wpisz treść*"
                                className={`report_form_content${isContentError ? '_error' : ''}`}
                                onChange={(e) => {
                                    setRaportContent(e.target.value)
                                    setIsContentError(false)
                                }}
                                value={raportContent}
                            />
                        </div>
                        <div className="report_button_box">
                            <button type="button" onClick={handleSendRaportButtonClick}>
                                Wyślij zgłoszenie
                            </button>
                        </div>
                        <div className="report_info_box">
                            <span>
                                Administratorem danych jest NG Code (Wrocławska 41/43, 56-400
                                Oleśnica), kontakt: kontakt@ngcode.pl Dane będziemy przetwarzać w
                                celu odpowiedzi na Twoje zapytanie. Masz także prawo: dostępu do
                                swoich danych, ich sprostowania, usunięcia, ograniczenia
                                przetwarzania oraz przenoszenia, sprzeciwu wobec przetwarzania
                                danych, a także złożenia skargi dotyczącej przetwarzania danych.
                                <Link to="/security ">Tutaj </Link>dowiesz się więcej o tym, jak
                                chronimy Twoje dane.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Report
