const groups = [
    {
        label: 'Klienci Indywidualni',
        value: 'individual_clients'
    },
    {
        label: 'Klienci instytucjonalni',
        value: 'companies'
    }
]
export default groups
