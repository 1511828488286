import { Button, SelectMenu } from 'evergreen-ui'

const SelectMenuCustom = ({
    elemenets,
    setSelected,
    selected,
    title,
    closeOnSelect = false,
    isMultiSelect = false
}) => {
    const handleAddElementToSelectedList = (e) => {
        if (isMultiSelect) {
            const tmp = []
            tmp.push(e)
            if (selected.length > 0) {
                return setSelected((state) => {
                    const isInList = state.some((val) => val === e)
                    if (isInList) return state
                    return state.concat(tmp)
                })
            }
            return setSelected(tmp)
        }
        return setSelected(e)
    }

    const removeElementFromSelectedList = (selectedId) => {
        const selectedItems = selected.filter((i) => i !== selectedId)
        setSelected(selectedItems)
    }

    return (
        <div className="select_menu">
            <SelectMenu
                isMultiSelect={isMultiSelect}
                selected={selected}
                title={title}
                options={elemenets}
                onSelect={(item) => handleAddElementToSelectedList(item.value)}
                onDeselect={(item) => removeElementFromSelectedList(item.value)}
                closeOnSelect={closeOnSelect}
                disabled
            >
                <Button>{title}</Button>
            </SelectMenu>
        </div>
    )
}
export default SelectMenuCustom
