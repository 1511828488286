import HistoryLog from '../../redux/models/HistoryLog'

const fromHistoryLogApi = (apiObj) => {
    const responseData = apiObj.data

    const parsedData = Object.assign(
        {},
        ...responseData.results.map((log) => {
            return {
                [log.pk]: new HistoryLog({
                    ...log,
                    createdBy: log.created_by,
                    createdAt: log.created_at,
                    tenantMember: log.tenant_member
                })
            }
        })
    )

    const data = {
        historyLogs: parsedData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }
    return data
}

export default fromHistoryLogApi
