/* eslint-disable camelcase */
class Document {
    constructor(data = {}) {
        const {
            pk,
            tenant,
            type,
            user,
            measurements,
            moderator_name,
            file,
            created_by,
            created_at,
            amount,
            number,
            updated_at,
            is_paid,
            index
        } = data

        this.id = pk || null
        this.measurement = measurements || []
        this.tenant = tenant || ''
        this.type = type || ''
        this.user = user || null
        this.moderatorName = moderator_name || ''
        this.file = file || ''
        this.createdBy = created_by || ''
        this.createdAt = created_at || ''
        this.amount = amount || null
        this.number = number || null
        this.updatedAt = updated_at || ''
        this.isPaid = is_paid || false
        this.index = index
    }
}

export default Document
