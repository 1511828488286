import { useSelector } from 'react-redux'

import { Table } from 'evergreen-ui'

import PaginationButtons from '../PaginationButtons'

import NoResultsRow from '../ListRows/NoResultsRow'
import MeasurementUserRow from './MeasurementUserRow'

const TableRows = ({ measurements }) => {
    if (Object.values(measurements).length === 0) {
        return <NoResultsRow />
    }
    return Object.entries(measurements)
        .reverse()
        .map(([key, value]) => {
            return <MeasurementUserRow row={value} key={key} />
        })
}

const TableMeasurements = ({
    selectedMeter,
    pageCount,
    onPageClick,
    measurementPreviousPage,
    measurementNextPage,
    measurementResultsCount,
    rowLimit
}) => {
    const meters = useSelector((state) => state.meter.meters)
    const measurements = useSelector((state) => state.measurement.measurements)

    return (
        <div className="table_home_measurement">
            <div className="container_main">
                <Table className="table">
                    {/* <span className="table_title">{`Odczyty z licznika: "${meters[selectedMeter]?.name}"`}</span> */}
                    {meters[selectedMeter]?.name ? (
                        <span className="table_title">{`Odczyty z licznika: "${meters[selectedMeter].name}"`}</span>
                    ) : (
                        <span className="table_title">
                            Odczyty z licznika: brak wybranego licznika
                        </span>
                    )}

                    <Table.Head className="header">
                        <Table.TextHeaderCell className="table_title_col">
                            Typ licznika
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="table_title_col">
                            Wartość odczytu
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="table_title_col">
                            Status
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="table_title_col">
                            Data
                        </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body>
                        <TableRows measurements={measurements} />
                    </Table.Body>
                </Table>
            </div>
            <PaginationButtons
                pageCount={pageCount}
                onPageClick={onPageClick}
                prevPage={measurementPreviousPage}
                nextPage={measurementNextPage}
                resultCount={measurementResultsCount}
                apiLimit={rowLimit}
            />
        </div>
    )
}

export default TableMeasurements
