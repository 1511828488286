import { useState } from 'react'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'

import '../styles/components/input.scss'

const Input = ({ type, placeholder, onChange, value }) => {
    const [isVisible, setIsVisible] = useState(false)

    const handleChangeVisibilityClick = () => {
        setIsVisible((current) => !current)
    }

    const getInputType = () => {
        if (type !== 'password') return type

        if (isVisible) return 'text'
        return type
    }

    return (
        <div className="container_input_wrapper">
            <input
                value={value}
                type={getInputType()}
                placeholder={placeholder}
                className={`container_input ${type === 'password' ? 'container_password' : ''}`}
                autoComplete={type === 'password' ? 'current-password' : 'email'}
                onChange={onChange}
            />
            {type === 'password' && (
                <button
                    type="button"
                    className="container_icon"
                    onClick={handleChangeVisibilityClick}
                >
                    {isVisible ? <AiOutlineEyeInvisible size="20" /> : <AiOutlineEye size="20" />}
                </button>
            )}
        </div>
    )
}

export default Input
