import { useNavigate } from 'react-router-dom'

import Footer from '../../components/Footer'
import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'

import brokenPipe from '../../assets/broken-pipe.png'

import '../../styles/views/PageNotFound.scss'

const PageNotFound = () => {
    const navigation = useNavigate()

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="home" />
            <div className="main_content">
                <div className="error">
                    <div className="error_leftBox">
                        <img
                            src={brokenPipe}
                            alt="Broken Pipe Clip Art@clipartmax.com"
                            className="photo"
                        />
                        <div className="abosulete">
                            <div className="drop" />
                            <div className="wave" />
                        </div>
                    </div>
                    <div className="error_rightBox">
                        <div className="titleError">404</div>
                        <div className="subtitleError">Strony nie znaleziono!</div>
                        <div className="textError">Wygląda na to, że się zgubiłeś...</div>
                        <div className="buttonBox">
                            <button
                                type="button"
                                onClick={() => navigation('/home')}
                                className="clear"
                            >
                                <span>Zgłoś błąd</span>
                            </button>
                            <button
                                type="button"
                                onClick={() => navigation('/home')}
                                className="blue"
                            >
                                <span>Strona główna</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PageNotFound
