import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Table, Tooltip, Position, Checkbox } from 'evergreen-ui'
import { BsFileEarmarkPdf, BsSpeedometer, BsFillPersonFill } from 'react-icons/bs'
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'
import { MdOutlinePayment } from 'react-icons/md'
import { TiTick } from 'react-icons/ti'
import { AiOutlineHistory } from 'react-icons/ai'

import { useDispatch, useSelector } from 'react-redux'
import DocumentExpandedRow from './DocumentExpandedRow'

import LoadingCell from '../LoadingCell'
import dateConvert from '../../../utils/formatDate'
import renderDocumentType from '../../../utils/renderDocumentType'
import savePaymentData from '../../../utils/savePaymentData'

const DocumentTableRows = ({
    document,
    user,
    meter,
    index,
    isExpanded,
    onExpand,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate,
    confirmPaymentPopup
}) => {
    const dispatch = useDispatch()
    const isModerator = useSelector((state) => state.profile.profile.isModerator)
    const [isSelected, setIsSelected] = useState(false)

    const handleSelectClick = (e) => {
        const newState = e.target.checked
        setIsSelected(newState)
        onRowSelected(document.id)
    }

    useEffect(() => {
        if (isSelectedEveryRow) setIsSelected(isSelectedEveryRow)
        else if (!isSelectedEveryRow && !isSelectedEveryRowIndeterminate) setIsSelected(false)
    }, [isSelectedEveryRow, isSelectedEveryRowIndeterminate])

    const callPayment = () => {
        savePaymentData(dispatch, document)
        confirmPaymentPopup()
    }

    const renderIsPaid = () => {
        return (
            <div className="payment_status_container">
                {document?.isPaid ? (
                    <>
                        <TiTick size="18" color="#00b300" />
                        Opłacono
                    </>
                ) : (
                    <>
                        <AiOutlineHistory size="18" color="#FFA500" />
                        Oczekujące
                    </>
                )}
            </div>
        )
    }

    return (
        <Table.Row
            key={index}
            className={`row ${isSelected ? 'selected' : ''} ${isExpanded ? 'in_color' : ''}`}
        >
            <Table.TextCell flexBasis="40px" flexGrow={0} flexShrink={0}>
                <Checkbox checked={isSelected} onChange={(e) => handleSelectClick(e)} />
            </Table.TextCell>
            <Table.TextCell> {renderDocumentType(document?.type)} </Table.TextCell>
            {user !== undefined ? (
                <Table.TextCell>
                    <Tooltip
                        content="Przejdź do odbiorcy"
                        showDelay={500}
                        position={Position.BOTTOM_LEFT}
                    >
                        <Link to={`/users?user_id=${user?.pk}`}>
                            {user?.isCompany ? user?.companyName : user?.nameSurname}
                        </Link>
                    </Tooltip>
                </Table.TextCell>
            ) : (
                <LoadingCell />
            )}
            <Table.TextCell>{document.moderatorName}</Table.TextCell>
            <Table.TextCell>{dateConvert(document?.createdAt)}</Table.TextCell>
            {isModerator && <Table.TextCell>{renderIsPaid()}</Table.TextCell>}
            <Table.TextCell flexBasis={isModerator ? '220px' : '350px'} flexShrink={0} flexGrow={0}>
                <div className="button_container">
                    <Tooltip content="Filtruj po odbiorcy" showDelay={500}>
                        <Link to={`/documents?user_id=${meter?.tenantMember}`}>
                            <button type="button" className="button">
                                <BsFillPersonFill size="18" />
                            </button>
                        </Link>
                    </Tooltip>
                    <Tooltip content="Filtruj po liczniku" showDelay={500}>
                        <Link to={`/documents?meter_id=${meter?.pk}`}>
                            <button type="button" className="button">
                                <BsSpeedometer size="18" />
                            </button>
                        </Link>
                    </Tooltip>
                    <a href={document?.file} target="_blank" rel="noopener noreferrer">
                        <button
                            type="button"
                            className={`button invoiceButton ${isModerator ? 'blueButton' : ''}`}
                        >
                            <span>Podgląd</span>
                            <BsFileEarmarkPdf size="18" />
                        </button>
                    </a>
                    {!isModerator && (
                        <button
                            type="button"
                            className={`button invoiceButton ${
                                document.isPaid ? 'payment_done_btn' : 'blueButton'
                            }`}
                            onClick={() => callPayment()}
                            disabled={document.isPaid}
                        >
                            <span>{document.isPaid ? 'Opłacono' : 'Zapłać'}</span>
                            {document.isPaid ? <TiTick /> : <MdOutlinePayment />}
                        </button>
                    )}
                </div>
            </Table.TextCell>
            <Table.TextCell className="gap" />
            <Table.TextCell className="alone_row">
                <Tooltip
                    content="Szczegóły"
                    showDelay={500}
                    key={!isExpanded ? 0 : 1}
                    position="top"
                >
                    <button type="button" onClick={onExpand} className="clickable">
                        {isExpanded ? (
                            <BiUpArrowAlt size="20" className="icon_white" />
                        ) : (
                            <BiDownArrowAlt size="20" />
                        )}
                    </button>
                </Tooltip>
            </Table.TextCell>
        </Table.Row>
    )
}

const DocumentRow = ({
    document,
    user,
    meter,
    index,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate,
    setIsDetailsShown,
    setDocumentDetailId,
    confirmPaymentPopup
}) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleExpandChange = () => {
        setIsExpanded((current) => !current)
    }

    return (
        <>
            <DocumentTableRows
                document={document}
                user={user}
                meter={meter}
                index={index}
                isExpanded={isExpanded}
                onExpand={handleExpandChange}
                onRowSelected={onRowSelected}
                isSelectedEveryRow={isSelectedEveryRow}
                isSelectedEveryRowIndeterminate={isSelectedEveryRowIndeterminate}
                setIsDetailsShown={setIsDetailsShown}
                setDocumentDetailId={setDocumentDetailId}
                confirmPaymentPopup={confirmPaymentPopup}
            />
            <DocumentExpandedRow isExpanded={isExpanded} document={document} />
        </>
    )
}

export default DocumentRow
