import Statistic from '../../redux/models/Statistic'

export const fromGetStatisticApi = (apiObj) => {
    const responseData = apiObj.data
    return new Statistic({
        coldWaterMeters: responseData.cold_water_meters,
        gardenWaterMeters: responseData.garden_water_meters,
        hotWaterMeters: responseData.hot_water_meters,
        newCompanyTenantMembers: responseData.new_company_tenant_members,
        newIndividualTenantMembers: responseData.new_individual_tenant_members,
        newMeasurements: responseData.new_measurements,
        newTenantMembers: responseData.new_tenant_members,
        rejectedCompanyTenantMembers: responseData.rejected_company_tenant_members,
        rejectedIndividualTenantMembers: responseData.rejected_individual_tenant_members,
        rejectedMeasurements: responseData.rejected_measurements,
        rejectedTenantMembers: responseData.rejected_tenant_members,
        verifiedCompanyTenantMembers: responseData.verified_company_tenant_members,
        verifiedIndividualTenantMembers: responseData.verified_individual_tenant_members,
        verifiedMeasurements: responseData.verified_measurements,
        verifiedTenantMembers: responseData.verified_tenant_members
    })
}

export default fromGetStatisticApi
