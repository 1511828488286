import Footer from '../components/Footer'
import LeftMenu from '../components/LeftMenu'
import TopBar from '../components/TopBar/TopBar'

import '../styles/views/contact.scss'

const Contact = () => {
    return (
        <div className="main">
            <TopBar />
            <LeftMenu />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Kontakt i wsparcie techniczne</div>
                </div>
                <div className="container">
                    <div className="contact">
                        <div className="contact__column-info">
                            <span>Adres:</span>
                            <a
                                className="contact__adres"
                                href="https://goo.gl/maps/QHGy5L28a9arqMbD9"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>ul. Wrocławska 41/43</span>
                                <span> 56-400 Oleśnica</span>
                            </a>
                        </div>
                        <div className="contact__column-info">
                            <span>Kontakt:</span>
                            <a className="margin_l margin_b" href="tel:+48123321123">
                                <span>+48 123321123</span>
                            </a>
                            <a className="margin_l" href="mailto:kontakt@ngcode.pl">
                                <span>E-mail: kontakt@ngcode.pl</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact
