import '../../styles/components/sides.scss'

const ProfileEditPasswordSide = ({
    onSaveClick,
    onReturnClick,
    onTextChanged,
    currentPasswordErrorText,
    newPasswordErrorText,
    repeatNewPasswordErrorText
}) => {
    return (
        <div className="profileSide_content">
            <div className="title">
                <span> Aktualizacja hasła </span>
            </div>
            <div className="optionsBox">
                <div className="optionsBox_info">
                    <div className="text">
                        <span className="text_title">Aktualne hasło </span>
                        <input
                            type="password"
                            onChange={onTextChanged}
                            placeholder={
                                !currentPasswordErrorText
                                    ? 'Aktualne hasło'
                                    : currentPasswordErrorText
                            }
                            name="actualPassword"
                            autoComplete="Aktualne hasło"
                            className={`${currentPasswordErrorText.length > 0 && 'errorInput'}`}
                        />
                    </div>
                    <div className="text">
                        <span className="text_title">Nowe hasło </span>
                        <input
                            type="password"
                            onChange={onTextChanged}
                            placeholder={
                                !newPasswordErrorText ? 'Nowe hasło ' : newPasswordErrorText
                            }
                            name="newPassword"
                            autoComplete="new-password"
                            className={`${newPasswordErrorText.length > 0 && 'errorInput'}`}
                        />
                    </div>
                    <div className="text">
                        <span className="text_title">Potwierdź nowe hasło </span>
                        <input
                            type="password"
                            onChange={onTextChanged}
                            placeholder={
                                !repeatNewPasswordErrorText
                                    ? 'Potwierdź nowe hasło'
                                    : repeatNewPasswordErrorText
                            }
                            name="repeatNewPassword"
                            autoComplete="new-password"
                            className={`${repeatNewPasswordErrorText.length > 0 && 'errorInput'}`}
                        />
                    </div>
                </div>
                <div className="optionsBox_buttons">
                    <button type="button" onClick={onReturnClick}>
                        Anuluj
                    </button>
                    <button type="button" onClick={onSaveClick} className="EditButton">
                        Zmień hasło
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProfileEditPasswordSide
