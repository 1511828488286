import { SelectMenu, Button } from 'evergreen-ui'

import '../styles/components/rowLimit.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setRowLimit } from '../redux/slices/profileSlice'

export const RowLimit = () => {
    const dispatch = useDispatch()
    const rowLimit = useSelector((state) => state.profile.rowLimit)

    const options = [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '30', value: 30 },
        { label: '40', value: 40 },
        { label: '50', value: 50 }
    ]

    const handleChangeClick = ({ key, value }) => {
        dispatch(setRowLimit(value))
    }

    return (
        <div className="row_limit_container">
            <SelectMenu
                title="Limit wierszy"
                options={options}
                onSelect={handleChangeClick}
                hasFilter={false}
                closeOnSelect
            >
                <Button data-testid="select-menu-option">Limit wierszy: {rowLimit}</Button>
            </SelectMenu>
        </div>
    )
}

export default RowLimit
