import { BiCalculator, BiErrorCircle } from 'react-icons/bi'
import { HiOutlineDocument } from 'react-icons/hi'
import { SiBookmeter } from 'react-icons/si'

import dateConvert from '../../../utils/formatDate'

const renderIconNotification = (img) => {
    switch (img) {
        case 'SettingsTenantList':
            return <HiOutlineDocument size={30} />
        case 'StatsMeters':
            return <SiBookmeter size={30} />
        case 'meter':
            return <BiCalculator size={30} />
        default:
            return <BiErrorCircle size={30} />
    }
}

const UserNotification = ({ notificationRow, setIsShownNotification, setDetailNotification }) => {
    const handleSelectedClick = () => {
        setDetailNotification(notificationRow)
        setIsShownNotification(true)
    }

    return (
        <button type="button" className="topBar_notification" onClick={handleSelectedClick}>
            <div className="topBar_notification_tittle">
                <span className="topBar_notification_tittle-icon">
                    {renderIconNotification(notificationRow.data.type)}
                </span>
                <span>{notificationRow.title}</span>
            </div>
            <p>Kliknij, aby uzyskać więcej szczegółów.</p>
            <span className="topBar_notification_date">
                {dateConvert(notificationRow.createdAt)}
            </span>
        </button>
    )
}

export default UserNotification
