import { toaster } from 'evergreen-ui'

export const fromApiCallError = (errObj) => {
    if (errObj.response.status === 401) {
        toaster.warning('Błąd autoryzacji')
        return { error: 'Błąd autoryzacji' }
    }
    toaster.warning('Błąd serwera')
    return { error: 'Błąd serwera' }
}

export default fromApiCallError
