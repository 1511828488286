import { useSelector } from 'react-redux'

import HomeModerator from './Home.moderator.view'
import HomeUser from './Home.user.view'

const Home = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? <HomeModerator /> : <HomeUser />
}

export default Home
