import { useSelector } from 'react-redux'

import NotificationsModerator from './Notifications.moderator.view'
import NotificationsUser from './Notifications.user.view'

const Notifications = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? <NotificationsModerator /> : <NotificationsUser />
}

export default Notifications
