const getChartData = (data) => {
    const chartData = []

    Object.values(data).forEach((report) => {
        const date = new Date(report.createdAt)
        const formattedDate = `${date.getMonth() + 1}/${date.getFullYear()}`
        chartData.push({ x: formattedDate, y: parseFloat(report.value) })
    })

    const maxValuesByMonth = new Map()

    chartData.forEach((item) => {
        const { x: monthYear, y: value } = item
        if (!maxValuesByMonth.has(monthYear) || maxValuesByMonth.get(monthYear) < value) {
            maxValuesByMonth.set(monthYear, value)
        }
    })

    const labels = Array.from(maxValuesByMonth.keys())
    const values = Array.from(maxValuesByMonth.values())

    return { labels, values }
}

export default getChartData
