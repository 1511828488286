import { Checkbox, InlineAlert, Pane } from 'evergreen-ui'

const NotificationsType = ({
    isNotifyAlert,
    isEmail,
    isPush,
    isSMS,
    setIsEmail,
    setIsPush,
    setIsSMS,
    isDisabled
}) => {
    return (
        <>
            <div className="titlebox">
                <div className="title">Typ powiadomienia</div>
            </div>
            {isNotifyAlert ? (
                <InlineAlert intent="danger" className="alert_inline">
                    Musi być wybrany chociaż jeden typ powiadomnienia
                </InlineAlert>
            ) : (
                <div className="no_alert_div" />
            )}
            <div className="communications_settings">
                <Pane className="communications_settings_checkBox_pane">
                    <Checkbox
                        checked={isPush}
                        onChange={(e) => setIsPush(e.target.checked)}
                        label="Powiadomienie push"
                        disabled={isDisabled}
                    />
                    <Checkbox
                        checked={isEmail}
                        onChange={(e) => setIsEmail(e.target.checked)}
                        label="Email"
                        disabled={isDisabled}
                    />
                    <Checkbox
                        checked={isSMS}
                        onChange={(e) => setIsSMS(e.target.checked)}
                        label="SMS"
                        disabled={isDisabled}
                    />
                </Pane>
            </div>
        </>
    )
}
export default NotificationsType
