import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'

import { Table, Tooltip, Pill } from 'evergreen-ui'
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'

import NotificationExpandedRow from './NotificationExpandedRow'

import { renderUserPills } from '../../../utils/renderUsers'
import { renderChannel, renderIcons, renderRecipient } from '../../../utils/notificationLogs'
import dateConvert from '../../../utils/formatDate'

const NotificationDetailRow = ({
    log,
    users,
    navigate,
    isExpanded,
    setIsExpanded,
    renderNotificationPopup
}) => {
    return (
        <Table.Row className={`row ${isExpanded ? 'in_color' : ''}`}>
            <Table.TextCell>{log.createdBy !== null ? log.createdBy : 'System'}</Table.TextCell>
            <Table.TextCell flexBasis={350} flexGrow={0}>
                <div className="canal_icon_name">
                    <span>{renderIcons(log.channel, 16)}</span>
                    {renderChannel(log.channel, true)}
                </div>
            </Table.TextCell>
            <Table.TextCell>{renderRecipient('notification', log.recipient, true)}</Table.TextCell>
            <Table.TextCell>
                <div className="clients_notification">
                    {users.length > 1
                        ? renderUserPills(users, navigate)?.slice(0, 5)
                        : renderUserPills(users, navigate, true)?.slice(0, 5)}
                    {renderUserPills(users, navigate).length > 1 && renderNotificationPopup()}
                </div>
            </Table.TextCell>
            <Table.TextCell>{dateConvert(log.createdAt)}</Table.TextCell>
            <Table.TextCell>{log.title}</Table.TextCell>
            <Table.TextCell className="gap" />
            <Table.TextCell className="alone_row">
                <Tooltip content="Szczegóły" showDelay={500} key={!isExpanded ? 0 : 1}>
                    <button
                        type="button"
                        onClick={() => setIsExpanded((current) => !current)}
                        className="clickable"
                    >
                        {isExpanded ? (
                            <BiUpArrowAlt size="20" className="icon_white" />
                        ) : (
                            <BiDownArrowAlt size="20" />
                        )}
                    </button>
                </Tooltip>
            </Table.TextCell>
        </Table.Row>
    )
}

const NotificationRow = ({ log, setIsDetailsShown, setNotificationRowId }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [notificationUsers, setNotificationUsers] = useState(log.users)

    const documents = useSelector((state) => state.document.documents)
    const measurements = useSelector((state) => state.measurement.measurements)
    const meters = useSelector((state) => state.meter.meters)
    const users = useSelector((state) => state.user.users)

    const navigate = useNavigate()

    useEffect(() => {
        if (log.document) {
            const document = documents[log.document]
            const measurement = measurements[documents[document?.id]?.measurement[0]]
            const meter = meters[measurement?.meter]
            setNotificationUsers([users[meter?.tenantMember]?.pk])
        }
    }, [users, meters])

    const handleExpandChange = () => {
        setIsExpanded((current) => !current)
    }

    const handleShowDetails = () => {
        setIsDetailsShown(true)
        setNotificationRowId(log.users)
    }

    const renderNotificationPopup = () => {
        return (
            <Tooltip content="Lista odbiorców" showDelay={500}>
                <Pill className="box-pills__notification" isInteractive onClick={handleShowDetails}>
                    ...
                </Pill>
            </Tooltip>
        )
    }

    return (
        <>
            <NotificationDetailRow
                log={log}
                users={notificationUsers}
                navigate={navigate}
                isExpanded={isExpanded}
                setIsExpanded={handleExpandChange}
                renderNotificationPopup={renderNotificationPopup}
            />
            <NotificationExpandedRow
                log={log}
                users={notificationUsers}
                navigate={navigate}
                isExpanded={isExpanded}
            />
        </>
    )
}

export default NotificationRow
