import { AiOutlineMail, AiOutlineMobile, AiOutlineMessage } from 'react-icons/ai'
import { renderUserPills } from './renderUsers'
import renderMeasurementPills from './renderMeasurement'

const stripHTMLTags = (text) => {
    return text.replace(/(<([^>]+)>)/gi, '')
}

export const renderChannel = (channels, simple = false, useDash = false) => {
    if (channels.length === 0) {
        if (useDash) return '-'
        return null
    }
    let text = ''

    channels.forEach((channel) => {
        switch (channel) {
            case 'push':
                if (text.length > 0) text += ', '
                text += 'Powiadomienie mobilne'
                break
            case 'email':
                if (text.length > 0) text += ', '
                text += 'E-mail'
                break
            case 'sms':
                if (text.length > 0) text += ', '
                text += 'SMS'
                break
            default:
                text = '-'
                break
        }
    })

    if (simple) return text
    return [<p key="channel">Kanał:</p>, <p key="text">{text}</p>]
}

export const renderIcons = (channels, size) => {
    const icons = []

    channels.forEach((channel) => {
        switch (channel) {
            case 'push':
                icons.push(<AiOutlineMobile key={channel} size={size} />)
                break
            case 'email':
                icons.push(<AiOutlineMail key={channel} size={size} />)
                break
            case 'sms':
                icons.push(<AiOutlineMessage key={channel} size={size} />)
                break
            default:
                break
        }
    })

    return <span>{icons}</span>
}

export const renderRecipient = (type, recipient, simple = false) => {
    if (type !== 'notification') return null

    let result = ''

    switch (recipient) {
        case 'users':
            result = 'Wybrani odbiorcy'
            break
        case 'individual_clients':
            result = 'Wszyscy odbiorcy indywidualni'
            break
        case 'companies':
            result = 'Wszyscy odbiorcy insytucjonalni'
            break
        case 'all':
            result = 'Wszyscy odbiorcy'
            break
        default:
            result = 'Inne'
    }

    if (simple) return result
    return [<p key="recipient">Grupa odbiorców: </p>, <p key={result}>{result}</p>]
}

export const renderUsers = (users, navigate) => {
    const pills = renderUserPills(users, navigate, true)

    if (!pills) return null
    return [<p key="users">Odbiorcy:</p>, <p key="pills">{pills}</p>]
}

export const renderTitle = (title) => {
    if (title.length > 0) return [<p key="title">Tytuł:</p>, <p key={title}>{title}</p>]
    return null
}

export const renderBody = (body) => {
    if (body.length > 0) return [<p key="body">Treść:</p>, <p key={body}>{stripHTMLTags(body)}</p>]
    return null
}

export const renderData = (data) => {
    if (data.length > 0) return [<p key="data">Dane:</p>, <p key={data}>{JSON.stringify(data)}</p>]
    return null
}

export const renderMeasurement = (measurement, navigate) => {
    if (measurement !== null)
        return [
            <p key="measurement">Odczyt:</p>,
            <p key={measurement}>{renderMeasurementPills([measurement], navigate)}</p>
        ]
    return null
}

export const renderTenantMember = (user, navigate) => {
    if (user !== null)
        return [
            <p key="tenant_member">Odbiorca:</p>,
            <p key="pills">{renderUserPills([user], navigate, true)}</p>
        ]
    return null
}
