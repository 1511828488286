import { useSelector } from 'react-redux'

import { Table } from 'evergreen-ui'

import PaginationButtons from '../PaginationButtons'

import MeterUserRow from '../ListRows/MeterRows/MeterUserRow'
import NoResultsRow from '../ListRows/NoResultsRow'

const TableRows = ({ meters, onRowSelect, selectedRow, ref }) => {
    if (Object.values(meters).length === 0) {
        return <NoResultsRow />
    }
    return Object.entries(meters)
        .reverse()
        .map(([key, value]) => {
            return (
                <MeterUserRow
                    row={value}
                    key={key}
                    selectedRow={selectedRow}
                    onRowSelect={onRowSelect}
                    ref={ref}
                />
            )
        })
}

const TableMeters = ({
    onSelectedMeter,
    selectedMeter,
    pageCountMeter,
    onPageClick,
    meterPreviousPage,
    meterNextPage,
    metersResultsCount,
    meterRowLimit
}) => {
    const meters = useSelector((state) => state.meter.meters)

    const handleRowSelect = (pk) => {
        onSelectedMeter(pk)
    }

    return (
        <div className="container table_home_meter">
            <div className="container_main">
                <Table className="table">
                    <span className="table_title">Liczniki</span>
                    <Table.Head className="header">
                        <Table.TextHeaderCell className="table_title_col">
                            Nazwa licznika
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="table_title_col">
                            Typ licznika
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="table_title_col">
                            Numer licznika
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="table_title_col">
                            Data
                        </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body>
                        <TableRows
                            meters={meters}
                            selectedRow={selectedMeter}
                            onRowSelect={handleRowSelect}
                        />
                    </Table.Body>
                </Table>
            </div>
            <PaginationButtons
                pageCount={pageCountMeter}
                onPageClick={onPageClick}
                prevPage={meterPreviousPage}
                nextPage={meterNextPage}
                resultCount={metersResultsCount}
                apiLimit={meterRowLimit}
            />
        </div>
    )
}

export default TableMeters
