import { useRef, useState } from 'react'
import { Dialog, Pane, toaster } from 'evergreen-ui'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { AiOutlineUpload } from 'react-icons/ai'
import '../styles/components/button.scss'
import '../styles/components/dialog.scss'

export const AddPdfDialog = ({
    isShown,
    setIsShown,
    loadedFile,
    onSendClick,
    selectedMeasurementPk
}) => {
    const inputFile = useRef()
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [importedFile, setImportedFile] = useState(null)
    const [isFileLoaded, setIsFileLoaded] = useState(false)

    const handleDialogClose = () => {
        setImportedFile(null)
        setIsFileLoaded(false)
        setPageNumber(1)
        setNumPages(null)
        loadedFile(null)
    }

    const handleFileChange = (e) => {
        setImportedFile(null)
        setIsFileLoaded(false)
        setPageNumber(1)
        setNumPages(null)
        loadedFile(null)
        const tmpFile = e.target.files[0]
        if (tmpFile) {
            setImportedFile(tmpFile)
            loadedFile(tmpFile)
            setPageNumber(1)
        }
    }
    const handleOnSendClick = () => {
        if (importedFile?.name.split('.')[1] === 'pdf') {
            onSendClick()
            handleDialogClose()
        } else if (selectedMeasurementPk === null) {
            toaster.warning('Najpierw wybierz odczyt')
        } else if (importedFile === null) {
            toaster.warning('Najpierw wybierz plik')
        } else {
            toaster.warning('Ten format pliku jest niebsługiwany!')
        }
    }

    const customDialogFooter = () => {
        return (
            <div className="dialogCustomFooter">
                <button
                    type="button"
                    className="cancelButton"
                    onClick={() => inputFile.current.click()}
                >
                    Wybierz plik <AiOutlineUpload />
                </button>
                <button
                    type="button"
                    className="cancelButton"
                    onClick={() => handleDialogClose() || setIsShown(false)}
                >
                    Anuluj
                </button>
                <button type="button" className="button" onClick={handleOnSendClick}>
                    Wyślij
                </button>
            </div>
        )
    }

    const handlePageButtonClick = (e) => {
        if (numPages >= 2) {
            if (e.target.name === 'plus' && pageNumber + 1 <= numPages) {
                setPageNumber((current) => current + 1)
            } else if (e.target.name === 'minus' && pageNumber - 1 >= 1) {
                setPageNumber((current) => current - 1)
            }
        }
    }

    const onDocumentLoadSuccess = (PdfObj) => {
        setIsFileLoaded(true)
        setNumPages(PdfObj.numPages)
    }

    const renderPageCount = () => {
        if (isFileLoaded && numPages > 1) {
            return (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <p>
                        Strona {pageNumber} z {numPages}
                    </p>
                    <div>
                        <button
                            type="button"
                            className="dialogNextPageButton"
                            name="minus"
                            onClick={handlePageButtonClick}
                        >
                            Poprzednia
                        </button>
                        <button
                            type="button"
                            className="dialogNextPageButton"
                            name="plus"
                            onClick={handlePageButtonClick}
                        >
                            Następna
                        </button>
                    </div>
                </div>
            )
        }
        return null
    }

    return (
        <Dialog
            isShown={isShown}
            title="Dodaj fakturę do odczytu."
            onCloseComplete={() => handleDialogClose() || setIsShown(false)}
            confirmLabel="Wybierz plik"
            onConfirm={() => inputFile.current.click()}
            cancelLabel="Anuluj"
            width={700}
            contentContainerProps={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            topOffset={60}
            footer={customDialogFooter}
        >
            <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".pdf"
            />
            <Document
                file={importedFile}
                noData="Wczytaj plik PDF."
                error="Błąd wczytywania pliku PDF."
                loading="Wczytywanie PDF..."
                onLoadSuccess={onDocumentLoadSuccess}
                style={{
                    padding: 0
                }}
            >
                <Page
                    pageNumber={pageNumber}
                    loading="Wczytywanie następnej strony"
                    noData="Brak okreslonej strony"
                    error="Błąd wczytywania strony"
                />
            </Document>
            {renderPageCount()}
        </Dialog>
    )
}

export default AddPdfDialog
