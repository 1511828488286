import User from '../../redux/models/User'
import Tenant from '../../redux/models/Tenant'

export const fromGetUserTenantApi = (apiObj) => {
    const responseData = apiObj.data

    const usersData = Object.assign(
        {},
        ...responseData.results.map((user, index) => {
            return {
                [user.pk]: new User({ ...user, index })
            }
        })
    )

    return {
        users: usersData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }
}

export const toVerifyUserApi = (uiObj) => {
    return {
        state: uiObj.state,
        last_invoice_number: uiObj.userVerificationData.lastInvoiceNumber,
        client_number: uiObj.userVerificationData.clientNumber,
        city: uiObj.userVerificationData.city,
        street: uiObj.userVerificationData.street,
        zip_code: uiObj.userVerificationData.zipCode,
        name_surname: uiObj.userVerificationData.nameSurname,
        nip: uiObj.userVerificationData.nip,
        company_name: uiObj.userVerificationData.companyName
    }
}

export const fromVerifyUserApi = (apiObj) => {
    const { data } = apiObj
    return new User({
        pk: data.pk,
        user: data.user,
        email: data.user_email,
        tenant: data.tenant,
        state: data.state,
        lastInvoiceImage: data.last_invoice_image,
        clientNumber: data.client_number,
        lastInvoiceNumber: data.last_invoice_number,
        isCompany: data.is_company,
        nip: data.nip,
        companyName: data.company_name,
        nameSurname: data.name_surname,
        street: data.street,
        city: data.street,
        zipCode: data.zip_code,
        createdAt: data.created_at,
        updatedAt: data.updated_at
    })
}

export const fromUserHistoryApi = (apiObj) => {
    const { data } = apiObj
    return data.results.map((user) => {
        return {
            tenantMember: user.tenant_member,
            createdAt: user.created_at,
            createdBy: user.created_by,
            changes: user.changes
        }
    })
}

export const toRejectUserApi = (uiObj) => {
    return {
        state: uiObj.state,
        reason: uiObj.reason
    }
}

export const fromGetTenantApi = (apiObj) => {
    const responseData = apiObj.data
    const tenantsData = Object.assign(
        {},
        ...responseData.results.map((tenant) => {
            return {
                [tenant.uuid]: new Tenant({
                    uuid: tenant.uuid,
                    name: tenant.name
                })
            }
        })
    )

    const data = {
        tenants: tenantsData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }

    return data
}

export default fromGetUserTenantApi
