import { useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import getChartData from '../../utils/getChartData'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const HomeGraph = ({ selectedMeter }) => {
    const measurements = useSelector((state) => state.measurement.measurements)
    const meter = useSelector((state) => state.meter.meters)
    const { labels, values } = getChartData(measurements)

    const measurementDifferences = values.map((value, index, array) => {
        if (index === 0) {
            return 0
        }
        return value - array[index - 1]
    })

    const data = {
        labels: labels.slice(1),
        datasets: [
            {
                label: `Licznik: ${meter[selectedMeter]?.name}`,
                data: measurementDifferences.slice(1),
                backgroundColor: (() => {
                    switch (meter[selectedMeter]?.type) {
                        case 'cold_water':
                            return 'rgb(135,206,235)'
                        case 'hot_water':
                            return 'rgb(255,165,0)'
                        default:
                            return 'rgb(230,230,250)'
                    }
                })(),
                borderColor: (() => {
                    switch (meter[selectedMeter]?.type) {
                        case 'cold_water':
                            return 'rgb(0,0,255)'
                        case 'hot_water':
                            return 'rgb(255,69,0)'
                        default:
                            return 'rgb(230,230,250)'
                    }
                })(),
                borderWidth: 1
            }
        ]
    }

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label(context) {
                        return `Zużyto: ${context.parsed.y} m³`
                    }
                }
            }
        }
    }

    return (
        <div className="container table_home_meter">
            <div>
                <span className="table_title">Wykres zużycia</span>
                {values.length > 1 ? (
                    <Bar options={options} data={data} />
                ) : (
                    <span className="table_home_meter__bar">
                        Dla wygenerowania wykresu potrzebujemy co najmniej 2 pomiary z dwóch
                        miesięcy. Prosimy o zebranie danych z tego okresu
                    </span>
                )}
            </div>
        </div>
    )
}

export default HomeGraph
