import '../styles/components/card.scss'

const Card = ({ children, type = 'small', id = null }) => {
    return (
        <div className={`container_cards_${type}`} id={id}>
            {children}
        </div>
    )
}

export default Card
