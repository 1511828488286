import { Link } from 'react-router-dom'
import '../styles/views/regulations.scss'
import { IoMdArrowBack } from 'react-icons/io'

const PrivacyPolicy = () => {
    return (
        <div className="regulationsContainer">
            <div className="header">
                <Link to="/">
                    <div className="backButton">
                        <IoMdArrowBack />
                    </div>
                </Link>
                <h1>Regulamin aplikacji Bezlicznik</h1>
            </div>
            <ol>
                <li>
                    <b>Postanowienia ogólne</b>
                    <ol>
                        <li>
                            Niniejszy regulamin określa zasady korzystania z aplikacji Bezlicznik
                            (zwanej dalej Aplikacją), przeznaczonej do raportowania odczytów
                            liczników wody, otrzymywania rozliczeń oraz dokonywania płatności za
                            rozliczenia.
                        </li>
                        <li>
                            Aplikację Bezlicznik dostarcza i administruje NGCode (zwany dalej
                            Operator).
                        </li>
                        <li>
                            Korzystając z Aplikacji, Użytkownik akceptuje niniejszy regulamin oraz
                            zobowiązuje się do przestrzegania jego postanowień
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Rejestracja i korzystanie z Aplikacji</b>
                    <ol>
                        <li>
                            Aby korzystać z aplikacji Bezlicznik, Użytkownik musi zarejestrować
                            swoje konto, podając prawdziwe i aktualne dane osobowe.
                        </li>
                        <li>Użytkownik może posiadać tylko jedno konto w Aplikacji.</li>
                        <li>
                            Operator zastrzega sobie prawo do weryfikacji danych podanych podczas
                            rejestracji oraz do odmowy rejestracji w przypadku podania
                            nieprawdziwych lub niepełnych informacji
                        </li>
                        <li>
                            Użytkownik jest odpowiedzialny za zachowanie poufności swojego hasła do
                            konta w Aplikacji i za wszelkie działania podejmowane za jego
                            pośrednictwem.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Funkcje Aplikacji</b>
                    <ol>
                        <li>Raportowanie odczytów liczników wody dla poszczególnych dostawców.</li>
                        <li>Otrzymywanie rozliczeń na podstawie zgłoszonych odczytów</li>
                        <li>
                            Dokonywanie płatności za rozliczenia poprzez wbudowane funkcje
                            płatnicze.
                        </li>
                        <li>
                            Użytkownik jest odpowiedzialny za zachowanie poufności swojego hasła do
                            konta w Aplikacji i za wszelkie działania podejmowane za jego
                            pośrednictwem.
                        </li>
                        <li>
                            Przypisywanie dostawcy wody do swojego konta. Jeden klient może posiadać
                            wiele dostawców
                        </li>
                        <li>
                            Korzystanie z webowego panelu administratora, w którym sprawdzane są
                            odczyty, wystawiane są dokumenty i wysyłane powiadomienia.
                        </li>
                        <li>
                            Korzystanie z webowego panelu klienta, w którym Użytkownik może
                            sprawdzać swoje odczyty, wykresy i inne informacje dotyczące zużycia
                            wody.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Odpowiedzialność Użytkownika</b>
                    <ol>
                        <li>
                            Użytkownik ponosi pełną odpowiedzialność za wszystkie zgłoszenia
                            odczytów liczników wody oraz za podane przez siebie informacje dotyczące
                            zużycia wody.
                        </li>
                        <li>
                            Użytkownik zobowiązuje się do korzystania z Aplikacji zgodnie z
                            obowiązującymi przepisami prawa oraz do niepodejmowania działań, które
                            mogą naruszać prawa Operatora lub innych Użytkowników.
                        </li>
                        <li>
                            Użytkownik jest odpowiedzialny za zapewnienie prawidłowego
                            funkcjonowania swojego urządzenia mobilnego oraz dostępu do Internetu
                            niezbędnego do korzystania z Aplikacji.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Ochrona danych osobowych</b>
                    <ol>
                        <li>
                            Operator zobowiązuje się do ochrony danych osobowych Użytkowników
                            zgodnie z obowiązującymi przepisami prawa oraz z Polityką Prywatności
                            dostępną w Aplikacji.
                        </li>
                        <li>
                            Użytkownik wyraża zgodę na przetwarzanie swoich danych osobowych przez
                            Operatora w celach związanych z funkcjonowaniem Aplikacji.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Zmiany w regulaminie</b>
                    <ol>
                        <li>
                            Operator zastrzega sobie prawo do wprowadzania zmian w niniejszym
                            regulaminie.
                        </li>
                        <li>
                            Wprowadzone zmiany w regulaminie będą ogłaszane w Aplikacji i będą
                            obowiązywać od momentu ich opublikowania.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Zakończenie korzystania z Aplikacji</b>
                    <ol>
                        <li>
                            Użytkownik ma prawo w dowolnym momencie zakończyć korzystanie z
                            Aplikacji poprzez usunięcie swojego konta.
                        </li>
                        <li>
                            Operator zastrzega sobie prawo do zablokowania lub usunięcia konta
                            Użytkownika w przypadku naruszenia postanowień regulaminu lub
                            obowiązujących przepisów prawa.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Postanowienia końcowe</b>
                    <ol>
                        <li>Niniejszy regulamin podlega przepisom prawa polskiego.</li>
                        <li>
                            Wszelkie spory wynikające z korzystania z Aplikacji będą rozstrzygane
                            przez sąd właściwy dla siedziby Operatora.
                        </li>
                        <li>
                            W przypadku pytań lub wątpliwości dotyczących Aplikacji lub niniejszego
                            regulaminu, Użytkownik może skontaktować się z Obsługą Klienta
                            Operatora.
                        </li>
                    </ol>
                </li>
            </ol>

            <p>
                Data wejścia w życie regulaminu: <b>16.05.2023</b>
            </p>
            <p>
                Data ostatniej aktualizacji regulaminu: <b>16.05.2023</b>
            </p>
        </div>
    )
}

export default PrivacyPolicy
