import React from 'react'
import { Dialog, Pane, Table } from 'evergreen-ui'

import { renderFieldName, renderFieldValue } from '../utils/historyLogs'

const TabelRows = ({ changes }) => {
    const rows = {}

    changes.forEach(([key, value, type]) => {
        if (key === 'reason') {
            return
        }
        if (!(key in rows)) {
            rows[key] = {}
        }
        if (type === 'new_value') {
            if (value.length === 0) rows[key][type] = '-'
            else rows[key][type] = value
        } else if (type === 'old_value') {
            if (value.length === 0) rows[key][type] = '-'
            else rows[key][type] = value
        }
    })
    return Object.entries(rows).map((el) => {
        return <Rows key={el} items={el} />
    })
}

const Rows = ({ items }) => {
    return (
        <Table.Row>
            <Table.TextCell>{renderFieldName(items[0])}</Table.TextCell>
            <Table.TextCell>{renderFieldValue(items[1].new_value)}</Table.TextCell>
            <Table.TextCell>{renderFieldValue(items[1].old_value)}</Table.TextCell>
        </Table.Row>
    )
}

const Information = ({ items }) => {
    let reason = []
    reason = items.find((el) => el.length === 2)
    if (reason) {
        return (
            reason[0] === 'reason' && (
                <div className="history-reason">
                    <span>{`Powód odrzucenia: ${reason[1]}`}</span>
                </div>
            )
        )
    }
    return ''
}

const HistoryDetailDialog = ({ isShown, setIsShown, logs }) => {
    return (
        <Pane>
            <Dialog
                isShown={isShown}
                title={<span className="bottom__title">Historia zmian</span>}
                onCloseComplete={() => setIsShown(false)}
                hasFooter={false}
            >
                <Table>
                    <Table.Head>
                        <Table.TextHeaderCell>Pole</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Nowa wartość</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Stara wartość</Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body>
                        <TabelRows changes={logs?.changes} />
                    </Table.Body>
                </Table>
                <Information items={logs?.changes} />
            </Dialog>
        </Pane>
    )
}

export default HistoryDetailDialog
