import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { Table, toaster, Checkbox } from 'evergreen-ui'

import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import UserRow from '../../components/ListRows/UserRows/UserRow'
import LoadingRows from '../../components/ListRows/LoadingRows'
import PaginationButtons from '../../components/PaginationButtons'
import ActiveFilters from '../../components/activeFilters'
import AddPdfDialog from '../../components/PdfDialog'
import Footer from '../../components/Footer'
import UserDataVerificationDialog from '../../components/UserDataVerificationDialog'
import UserHistoryDialog from '../../components/UserHistoryDialog'
import PaginationInput from '../../components/PaginationInput'
import RowLimit from '../../components/RowLimit'
import VerifyUserAccountDialog from '../../components/VerifyUserAccountDialog'
import DeclineUserAccountDialog from '../../components/DeclineUserAccountDialog'
import NoResultsRow from '../../components/ListRows/NoResultsRow'

import useApi from '../../hooks/useApi'

import { addUsers, verifyUser } from '../../redux/slices/userSlice'
import Measurement from '../../redux/models/Measurement'
import User from '../../redux/models/User'

import {
    fromGetUserTenantApi,
    fromUserHistoryApi,
    fromVerifyUserApi,
    toRejectUserApi,
    toVerifyUserApi
} from '../../utils/Adapters/UserApiAdapter'
import { toMeasurementInvoiceApi } from '../../utils/Adapters/MeasurementApiAdapter'
import { userRejectOptions } from '../../static/rejectOptions'

const TableRows = ({
    fetchStatus,
    users,
    setIsInvoicePopup,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate,
    setIsDeclineUserPopup,
    setIsUserDataVerifieShown,
    setSelectedUser,
    setIsUserHistoryShown
}) => {
    if (!fetchStatus) {
        return <LoadingRows elements={users} />
    }
    if (Object.values(users).length === 0) {
        return <NoResultsRow />
    }

    return Object.entries(users)
        .sort((a, b) => a[1].index - b[1].index)
        .map(([pk, value]) => {
            return (
                <UserRow
                    user={value}
                    key={pk}
                    sendInvoiceCall={setIsInvoicePopup}
                    onRowSelected={onRowSelected}
                    isSelectedEveryRow={isSelectedEveryRow}
                    isSelectedEveryRowIndeterminate={isSelectedEveryRowIndeterminate}
                    setIsVerifyUserPopup={setIsUserDataVerifieShown}
                    setIsDeclineUserPopup={setIsDeclineUserPopup}
                    setSelectedUser={setSelectedUser}
                    setIsUserHistoryPopup={setIsUserHistoryShown}
                />
            )
        })
}

const UsersModerator = () => {
    const dispatch = useDispatch()

    const [urlParams, setUrlParams] = useSearchParams()

    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const users = useSelector((state) => state.user.users)
    const usersNextPage = useSelector((state) => state.user.nextPage)
    const usersPreviousPage = useSelector((state) => state.user.previousPage)
    const usersResultsCount = useSelector((state) => state.user.count)
    const rowLimit = useSelector((state) => state.profile.rowLimit)
    const isMobile = useSelector((state) => state.profile.isMobile)

    const [isSendInvoiceShown, setIsSendInvoiceShown] = useState(false)
    const [isVerifyUserShown, setIsVerifyUserShown] = useState(false)
    const [isUserHistoryShown, setIsUserHistoryShown] = useState(false)
    const [isDeclineUserShown, setIsDeclineUserShown] = useState(false)
    const [isUserDataVerifiedShown, setIsUserDataVerifiedShown] = useState(false)
    const [selectedMeasurement, setSelecteMeasurement] = useState(new Measurement())
    const [userVerificationData, setUserVerificationData] = useState(new User())
    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [loadedPdfFile, setLoadedPdfFile] = useState({})

    // mass actions
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedUser, setSelectedUser] = useState(new User())
    const [userLogs, setUserLogs] = useState([])
    const [isSelectedEveryRow, setIsSelectedEveryRow] = useState(false)
    const [isSelectedEveryRowIndeterminate, setIsSelectedEveryRowIndeterminate] = useState(false)
    const [rejectTitle, setRejectTitle] = useState('')
    const [rejectContent, setRejectContent] = useState('')

    // url params
    const [userParam, setUserParam] = useState(null)
    const [stateParam, setStateParam] = useState(null)
    const [userTypeParam, setUserTypeParam] = useState(null)
    const [createdAtStartParam, setCreatedAtStartParam] = useState(null)
    const [createdAtEndParam, setCreatedAtEndParam] = useState(null)
    const [updatedAtStartParam, setUpdatedAtStartParam] = useState(null)
    const [updatedAtEndParam, setUpdatedAtEndParam] = useState(null)
    const [searchParam, setSearchParam] = useState(null)
    const [sortingParam, setSortingParam] = useState(null)

    const buildURL = () => {
        let baseURL = `/app/tenants/moderator/tenant_member/?limit=${rowLimit}&offset=${offset}`

        // filters
        if (userParam !== null) baseURL += `&id__in=${userParam}`
        if (searchParam !== null) baseURL += `&search=${searchParam}`
        if (stateParam !== null) baseURL += `&state=${stateParam}`
        if (userTypeParam !== null) baseURL += `&is_company=${userTypeParam}`
        if (createdAtStartParam !== null && createdAtEndParam !== null) {
            baseURL += `&created_at_after=${createdAtStartParam}`
            baseURL += `&created_at_before=${createdAtEndParam}`
        }
        if (updatedAtStartParam !== null && updatedAtEndParam !== null) {
            baseURL += `&updated_at_after=${updatedAtStartParam}`
            baseURL += `&updated_at_before=${updatedAtEndParam}`
        }
        // sorting
        if (sortingParam !== null) baseURL += `&ordering=${sortingParam}`

        return baseURL
    }

    const fetchUserTenantList = useApi({
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: [
            'users',
            offset,
            rowLimit,
            userParam,
            searchParam,
            sortingParam,
            stateParam,
            userTypeParam,
            createdAtStartParam,
            createdAtEndParam,
            updatedAtStartParam,
            updatedAtEndParam,
            selectedUser
        ],
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const fetchUserTenantLogs = useApi({
        url: `/app/tenants/moderator/tenant_member/${selectedUser.pk}/logs/`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromUserHistoryApi,
        enabled: isUserHistoryShown,
        queryName: ['users', selectedUser],
        onSuccess: (data) => setUserLogs(data)
    })

    const sendInvoiceCall = useApi({
        method: 'POST',
        apiKey,
        isFile: true,
        url: '/app/meters/moderator/invoice/',
        toApiAdapter: toMeasurementInvoiceApi
    })

    const verifyUserState = useApi({
        method: 'PUT',
        apiKey,
        toApiAdapter: toVerifyUserApi
    })

    const rejectUserState = useApi({
        method: 'PUT',
        apiKey,
        toApiAdapter: toRejectUserApi
    })

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCount((old) => old + 1)
            else setPageCount((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1) setOffset(page.substring(offsetPosition + 7, page.length))
            else setOffset(0)
        }
    }

    const handleOnSendRejectionClick = () => {
        if (rejectContent.length > 0) {
            rejectUserState
                .mutateAsync({
                    data: { state: 'rejected', reason: rejectContent },
                    url: `/app/tenants/moderator/tenant_member/${selectedUser.pk}/`
                })
                .then((response) => {
                    setIsUserDataVerifiedShown(false)
                    dispatch(verifyUser(fromVerifyUserApi(response)))
                    toaster.success('Odbiorca został odrzucony')
                })
        } else {
            toaster.warning('Wybierz powód odrzucenia zgłoszenia')
        }
    }

    const handleAccountVerifyClick = () => {
        verifyUserState
            .mutateAsync({
                data: { state: 'verified', userVerificationData },
                url: `/app/tenants/moderator/tenant_member/${selectedUser.pk}/`
            })
            .then((response) => {
                toaster.success('Odbiorca został zatwierdzony')
                setIsUserDataVerifiedShown(false)
                dispatch(verifyUser(fromVerifyUserApi(response)))
            })
    }

    const handleOnSendClick = () => {
        if (selectedMeasurement.id !== null) {
            sendInvoiceCall
                .mutateAsync({
                    data: {
                        file: loadedPdfFile,
                        measurement: null
                    }
                })
                .then(() => {
                    toaster.success('Pomyślnie dodano fakturę')
                })
        } else {
            toaster.warning('Najpierw wybierz odczyt')
        }
    }

    const handleRowSelectChanged = (pk) => {
        if (selectedRows.indexOf(pk) !== -1)
            setSelectedRows((current) => current.filter((elem) => elem !== pk))
        else setSelectedRows((current) => [...current, pk])
    }

    const handeSelectEveryRowClick = () => {
        if (isSelectedEveryRowIndeterminate) {
            setSelectedRows([])
            setIsSelectedEveryRowIndeterminate(false)
            return
        }

        if (isSelectedEveryRow) setSelectedRows([])
        else {
            const ids = Object.values(users).map((user) => user.id)
            setSelectedRows(ids)
        }
        setIsSelectedEveryRow((current) => !current)
    }

    const handleMassActionClick = (actionObject) => {
        if (selectedRows.length === 0) {
            toaster.notify('Nie wybrano żadnego wiersza')
            return
        }

        // switch użyty przyszłościowo, w przypadku więcej opcji
        switch (actionObject.value) {
            case 'invoice':
                toaster.notify(`Wystawianie faktury dla wierszy: ${selectedRows.toString()}`)
                break
            default:
                break
        }
    }

    const handleTopicChanged = (e) => {
        userRejectOptions.forEach((topic) => {
            if (topic.title === e) {
                setRejectTitle(topic.title)
                setRejectContent(topic.content)
            }
        })
    }

    const handlePaginationInputonBlur = (e) => {
        setOffset(rowLimit * e - rowLimit)
        setPageCount(e)
    }

    // logika zaznaczania wierszy
    useEffect(() => {
        if (Object.values(selectedRows).length === 0) {
            setIsSelectedEveryRow(false)
            setIsSelectedEveryRowIndeterminate(false)
        } else if (selectedRows.length === Object.values(users).length) {
            setIsSelectedEveryRow(true)
            setIsSelectedEveryRowIndeterminate(false)
        } else {
            setIsSelectedEveryRow(false)
            setIsSelectedEveryRowIndeterminate(true)
        }
    }, [selectedRows])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
    }, [rowLimit])

    useEffect(() => {
        if (!isUserDataVerifiedShown) setSelectedUser(new User())
    }, [isUserDataVerifiedShown])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }

        if (!Number.isNaN(userParam)) setUserParam(null)
        setSearchParam(null)
        setSortingParam(null)
        setStateParam(null)
        setUserTypeParam(null)
        setCreatedAtEndParam(null)
        setCreatedAtStartParam(null)
        setUpdatedAtEndParam(null)
        setUpdatedAtStartParam(null)

        // filters
        const userParamGET = parseInt(urlParams.get('user_id'), 10)
        const searchParamGET = urlParams.get('search') || null
        const stateParamGET = urlParams.get('state') || null
        const userTypeParamGET = urlParams.get('is_company') || null
        const createdAtParamGET = urlParams.get('created_at') || null
        const updatedAtParamGET = urlParams.get('updated_at') || null
        // sorting
        const sortingParamGET = urlParams.get('sorting') || null

        // filters
        if (!Number.isNaN(userParamGET)) setUserParam(userParamGET)
        if (searchParamGET !== null) setSearchParam(searchParamGET)
        if (stateParamGET !== null) setStateParam(stateParamGET)
        if (userTypeParamGET !== null) {
            if (userTypeParamGET.includes('true')) setUserTypeParam('true')
            else setUserTypeParam('false')
        }

        if (createdAtParamGET !== null) {
            const endDate = new Date()
            let startDate = null
            setCreatedAtEndParam(endDate.toISOString().split('T')[0])

            // 1000 * 60 * 60 * 24 - stands for one day in ms
            switch (createdAtParamGET) {
                case 'last_24h': {
                    startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_week': {
                    startDate = new Date(endDate.getTime() - 7 * 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_month': {
                    startDate = new Date(endDate.getTime() - 30 * 1000 * 60 * 60 * 24)
                    break
                }
                default:
                    break
            }

            setCreatedAtStartParam(startDate.toISOString().split('T')[0])
        }
        if (updatedAtParamGET !== null) {
            const endDate = new Date()
            let startDate = null

            setUpdatedAtEndParam(endDate.toISOString().split('T')[0])

            // 1000 * 60 * 60 * 24 - stands for one day in ms
            switch (updatedAtParamGET) {
                case 'last_24h': {
                    startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_week': {
                    startDate = new Date(endDate.getTime() - 7 * 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_month': {
                    startDate = new Date(endDate.getTime() - 30 * 1000 * 60 * 60 * 24)
                    break
                }
                default:
                    break
            }

            setUpdatedAtStartParam(startDate.toISOString().split('T')[0])
        }
        // sorting
        if (sortingParamGET !== null) {
            switch (sortingParamGET) {
                case 'created_at_asc':
                    setSortingParam('created_at')
                    break
                case 'created_at_desc':
                    setSortingParam('-created_at')
                    break
                case 'updated_at_asc':
                    setSortingParam('updated_at')
                    break
                case 'updated_at_desc':
                    setSortingParam('-updated_at')
                    break
                default:
                    break
            }
        }
    }, [urlParams])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="users" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Odbiorcy</div>
                    <ActiveFilters onMassActionSelect={handleMassActionClick} userFilters />
                </div>

                <div className="container">
                    <div className="container_main">
                        <div className="container_cards">
                            <Table className="table">
                                <Table.Head className="header">
                                    {!isMobile && (
                                        <Table.TextHeaderCell
                                            flexBasis="40px"
                                            flexShrink={0}
                                            flexGrow={0}
                                        >
                                            <Checkbox
                                                checked={isSelectedEveryRow}
                                                indeterminate={isSelectedEveryRowIndeterminate}
                                                onChange={handeSelectEveryRowClick}
                                            />
                                        </Table.TextHeaderCell>
                                    )}
                                    <Table.TextHeaderCell className="table_title_col">
                                        Nazwa odbiorcy
                                    </Table.TextHeaderCell>
                                    {!isMobile && (
                                        <>
                                            <Table.TextHeaderCell className="table_title_col">
                                                ID odbiorcy
                                            </Table.TextHeaderCell>
                                            <Table.TextHeaderCell className="table_title_col">
                                                Typ odbiorcy
                                            </Table.TextHeaderCell>
                                            <Table.TextHeaderCell className="table_title_col">
                                                Status odbiorcy
                                            </Table.TextHeaderCell>
                                            <Table.TextHeaderCell
                                                className="table_title_col"
                                                flexBasis="270px"
                                                flexShrink={0}
                                                flexGrow={0}
                                            >
                                                Działania
                                            </Table.TextHeaderCell>
                                        </>
                                    )}
                                    {isMobile && (
                                        <>
                                            <Table.TextHeaderCell className="table_title_col">
                                                Status odbiorcy
                                            </Table.TextHeaderCell>
                                            <Table.TextHeaderCell
                                                flexBasis="50px"
                                                flexShrink={0}
                                                flexGrow={0}
                                            />
                                        </>
                                    )}
                                </Table.Head>
                                <Table.Body>
                                    <TableRows
                                        fetchStatus={fetchUserTenantList.isSuccess}
                                        users={users}
                                        setIsInvoicePopup={setIsSendInvoiceShown}
                                        onRowSelected={handleRowSelectChanged}
                                        isSelectedEveryRow={isSelectedEveryRow}
                                        isSelectedEveryRowIndeterminate={
                                            isSelectedEveryRowIndeterminate
                                        }
                                        setIsDeclineUserPopup={setIsDeclineUserShown}
                                        setIsUserDataVerifieShown={setIsUserDataVerifiedShown}
                                        setSelectedUser={setSelectedUser}
                                        setIsUserHistoryShown={setIsUserHistoryShown}
                                    />
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="pagination_box">
                            <PaginationInput
                                pageCount={pageCount}
                                onBlur={handlePaginationInputonBlur}
                                resultCount={usersResultsCount}
                                apiLimit={rowLimit}
                            />
                            <RowLimit />
                        </div>
                        <PaginationButtons
                            pageCount={pageCount}
                            onPageClick={onPageClick}
                            prevPage={usersPreviousPage}
                            nextPage={usersNextPage}
                            resultCount={usersResultsCount}
                            apiLimit={rowLimit}
                        />
                    </div>
                </div>
            </div>
            <AddPdfDialog
                isShown={isSendInvoiceShown}
                setIsShown={setIsSendInvoiceShown}
                loadedFile={setLoadedPdfFile}
                onSendClick={handleOnSendClick}
                selectedMeasurementPk={null}
            />

            <UserDataVerificationDialog
                isShown={isUserDataVerifiedShown}
                setIsShown={setIsUserDataVerifiedShown}
                setIsVerifyUserPopup={setIsVerifyUserShown}
                setIsDeclineUserPopup={setIsDeclineUserShown}
                selectedUser={selectedUser}
                setSendingData={setUserVerificationData}
            />

            <VerifyUserAccountDialog
                isShown={isVerifyUserShown}
                setIsShown={setIsVerifyUserShown}
                onSendClick={handleAccountVerifyClick}
            />

            <UserHistoryDialog
                isShown={isUserHistoryShown}
                setIsShown={setIsUserHistoryShown}
                userLogs={userLogs}
            />

            <DeclineUserAccountDialog
                isShown={isDeclineUserShown}
                topics={userRejectOptions}
                setIsShown={setIsDeclineUserShown}
                setRejectContent={setRejectContent}
                handleTopicChanged={handleTopicChanged}
                rejectContent={rejectContent}
                onSendClick={handleOnSendRejectionClick}
            />
            <Footer />
        </div>
    )
}
export default UsersModerator
