import Meter from '../../redux/models/Meter'

export const fromMeterApi = (apiObj) => {
    const responseData = apiObj.data

    const parsedData = Object.assign(
        {},
        ...responseData.results.map((meter, index) => {
            return {
                [meter.pk]: new Meter({ ...meter, index })
            }
        })
    )

    return {
        meters: parsedData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }
}

export const fromOneMeterApi = (apiObj) => {
    const responseData = apiObj.data
    return { [responseData.pk]: new Meter(responseData) }
}

export const toMeterApi = (newMeter) => {
    return {
        tenant_member: newMeter.tenantMember,
        name: newMeter.name,
        type: newMeter.type,
        serial_number: newMeter.serialNumber,
        image: newMeter.image,
        street: newMeter.street,
        city: newMeter.city,
        zip_code: newMeter.zipCode
    }
}
