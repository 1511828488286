import { combineReducers } from '@reduxjs/toolkit'
import UserSlice from './userSlice'
import meterSlice from './meterSlice'
import profileSlice from './profileSlice'
import measurementSlice from './measurementSlice'
import DocumentSlice from './documentSlice'
import StatisticSlice from './statisticSlice'
import notificationLogSlice from './notificationLogSlice'
import historyLogSlice from './historyLogSlice'
import tenantSlice from './tenantSlice'
import paymentSlices from './paymentSlices'
import applicationSlice from './applicationSlice'

const rootSlice = combineReducers({
    profile: profileSlice,
    measurement: measurementSlice,
    user: UserSlice,
    meter: meterSlice,
    document: DocumentSlice,
    statistic: StatisticSlice,
    notificationLog: notificationLogSlice,
    historyLog: historyLogSlice,
    tenant: tenantSlice,
    payment: paymentSlices,
    application: applicationSlice
})

export default rootSlice
