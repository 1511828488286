import { useRef } from 'react'
import { Link } from 'react-router-dom'

import { IoIosLogOut } from 'react-icons/io'
import { IoSettingsOutline } from 'react-icons/io5'
import { BsPerson } from 'react-icons/bs'
import { FiBell } from 'react-icons/fi'

import { Popover, Menu, Position } from 'evergreen-ui'

import eventEmitter from '../../utils/eventEmitter'

const PopupMenu = ({ children }) => {
    const ref = useRef()

    const handleLogoutButtonClick = () => {
        eventEmitter.emit('showLogout')
        ref.current.close()
    }

    return (
        <Popover
            position={Position.BOTTOM_RIGHT}
            ref={ref}
            content={
                <Menu>
                    <Menu.Group>
                        <Link to="/profile">
                            <Menu.Item icon={<BsPerson size="20" />}>Moje konto</Menu.Item>
                        </Link>
                    </Menu.Group>
                    <Menu.Group>
                        <Link to="/notifications">
                            <Menu.Item icon={<FiBell size="20" />}>Powiadomienia</Menu.Item>
                        </Link>
                    </Menu.Group>
                    <Menu.Divider />
                    <Menu.Group>
                        <Menu.Item
                            icon={<IoIosLogOut size="20" />}
                            intent="danger"
                            onClick={handleLogoutButtonClick}
                        >
                            Wyloguj
                        </Menu.Item>
                    </Menu.Group>
                </Menu>
            }
        >
            {children}
        </Popover>
    )
}

export default PopupMenu
