import { FiX } from 'react-icons/fi'
import { TiTickOutline, TiTick } from 'react-icons/ti'

export const getState = (state) => {
    switch (state) {
        case 'new':
            return 'Nowy'
        case 'verified':
            return 'Zatwierdzony'
        case 'rejected':
            return 'Odrzucony'
        case 'non_billed':
            return 'Nierozliczeniowy'
        default:
            return 'Inny'
    }
}

export const renderState = (state, size) => {
    switch (state) {
        case 'new':
            return (
                <span>
                    <TiTickOutline className="icon_blue" size={size} />
                    {getState(state)}
                </span>
            )
        case 'verified':
            return (
                <span>
                    <TiTick className="icon_green" size={size} />
                    {getState(state)}
                </span>
            )
        case 'rejected':
            return (
                <span>
                    <FiX className="icon_red" size={size} />
                    {getState(state)}
                </span>
            )
        case 'non_billed':
            return (
                <span>
                    <FiX className="icon_blue" size={size} />
                    {getState(state)}
                </span>
            )
        default:
            return 'Inny'
    }
}

export default getState
