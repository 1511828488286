import Document from '../../redux/models/Document'

export const fromDocumentApi = (apiObj) => {
    const responseData = apiObj.data

    const parsedData = Object.assign(
        {},
        ...responseData.results.map((document, index) => {
            return {
                [document.pk]: new Document({
                    ...document,
                    index
                })
            }
        })
    )

    return {
        documents: parsedData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }
}

export default fromDocumentApi
