import { BsQuestion } from 'react-icons/bs'
import { FaFaucet, FaThermometerEmpty, FaThermometerFull, FaFire } from 'react-icons/fa'
import { GiHeatHaze, GiElectric } from 'react-icons/gi'

const renderMeterIconType = (row, size) => {
    switch (row?.type) {
        case 'cold_water':
            return (
                <span>
                    <FaThermometerEmpty className="icon_blue" size={size} />
                    Woda zimna
                </span>
            )
        case 'hot_water':
            return (
                <span>
                    <FaThermometerFull className="icon_red" size={size} />
                    Woda ciepła
                </span>
            )
        case 'gas':
            return (
                <span>
                    <FaFire className="icon_blue" size={size} /> Gaz
                </span>
            )
        case 'heat':
            return (
                <span>
                    <GiHeatHaze className="icon_red" size={size} /> Ciepło
                </span>
            )
        case 'electricity':
            return (
                <span>
                    <GiElectric className="icon_yellow" size={size} /> Prąd
                </span>
            )
        default:
            return (
                <span>
                    <BsQuestion size={size} />
                    Niezdefiniowany
                </span>
            )
    }
}
export default renderMeterIconType
