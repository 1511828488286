import { Link } from 'react-router-dom'

import { Position, Tooltip } from 'evergreen-ui'
import { GoPrimitiveDot } from 'react-icons/go'
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs'

import Card from '../Card'
import ProgressBar from '../ProgressBar'

import '../../styles/views/home.scss'
import '../../styles/components/table.scss'

import {
    getCompanyUserChartValues,
    getIndividualUserChartValues,
    getAllUserChartValues,
    getCountAllUsers,
    getCountIndividualUsers,
    getCountCompanyUsers
} from '../../utils/ChartValues'

const UserCards = ({ statistics }) => {
    return (
        <div className="user_card_box">
            <Card type="small">
                <div className="card_title">
                    <Tooltip
                        content="Przejdź do wszystkich odbiorców instytucjonalnych"
                        showDelay={500}
                    >
                        <Link to="/users?is_company=is_company_true">
                            <span>Odbiorcy instytucjonalni</span>
                        </Link>
                    </Tooltip>
                    <Tooltip
                        content="Przejdź do wszystkich odbiorców instytucjonalnych"
                        showDelay={500}
                        position={Position.RIGHT}
                    >
                        <Link to="/users?is_company=is_company_true">
                            <BsFillArrowUpRightCircleFill size="17" className="arrow" />
                        </Link>
                    </Tooltip>
                </div>
                <div className="card_content">
                    <ProgressBar data={getCompanyUserChartValues(statistics)} />
                    <p>
                        <span className="color_blue dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do nowych odbiorców instytucjonalnych"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users?state=new&is_company=is_company_true">
                                Nowi odbiorcy instytucjonalni: {statistics.newCompanyTenantMembers}
                            </Link>
                        </Tooltip>
                    </p>
                    <p>
                        <span className="color_red dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do odrzuconych odbiorców indywidualnych"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users?state=rejected&is_company=is_company_true">
                                Odrzuceni odbiorcy instytucjonalni:&nbsp;
                                {statistics.rejectedCompanyTenantMembers}
                            </Link>
                        </Tooltip>
                    </p>
                    <p>
                        <span className="color_green dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do zweryfikowanych odbiorców instytucjonalnych"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users?state=verified&is_company=true">
                                Zweryfikowani odbiorcy instytucjonalni:&nbsp;
                                {statistics.verifiedCompanyTenantMembers}
                            </Link>
                        </Tooltip>
                    </p>
                </div>
            </Card>
            <Card type="small">
                <div className="card_title">
                    <Tooltip
                        content="Przejdź do wszystkich odbiorców indywidualnych"
                        showDelay={500}
                    >
                        <Link to="/users?is_company=is_company_false">
                            <span>Odbiorcy indywidualni</span>
                        </Link>
                    </Tooltip>
                    <Tooltip
                        content="Przejdź do wszystkich odbiorców indywidualnych"
                        showDelay={500}
                        position={Position.RIGHT}
                    >
                        <Link to="/users?is_company=is_company_false">
                            <BsFillArrowUpRightCircleFill size="17" className="arrow" />
                        </Link>
                    </Tooltip>
                </div>
                <div className="card_content">
                    <ProgressBar
                        data={getIndividualUserChartValues(statistics)}
                        bgcolor="#21b49b"
                    />
                    <p>
                        <span className="color_blue dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do nowych odbiorców indywidualnych"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users?state=new&is_company=is_company_false">
                                Nowi odbiorcy indywidualni: {statistics.newIndividualTenantMembers}
                            </Link>
                        </Tooltip>
                    </p>
                    <p>
                        <span className="color_red dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do odrzuconych odbiorców indywidualnych"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users?state=rejected&is_company=is_company_false">
                                Odrzuceni odbiorcy indywidualni:{' '}
                                {statistics.rejectedIndividualTenantMembers}
                            </Link>
                        </Tooltip>
                    </p>
                    <p>
                        <span className="color_green dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do zweryfikowanych odbiorców indywidualnych"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users?state=verified&is_company=is_company_false">
                                Zweryfikowani odbiorcy indywidualni:{' '}
                                {statistics.verifiedIndividualTenantMembers}
                            </Link>
                        </Tooltip>
                    </p>
                </div>
            </Card>
            <Card type="small">
                <div className="card_title">
                    <Tooltip content="Przejdź do wszystkich odbiorców" showDelay={500}>
                        <Link to="/users">
                            <span>Odbiorcy łącznie</span>
                        </Link>
                    </Tooltip>
                    <Tooltip
                        content="Przejdź do wszystkich odbiorców"
                        showDelay={500}
                        position={Position.RIGHT}
                    >
                        <Link to="/users">
                            <BsFillArrowUpRightCircleFill size="17" className="arrow" />
                        </Link>
                    </Tooltip>
                </div>
                <div className="card_content">
                    <ProgressBar data={getAllUserChartValues(statistics)} />
                    <p>
                        <span className="color_green dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do odbiorców instytucjonalnych"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users?is_company=is_company_true">
                                Odbiorcy instytucjonalni:&nbsp;
                                {getCountCompanyUsers(statistics)}
                            </Link>
                        </Tooltip>
                    </p>
                    <p>
                        <span className="color_red dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do odbiorców indywidualnych"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users?is_company=is_company_false">
                                Odbiorcy indywidualni:&nbsp;
                                {getCountIndividualUsers(statistics)}
                            </Link>
                        </Tooltip>
                    </p>
                    <p>
                        <span className="dot">
                            <GoPrimitiveDot />
                        </span>
                        <Tooltip
                            content="Przejdź do wszystkich odbiorców"
                            showDelay={500}
                            position={Position.RIGHT}
                        >
                            <Link to="/users">
                                Odbiorcy łącznie:&nbsp;
                                {getCountAllUsers(statistics)}
                            </Link>
                        </Tooltip>
                    </p>
                </div>
            </Card>
        </div>
    )
}

export default UserCards
