/* eslint-disable camelcase */
class User {
    constructor(data = {}) {
        const {
            pk,
            user,
            user_email,
            tenant,
            state,
            last_invoice_image,
            client_number,
            last_invoice_number,
            is_company,
            nip,
            company_name,
            name_surname,
            street,
            city,
            zip_code,
            created_at,
            updated_at,
            index
        } = data

        this.pk = pk || null
        this.user = user || null
        this.email = user_email || ''
        this.tenant = tenant || null
        this.state = state || ''
        this.lastInvoiceImage = last_invoice_image || ''
        this.clientNumber = client_number || ''
        this.lastInvoiceNumber = last_invoice_number || ''
        this.isCompany = is_company || false
        this.nip = nip || ''
        this.companyName = company_name || ''
        this.nameSurname = name_surname || ''
        this.street = street || ''
        this.city = city || ''
        this.zipCode = zip_code || ''
        this.createdAt = created_at || ''
        this.updatedAt = updated_at || ''
        this.index = index
    }
}

export default User
