import { createSlice } from '@reduxjs/toolkit'

export const measurementsSlice = createSlice({
    name: 'measurements',
    initialState: {
        measurements: {},
        count: 0,
        nextPage: null,
        previousPage: null
    },

    reducers: {
        addMeasurements: (state, action) => {
            state.measurements = action.payload.measurements
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        },
        verifyMeasurement: (state, action) => {
            state.measurements[action.payload.id] = action.payload
        },
        bulkVerifyMeasurement: (state, action) => {
            action.payload.ids.forEach((id) => {
                state.measurements[id].isChecked = action.payload.isChecked
            })
        }
    }
})

export const {
    addMeasurementsToVerified,
    addMeasurements,
    verifyMeasurement,
    bulkVerifyMeasurement
} = measurementsSlice.actions
export default measurementsSlice.reducer
