import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import Footer from '../../components/Footer'

import useApi from '../../hooks/useApi'

import { setUserData } from '../../redux/slices/profileSlice'
import { addMeasurements } from '../../redux/slices/measurementSlice'
import { addMeters } from '../../redux/slices/meterSlice'

import { fromGetUserDataApi } from '../../utils/Adapters/ProfileApiAdapter'
import { fromMeasurementApi } from '../../utils/Adapters/MeasurementApiAdapter'
import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'

import TableMeters from '../../components/homeUsersCards/TableMeters'
import TableMeasurements from '../../components/homeUsersCards/TableMeasurements'
import HomeGraph from '../../components/homeUsersCards/HomeGraph'

import '../../styles/views/homeUser.scss'

const HomeUser = () => {
    const dispatch = useDispatch()
    const rowLimitMeasurement = 12
    const rowLimitMeter = 5

    const profile = useSelector((state) => state.profile.profile)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const userID = useSelector((state) => state.profile.profile.id)
    const meterNextPage = useSelector((state) => state.meter.nextPage)
    const meterPreviousPage = useSelector((state) => state.meter.previousPage)
    const meterResultsCount = useSelector((state) => state.meter.count)
    const measurementNextPage = useSelector((state) => state.measurement.nextPage)
    const measurementPreviousPage = useSelector((state) => state.measurement.previousPage)
    const measurementResultsCount = useSelector((state) => state.measurement.count)

    const [selectedMeter, setSelectedMeter] = useState(null)
    const [pageCountMeasurement, setPageCountMeasurement] = useState(1)
    const [pageCountMeter, setPageCountMeter] = useState(1)
    const [offsetMeasurement, setOffsetMeasurement] = useState(0)
    const [offsetMeter, setOffsetMeter] = useState(0)

    const [timeNow, setTimeNow] = useState(new Date().toLocaleTimeString().slice(0, 5))
    const dateNow = new Date().toLocaleDateString()

    const buildURL = () => {
        let baseURL = `/app/meters/measurement/`
        if (selectedMeter !== null)
            baseURL += `?meter=${selectedMeter}&limit=${rowLimitMeasurement}&offset=${offsetMeasurement}`
        return baseURL
    }

    useApi({
        url: `/app/tenants/user/${userID}/`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetUserDataApi,
        queryName: ['UserData'],
        onSuccess: (data) => dispatch(setUserData(data))
    })

    const handlerMeters = (data) => {
        dispatch(addMeters(data))
        setSelectedMeter(Object.values(data.meters).reverse()[0].pk)
    }

    const fetchMeters = useApi({
        url: `/app/meters/meter/?limit=${rowLimitMeter}&offset=${offsetMeter}`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeterApi,
        queryName: ['meters', offsetMeter],
        keepPreviousData: true,
        onSuccess: handlerMeters
    })

    const fetchMeasurement = useApi({
        enabled: fetchMeters.isSuccess,
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeasurementApi,
        queryName: ['measurement', selectedMeter, offsetMeasurement],
        keepPreviousData: true,
        onSuccess: (data) => dispatch(addMeasurements(data))
    })

    useEffect(() => {
        const time = setInterval(() => {
            setTimeNow(new Date().toLocaleTimeString().slice(0, 5))
        }, 1000)

        return () => clearInterval(time)
    }, [])

    const handleSelectedMeter = (pk) => {
        setSelectedMeter(pk)
    }

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCountMeasurement((old) => old + 1)
            else setPageCountMeasurement((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1)
                setOffsetMeasurement(page.substring(offsetPosition + 7, page.length))
            else setOffsetMeasurement(0)
        }
    }

    const onPageClickMeters = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCountMeter((old) => old + 1)
            else setPageCountMeter((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1)
                setOffsetMeter(page.substring(offsetPosition + 7, page.length))
            else setOffsetMeter(0)
        }
    }

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="home" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Witaj, {profile.name}</div>
                    <div className="title_locales">
                        <span> {dateNow} </span>
                        <span> {timeNow} </span>
                    </div>
                </div>

                <div className="home">
                    <div className="home__left">
                        <TableMeters
                            onSelectedMeter={handleSelectedMeter}
                            selectedMeter={selectedMeter}
                            pageCountMeter={pageCountMeter}
                            onPageClick={onPageClickMeters}
                            meterPreviousPage={meterPreviousPage}
                            meterNextPage={meterNextPage}
                            metersResultsCount={meterResultsCount}
                            meterRowLimit={rowLimitMeter}
                        />
                        <HomeGraph selectedMeter={selectedMeter} />
                    </div>
                    <div className="home__right">
                        <TableMeasurements
                            selectedMeter={selectedMeter}
                            pageCount={pageCountMeasurement}
                            onPageClick={onPageClick}
                            measurementPreviousPage={measurementPreviousPage}
                            measurementNextPage={measurementNextPage}
                            measurementResultsCount={measurementResultsCount}
                            rowLimit={rowLimitMeasurement}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomeUser
