import { createSlice } from '@reduxjs/toolkit'

export const DocumentSlice = createSlice({
    name: 'document',
    initialState: {
        documents: {},
        count: 0,
        nextPage: null,
        previousPage: null
    },
    reducers: {
        addDocuments: (state, action) => {
            state.documents = action.payload.documents
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        }
    }
})

export const { addDocuments } = DocumentSlice.actions
export default DocumentSlice.reducer
