import { Table } from 'evergreen-ui'

const NoResultsRow = ({ text = null }) => {
    return (
        <Table.Row className="row">
            <Table.TextCell className="centered">
                {text || 'Brak wyników. Spróbuj zmienić filtry wyszukiwania.'}
            </Table.TextCell>
        </Table.Row>
    )
}

export default NoResultsRow
