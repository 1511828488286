import { useSelector } from 'react-redux'

import UsersModerator from './Users.moderator.view'
import UsersUser from './Users.user.view'

const Meters = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? <UsersModerator /> : <UsersUser />
}

export default Meters
