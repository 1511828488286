import { useState } from 'react'
import { useSelector } from 'react-redux'

import { FaFile } from 'react-icons/fa'
import { FiBell } from 'react-icons/fi'
import { BiFilterAlt } from 'react-icons/bi'
import { BsCameraFill, BsPerson } from 'react-icons/bs'
import { MdOutlineImage, MdOutlineImageNotSupported } from 'react-icons/md'

import { Pane, Dialog, Tooltip } from 'evergreen-ui'

import { Link } from 'react-router-dom'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { pl } from 'date-fns/locale'
import { Line } from 'react-chartjs-2'
import dateConvert from '../../../utils/formatDate'
import useApi from '../../../hooks/useApi'

import { fromMeasurementApi } from '../../../utils/Adapters/MeasurementApiAdapter'

const returnMeasurementsValuesForChart = (measurements) => {
    if (Object.values(measurements).length > 0) {
        return Object.values(measurements).map((measurement) => {
            return {
                x: measurement?.createdAt,
                y: measurement?.value
            }
        })
    }
    return {}
}

const MeterExpandedRow = ({ row, isExpanded }) => {
    const [isImageShown, setIsImageShown] = useState(false)
    const [formatUnit, setFormatUnit] = useState('day')
    const [measurements, setMeasurements] = useState([])

    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    const user = useSelector((state) => state.user.users[row.tenantMember])
    const apiKey = useSelector((state) => state.profile.profile.apiKey)

    const getUrl = (rowID) => {
        if (isModerator) return `/app/meters/moderator/measurement/?meter=${rowID}`
        return `/app/meters/measurement/?meter=${rowID}`
    }

    useApi({
        url: getUrl(row.pk),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeasurementApi,
        queryName: ['measurementByMeterId', [row.pk]],
        enabled: isExpanded,
        keepPreviousData: true,
        onSuccess: (data) => setMeasurements(data.measurements)
    })

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: 'rgb(255, 255, 255)'
                }
            },
            title: {
                display: true,
                text: 'Wykorzystanie w danym miesiącu',
                color: 'rgb(255, 255, 255)'
            },
            tooltip: {
                callbacks: {
                    label: (value) => ` ${value.parsed.y} ${row.unit || ''}`
                }
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        scales: {
            x: {
                type: 'time',
                adapters: {
                    date: {
                        locale: pl
                    }
                },
                time: {
                    unit: formatUnit,
                    tooltipFormat: 'dd MMM yyyy HH:mm'
                },
                ticks: {
                    color: 'rgb(255, 255, 255)'
                },
                grid: {
                    color: 'rgb(255, 255, 255, 0.4)'
                }
            },
            y: {
                ticks: {
                    color: 'rgb(255, 255, 255, 1)'
                },
                grid: {
                    color: 'rgb(255, 255, 255, 0.4)'
                },
                title: {
                    display: true,
                    text: `Wartość [${row.unit}]`,
                    color: 'rgb(255, 255, 255, 1)'
                }
            }
        }
    }

    const data = {
        datasets: [
            {
                label: 'Wartość odczytu',
                data: returnMeasurementsValuesForChart(measurements, row),
                borderColor: 'rgb(242, 154, 74)',
                backgroundColor: 'rgb(255, 255, 255)',
                tension: 0.1
            }
        ]
    }

    const renderMeterType = () => {
        switch (row.type) {
            case 'cold_water':
                return 'Woda zimna'
            case 'hot_water':
                return 'Woda ciepła'
            case 'garden_water':
                return 'Woda ogrodowa'
            case 'gas':
                return 'Gaz'
            case 'electricity':
                return 'Elektryczność'
            case 'heat':
                return 'Ciepło'
            default:
                return 'Niezdefiniowany'
        }
    }

    const handleShowImageClick = () => {
        setIsImageShown(true)
    }

    const renderImageButton = () => {
        const renderName = () => {
            if (row.serialNumber) return row.serialNumber
            if (!row.serialNumber && row.image) return 'Zdjęcie'
            return 'Brak'
        }

        if (row.image)
            return [<MdOutlineImage size="18" fill="lightGreen" key="image" />, renderName()]
        return [<MdOutlineImageNotSupported size="18" fill="orange" key="image" />, renderName()]
    }

    const renderProperty = (element) => {
        let returnValue = row?.[element] !== '' ? row?.[element] : user?.[element]
        if (!returnValue) returnValue = '-'

        return returnValue
    }

    return (
        <div className={`more_info ${isExpanded ? 'expanded' : 'hidden'}`}>
            <div className="more_info_content">
                <div className="column">
                    <p id="title">Szczegóły licznika </p>
                    <div className="data_grid">
                        <p>Data utworzenia:</p>
                        <p>{dateConvert(row.createdAt)}</p>
                        <p>Typ licznika:</p>
                        <p>{renderMeterType()}</p>
                        <p>Jednostka:</p>
                        <p>{renderProperty('unit')}</p>
                        <p className="inLine">Nr Licznika:</p>
                        <p className="inLine">
                            <Tooltip
                                content="Pokaż zdjęcie licznika"
                                showDelay={500}
                                position="right"
                            >
                                <button
                                    type="button"
                                    className="clearButton"
                                    onClick={handleShowImageClick}
                                    disabled={!row.image}
                                >
                                    {renderImageButton()}
                                </button>
                            </Tooltip>
                        </p>
                        <p id="spacer_row">Adres licznika:</p>
                        <p style={{ textIndent: '1em' }}>Ulica:</p>
                        <p>{renderProperty('street')}</p>
                        <p style={{ textIndent: '1em' }}>Miasto:</p>
                        <p>{renderProperty('city')}</p>
                        <p style={{ textIndent: '1em' }}>Kod pocztowy:</p>
                        <p>{renderProperty('zipCode')}</p>
                    </div>
                </div>
                <div className="more_info_content_chart">
                    <Line options={options} data={data} color="white" />
                </div>

                <div className="column">
                    <div className="column_right">
                        <p id="title">Dostępne działania </p>
                        <Tooltip
                            content="Pokaż liczniki tego odbiorcy"
                            showDelay={500}
                            position="left"
                        >
                            <Link to={`/meters?user_id=${row.tenantMember}`}>
                                <button type="button" className="button">
                                    <BiFilterAlt size="18" /> Filtruj po odbiorcy
                                </button>
                            </Link>
                        </Tooltip>
                        <Tooltip content="Przejdź do powiadomień" showDelay={500} position="left">
                            <Link to={`/notifications?user_id=${row.tenantMember}&type=meter`}>
                                <button type="button" className="button">
                                    <FiBell size="18" color="#142c66" /> Powiadomienia
                                </button>
                            </Link>
                        </Tooltip>
                        <Tooltip content="Przejdź do dokumentów" showDelay={500} position="left">
                            <Link to={`/documents?meter_id=${row.pk}`}>
                                <button type="button" className="button">
                                    <FaFile size="18" color="#142c66" /> Dokumenty
                                </button>
                            </Link>
                        </Tooltip>
                    </div>
                    <div className="column_right">
                        <Tooltip content="Przejdź do odbiorcy" showDelay={500} position="left">
                            <Link to={`/users?user_id=${row.tenantMember}`}>
                                <button type="button" className="button">
                                    <BsPerson size="18" color="#142c66" /> Odbiorca
                                </button>
                            </Link>
                        </Tooltip>
                        <Tooltip content="Przejdź do odczytów" showDelay={500} position="left">
                            <Link to={`/readings?meter_id=${row.pk}`}>
                                <button type="button" className="button">
                                    <BsCameraFill size="18" color="#142c66" /> Odczyty
                                </button>
                            </Link>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Pane>
                <Dialog
                    isShown={isImageShown}
                    title="Zdjęcie licznika"
                    onCloseComplete={() => setIsImageShown(false)}
                    hasFooter={false}
                >
                    <Zoom>
                        <div className="dialog_image_container">
                            <img src={row.image} alt="meter" />
                        </div>
                    </Zoom>
                </Dialog>
            </Pane>
        </div>
    )
}

export default MeterExpandedRow
