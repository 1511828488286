export const userRejectOptions = [
    {
        title: 'Błąd wpisanych przez użytkownika danych',
        content: ''
    },
    {
        title: 'Przesłana faktura się nie zgadza',
        content: ''
    },
    {
        title: 'Inne...',
        content: ''
    }
]

export const measurementRejectOptions = [
    {
        title: 'Błąd w przesłanym odczycie',
        content: ''
    },
    {
        title: 'Przesłana zdjęcie jest nie wyraźne',
        content: ''
    },
    {
        title: 'Inne...',
        content: ''
    }
]
