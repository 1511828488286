import dateConvert from '../../../utils/formatDate'
import { renderChannel, renderRecipient } from '../../../utils/notificationLogs'
import { renderUserPills } from '../../../utils/renderUsers'

const NotificationExpandedRow = ({ log, users, navigate, isExpanded }) => {
    const stripHTMLTags = (text) => {
        return text.replace(/(<([^>]+)>)/gi, '')
    }

    return (
        <div className={`more_info ${isExpanded ? 'expanded' : 'hidden'}`}>
            <div className="more_info_content">
                <div className="column column_fixed">
                    <div className="data_grid">
                        <p>Nadawca:</p>
                        <p>{log.createdBy !== null ? log.createdBy : 'System'}</p>
                        <p>Tytuł:</p>
                        <p>{log.title}</p>
                        <p>Treść:</p>
                        <p>{stripHTMLTags(log.body)}</p>
                        <p>Kanał:</p>
                        <p>{renderChannel(log.channel, true)}</p>
                        {renderRecipient('notification', log.recipient)}
                        <p>Odbiorcy:</p>
                        <div className="box-pills">{renderUserPills(users, navigate, true)}</div>
                        <p>Data utworzenia:</p>
                        <p>{dateConvert(log.createdAt)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationExpandedRow
