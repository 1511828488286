import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Table } from 'evergreen-ui'

import Footer from '../../components/Footer'
import LeftMenu from '../../components/LeftMenu'
import PaginationButtons from '../../components/PaginationButtons'
import PaginationInput from '../../components/PaginationInput'
import RowLimit from '../../components/RowLimit'
import TopBar from '../../components/TopBar/TopBar'
import HistoryRow from '../../components/ListRows/HistoryRows/HistoryRow'
import ActiveFilters from '../../components/activeFilters'
import HistoryDetailDialog from '../../components/HistoryDetailDialog'
import NoResultsRow from '../../components/ListRows/NoResultsRow'

import useApi from '../../hooks/useApi'

import { addHistoryLogs } from '../../redux/slices/historyLogSlice'
import { addUsers } from '../../redux/slices/userSlice'

import fromHistoryLogApiUser from '../../utils/Adapters/LogApiAdapter'
import { fromGetUserTenantApi } from '../../utils/Adapters/UserApiAdapter'

import '../../styles/views/faq.scss'

const TableRows = ({ logs, setIsDetailsShown, setLogDetailId }) => {
    if (Object.values(logs).length === 0) {
        return <NoResultsRow />
    }
    return Object.entries(logs)
        .reverse()
        .map(([key, value]) => {
            return (
                <HistoryRow
                    key={key}
                    log={value}
                    setIsDetailsShown={setIsDetailsShown}
                    setLogDetailId={setLogDetailId}
                />
            )
        })
}

const HistoryUser = () => {
    const dispatch = useDispatch()

    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const historyLogs = useSelector((state) => state.historyLog.historyLog)
    const historyLogsCount = useSelector((state) => state.historyLog.count)
    const historyLogsPreviousPage = useSelector((state) => state.historyLog.previousPage)
    const historyLogsNextPage = useSelector((state) => state.historyLog.nextPage)
    const rowLimit = useSelector((state) => state.profile.rowLimit)
    const profile = useSelector((state) => state.profile.profile.id)

    const [urlParams, setUrlParams] = useSearchParams()

    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [isDetailsShown, setIsDetailsShown] = useState(false)
    const [logDetailId, setLogDetailId] = useState(null)
    const [usersToFetch, setUsersToFetch] = useState(profile)

    // url params - filters
    const [userParam, setUserParam] = useState(null)
    const [meterParam, setMeterParam] = useState(null)
    const [measurementParam, setMeasurementParam] = useState(null)
    const [excludeMeasurementParam, setExcludeMeasurementParam] = useState(null)
    const [excludeTenantMemberParam, setExcludeTenantMemberParam] = useState(null)
    const [excludeNotificationParam, setExcludeNotificationParam] = useState(null)
    const [startDateParam, setStartDateParam] = useState(null)
    const [endDateParam, setEndDateParam] = useState(null)
    // sorting
    const [sortingParam, setSortingParam] = useState(null)

    const buildURL = () => {
        let baseURL = `/app/notifications/history/?limit=${rowLimit}&offset=${offset}`

        // filters
        if (userParam !== null) baseURL += `&user=${userParam}`
        if (meterParam !== null) baseURL += `&meter__in=${meterParam}`
        if (measurementParam !== null) baseURL += `&measurement__in=${measurementParam}`
        if (excludeMeasurementParam !== null)
            baseURL += `&exclude_measurement_logs=${excludeMeasurementParam}`
        if (excludeTenantMemberParam !== null)
            baseURL += `&exclude_tenant_member_logs=${excludeTenantMemberParam}`
        if (excludeNotificationParam !== null)
            baseURL += `&exclude_notification_logs=${excludeNotificationParam}`
        if (startDateParam !== null && endDateParam !== null) {
            baseURL += `&created_at__after=${startDateParam}`
            baseURL += `&created_at__before=${endDateParam}`
        }

        // sorting
        if (sortingParam !== null) baseURL += `&ordering=${sortingParam}`
        else baseURL += '&ordering=-created_at'

        return baseURL
    }

    const fetchHistoryLogs = useApi({
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromHistoryLogApiUser,
        queryName: [
            'history',
            offset,
            meterParam,
            userParam,
            measurementParam,
            excludeMeasurementParam,
            excludeTenantMemberParam,
            excludeNotificationParam,
            startDateParam,
            endDateParam,
            sortingParam,
            rowLimit
        ],
        keepPreviousData: true,
        onSuccess: (data) => {
            dispatch(addHistoryLogs(data))
        }
    })

    useApi({
        url: `/app/tenants/standard/tenant_member/?id__in=${usersToFetch}`,
        method: 'GET',
        apiKey,
        enabled: fetchHistoryLogs.isSuccess && usersToFetch.length > 0,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: ['users', usersToFetch],
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const handlePaginationInputonBlur = (e) => {
        setOffset(rowLimit * e - rowLimit)
        setPageCount(e)
    }

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCount((old) => old + 1)
            else setPageCount((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1) setOffset(page.substring(offsetPosition + 7, page.length))
            else setOffset(0)
        }
    }

    useEffect(() => {
        let userIDs = []

        Object.values(historyLogs).forEach((log) => {
            userIDs = userIDs.concat(log.users)

            const tenantMembers = log.tenantMember
            if (tenantMembers !== null) {
                userIDs = userIDs.concat([tenantMembers])
            }
        })

        setUsersToFetch([...new Set(userIDs)].join(','))
    }, [historyLogs])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }

        setUserParam(null)
        setMeterParam(null)
        setStartDateParam(null)
        setEndDateParam(null)
        setExcludeNotificationParam(null)
        setExcludeTenantMemberParam(null)
        setExcludeMeasurementParam(null)
        setMeasurementParam(null)

        // filters
        const userParamGET = parseInt(urlParams.get('user_id'), 10)
        const meterParamGET = parseInt(urlParams.get('meter_id'), 10)
        const measurementParamGET = parseInt(urlParams.get('measurement_id'), 10)
        const excludeParamGET = urlParams.get('exclude')
        const createdAtParamGET = urlParams.get('created_at') || null
        // sorting
        const sortingParamGET = urlParams.get('sorting') || null

        // filters
        if (!Number.isNaN(userParamGET)) setUserParam(userParamGET)
        if (!Number.isNaN(meterParamGET)) setMeterParam(meterParamGET)
        if (!Number.isNaN(measurementParamGET)) setMeasurementParam(measurementParamGET)
        switch (excludeParamGET) {
            case 'exclude_measurement':
                setExcludeMeasurementParam('true')
                break
            case 'exclude_tenant_member':
                setExcludeTenantMemberParam('true')
                break
            case 'exclude_notification':
                setExcludeNotificationParam('true')
                break
            default:
                break
        }

        if (createdAtParamGET !== null) {
            const endDate = new Date()
            let startDate = null
            setEndDateParam(endDate.toISOString().split('T')[0])

            // 1000 * 60 * 60 * 24 - stands for one day in ms
            switch (createdAtParamGET) {
                case 'last_24h': {
                    startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_week': {
                    startDate = new Date(endDate.getTime() - 7 * 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_month': {
                    startDate = new Date(endDate.getTime() - 30 * 1000 * 60 * 60 * 24)
                    break
                }
                default:
                    break
            }

            setStartDateParam(startDate.toISOString().split('T')[0])
        }

        // sorting
        if (sortingParamGET !== null) {
            switch (sortingParamGET) {
                case 'created_at_asc':
                    setSortingParam('created_at')
                    break
                case 'created_at_desc':
                    setSortingParam('-created_at')
                    break
                default:
                    break
            }
        }
    }, [urlParams])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
    }, [rowLimit])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="history" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Historia komunikacji z odbiorcami</div>
                    <ActiveFilters historyFilters />
                </div>
                <div className="container">
                    <div className="container_main">
                        <div className="container_cards">
                            <Table className="table">
                                <Table.Head className="header">
                                    <Table.TextHeaderCell className="table_title_col">
                                        Tytuł
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Kanał
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Typ
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        className="table_title_col"
                                        flexBasis="190px"
                                        flexShrink={0}
                                        flexGrow={0}
                                    >
                                        Data utworzenia
                                    </Table.TextHeaderCell>
                                </Table.Head>
                                <Table.Body>
                                    <TableRows
                                        logs={historyLogs}
                                        setIsDetailsShown={setIsDetailsShown}
                                        setLogDetailId={setLogDetailId}
                                    />
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="pagination_box">
                            <PaginationInput
                                pageCount={pageCount}
                                onBlur={handlePaginationInputonBlur}
                                resultCount={historyLogsCount}
                                apiLimit={rowLimit}
                            />
                            <RowLimit />
                        </div>
                        <PaginationButtons
                            pageCount={pageCount}
                            onPageClick={onPageClick}
                            prevPage={historyLogsPreviousPage}
                            nextPage={historyLogsNextPage}
                            resultCount={historyLogsCount}
                            apiLimit={rowLimit}
                        />
                    </div>
                </div>
            </div>
            <HistoryDetailDialog
                isShown={isDetailsShown}
                setIsShown={setIsDetailsShown}
                logs={historyLogs[logDetailId]}
            />
            <Footer />
        </div>
    )
}

export default HistoryUser
