const renderDocumentType = (type) => {
    switch (type) {
        case 'payment_reminder':
            return <span>Przypomnienie o płatności</span>
        case 'invoice':
            return <span>Faktura</span>
        case 'other':
            return <span>Inne</span>
        default:
            return <span>Niezdefiniowano</span>
    }
}

export default renderDocumentType
