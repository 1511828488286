import { toaster } from 'evergreen-ui'

const fromPasswordChangeApiCallError = (errObj) => {
    const { data, status } = errObj.response
    if (status === 400) {
        if (data.non_field_errors) toaster.danger('Hasła nie są takie same')
        if (data.detail) toaster.danger('Aktualne hasło jest błędne')
        return { error: 'Hasła nie są takie same' }
    }
    if (status === 401) {
        toaster.danger('Błąd autoryzacji')
        return { error: 'Błąd autoryzacji' }
    }
    toaster.danger('Błąd serwera')
    return { error: 'Błąd serwera' }
}

export default fromPasswordChangeApiCallError
