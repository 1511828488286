import '../styles/components/progressBar.scss'

const ProgressBar = ({ data = [] }) => {
    const renderBars = () => {
        return data.map((values) => {
            if (values.value === 0 || Number.isNaN(values.value)) return null

            return (
                <div
                    key={values.color}
                    className={`filler ${values.color}`}
                    data-testid="filler"
                    style={{ width: `${values.value}%` }}
                >
                    <span className="label" data-testid="label">{`${values.value}%`}</span>
                </div>
            )
        })
    }

    return <div className="progress_container">{renderBars()}</div>
}

export default ProgressBar
