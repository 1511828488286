import '../styles/components/button.scss'

const Button = ({ text, onClick, style, children }) => {
    return (
        <button className="buttonComponent" type="button" onClick={onClick} style={{ ...style }}>
            {text}
            {children}
        </button>
    )
}

export default Button
