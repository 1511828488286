import { createSlice } from '@reduxjs/toolkit'

export const tenantSlice = createSlice({
    name: 'tenant',
    initialState: {
        tenants: {},
        count: 0,
        nextPage: null,
        previousPage: null
    },
    reducers: {
        addTenants: (state, action) => {
            state.tenants = action.payload.tenants
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        }
    }
})

export const { addTenants } = tenantSlice.actions
export default tenantSlice.reducer
