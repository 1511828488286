const DataGridRow = ({ name, data }) => {
    if (!data) return null

    return (
        <>
            <span>{name}:</span>
            <span>{data}</span>
        </>
    )
}

export default DataGridRow
