import { addPaymentData } from '../redux/slices/paymentSlices'

const savePaymentData = (dispatch, document) => {
    dispatch(
        addPaymentData(
            document.id,
            document.user,
            document.measurement[0],
            document.amount,
            document.tenant
        )
    )
}

export default savePaymentData
