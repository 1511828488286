import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

import { toaster } from 'evergreen-ui'
import { BiLeftArrowAlt } from 'react-icons/bi'

import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import RenderSelects from '../../components/Notifications/LeftBox/RenderSelectedMenus'
import NotificationsType from '../../components/Notifications/RightBox/NotificationType'
import NotificationsEdit from '../../components/Notifications/RightBox/NotificationEdit'
import Footer from '../../components/Footer'
import { addUsers } from '../../redux/slices/userSlice'

import useApi from '../../hooks/useApi'
import usePrompt from '../../hooks/useBackListener'

import FormSteps from '../../components/Notifications/FormSteps'
import RadioButtonAlert from '../../components/Notifications/LeftBox/RadioButtonAlert'
import RadioPane from '../../components/Notifications/LeftBox/RadioPane'
import LeftButtonBox from '../../components/Notifications/LeftBox/LeftButtonBox'
import RightButtonBox from '../../components/Notifications/RightBox/RightButtonBox'
import SummaryButtonBox from '../../components/Notifications/SummaryBox/SummaryButtonBox'

import { fromGetUserTenantApi } from '../../utils/Adapters/UserApiAdapter'
import toNotificationApi from '../../utils/Adapters/NotificationApiAdapter'
import topics from '../../static/notificationsTopics'

import '../../styles/views/notifications.scss'

const SendNotification = () => {
    const dispatch = useDispatch()

    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const users = useSelector((state) => state.user.users)

    const [urlParams, setUrlParams] = useSearchParams()

    const [selectedItems, setSelectedItems] = useState([])
    const [value, setValue] = useState('users')
    const [isPush, setIsPush] = useState(true)
    const [isEmail, setIsEmail] = useState(false)
    const [isSMS, setIsSMS] = useState(false)
    const [notifyTitle, setNotifyTitle] = useState('')
    const [notifyContent, setNotifyContent] = useState('')
    const [selectedTopic, setSelectedTopic] = useState('')
    const [isNotifyAlert, setIsNotifyAlert] = useState(false)
    const [isFormDuringEdition, setIsFormDuringEdition] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const [takenSteps, setTakenSteps] = useState([])
    const [rightBoxDisabled, setRightBoxDisabled] = useState(false)
    const [isLeftBoxDisabled, setIsLeftBoxDisabled] = useState(false)
    const [isContentTitleError, setIsContentTitleError] = useState(false)
    const [isRadioButtonError, setIsRadioButtonError] = useState(false)

    const [options] = useState([
        { label: 'Wszyscy', value: 'all' },
        { label: 'Grupy', value: 'group' },
        { label: 'Odbiorcy', value: 'users' }
    ])

    usePrompt('Wprowadzone zmiany zostaną usunięte', isFormDuringEdition)

    useApi({
        url: '/app/tenants/moderator/tenant_member/',
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: ['users'],
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const sendNotificationCall = useApi({
        method: 'POST',
        apiKey,
        url: '/app/notifications/manage/',
        toApiAdapter: toNotificationApi
    })

    const handleSendNotificationButton = () => {
        if (
            value !== 'all'
                ? Object.values(selectedItems).length > 0
                : Object.values(selectedItems).length === 0 &&
                  (isEmail || isPush || isSMS) &&
                  notifyTitle.length > 0
        ) {
            sendNotificationCall
                .mutateAsync({
                    data: {
                        notifyTitle,
                        notifyContent,
                        isEmail,
                        isPush,
                        isSMS,
                        value,
                        selectedItems
                    }
                })
                .then(() => {
                    toaster.success('Pomyślne wysłano powiadomienie')
                    setIsLeftBoxDisabled(false)
                    setRightBoxDisabled(false)
                    setTakenSteps([])
                    setCurrentStep(0)
                    setNotifyContent('')
                    setNotifyTitle('')
                    setValue('all')
                    setIsFormDuringEdition(false)
                })
        } else toaster.warning('Przy wysyłaniu powiadomienia wystąpił błąd')
    }

    const handleTopicChanged = (e) => {
        setIsFormDuringEdition(true)
        setSelectedTopic(e)
        topics.forEach((topic) => {
            if (topic.key === e) {
                setNotifyTitle(topic.title)
                setNotifyContent(topic.content)
            }
        })
    }

    const handleRadioStateChanged = (e) => {
        setIsFormDuringEdition(true)
        setValue(e)
        setSelectedItems([])
    }

    const handleNotifyTitleChanged = (e) => {
        setNotifyTitle(e)
        setIsFormDuringEdition(true)
        setIsContentTitleError(false)
    }

    const handleNotifyContentChanged = (e) => {
        setNotifyContent(e)
        setIsFormDuringEdition(true)
        setIsContentTitleError(false)
    }

    const handleNextStepButtonClick = (e) => {
        const { name } = e.target
        const found = takenSteps.find((elemnet) => elemnet === parseInt(name, 10))
        if (!found) {
            if (parseInt(name, 10) !== 2) {
                if (parseInt(name, 10) === 1 && selectedItems.length === 0 && value !== 'all') {
                    setIsRadioButtonError(true)
                    return false
                }
                if (parseInt(name, 10) === 1) setIsLeftBoxDisabled(true)
                setCurrentStep(parseInt(name, 10))
                setTakenSteps((state) => [...state, parseInt(name, 10)])
            }
            if (
                parseInt(name, 10) === 2 &&
                notifyContent.length > 0 &&
                notifyTitle.length > 0 &&
                (isEmail || isPush || isSMS)
            ) {
                setCurrentStep(parseInt(name, 10))
                setRightBoxDisabled(true)
                setTakenSteps((state) => [...state, parseInt(name, 10)])
            }
            if (
                parseInt(name, 10) === 2 &&
                notifyContent.length === 0 &&
                notifyTitle.length === 0
            ) {
                setIsContentTitleError(true)
            }
        }
        return true
    }

    const handlePrevStepButtonClick = (e) => {
        const { name } = e.target
        const found = takenSteps.find((elemnet) => elemnet === parseInt(name, 10))
        if (found) {
            if (parseInt(name, 10) === 2) setRightBoxDisabled(false)
            if (parseInt(name, 10) === 1) setIsLeftBoxDisabled(false)
            setCurrentStep(parseInt(name, 10) - 1)
            takenSteps.pop()
        }
    }

    useEffect(() => {
        if (isEmail || isPush || isSMS) setIsNotifyAlert(false)
        else setIsNotifyAlert(true)
    }, [isEmail, isPush, isSMS])

    useEffect(() => {
        setIsRadioButtonError(false)
    }, [selectedItems])

    useEffect(() => {
        const userParamGET = parseInt(urlParams.get('user_id'), 10)

        if (!Number.isNaN(userParamGET)) {
            setSelectedItems([userParamGET.toString()])
            setValue('users')
        }
    }, [urlParams])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="notifications" />
            <div className="main_content">
                <div className="notify_title">
                    <div className="title_name">Powiadomienia</div>
                    <FormSteps
                        currentStep={currentStep}
                        steps={['Wybór odbiorców', 'Edycja danych', 'Podsumowanie']}
                    />
                    <Link to="/notifications">
                        <button type="button" className="exit_button">
                            <BiLeftArrowAlt size={16} /> Powrót
                        </button>
                    </Link>
                </div>
                <div className="container">
                    <div className="container_main">
                        <div className="notificationBox">
                            <div
                                className={`notificationBox_settingsLeftBox ${
                                    currentStep === 0 ? 'highlighted' : 'deactivated'
                                } ${currentStep > 0 ? 'doneStep' : ''} `}
                            >
                                <div className="titlebox">
                                    <div className="title">Zarządzanie odbiorcami</div>
                                </div>
                                <RadioButtonAlert isRadioButtonError={isRadioButtonError} />
                                <RadioPane
                                    value={value}
                                    isLeftBoxDisabled={isLeftBoxDisabled}
                                    handleRadioStateChanged={handleRadioStateChanged}
                                />
                                <RenderSelects
                                    selected={selectedItems}
                                    setSelected={setSelectedItems}
                                    users={users}
                                    value={value}
                                    isDisabled={isLeftBoxDisabled}
                                />
                                <LeftButtonBox
                                    currentStep={currentStep}
                                    handleNextStepButtonClick={handleNextStepButtonClick}
                                    disabled={takenSteps.find((elemnet) => elemnet === 1)}
                                />
                            </div>
                            <div
                                className={`notificationBox_settingsRightBox ${
                                    currentStep === 1 ? 'highlighted' : 'deactivated'
                                } ${currentStep > 1 ? 'doneStep' : ''}`}
                            >
                                <div
                                    className={`${
                                        takenSteps.find((elemnet) => elemnet === 1) ? '' : 'greyBox'
                                    }`}
                                />
                                <NotificationsType
                                    isNotifyAlert={isNotifyAlert}
                                    isEmail={isEmail}
                                    isPush={isPush}
                                    isSMS={isSMS}
                                    setIsEmail={setIsEmail}
                                    setIsPush={setIsPush}
                                    setIsSMS={setIsSMS}
                                    isDisabled={rightBoxDisabled}
                                />
                                <NotificationsEdit
                                    topics={topics}
                                    handleTopicChanged={handleTopicChanged}
                                    notifyTitle={notifyTitle}
                                    notifyContent={notifyContent}
                                    setNotifyContent={handleNotifyContentChanged}
                                    setNotifyTitle={handleNotifyTitleChanged}
                                    isBoxDisabled={rightBoxDisabled}
                                    isError={isContentTitleError}
                                />
                                <RightButtonBox
                                    handleNextStepButtonClick={handleNextStepButtonClick}
                                    handlePrevStepButtonClick={handlePrevStepButtonClick}
                                    currentStep={currentStep}
                                    disabled={
                                        !takenSteps.find((elemnet) => elemnet === 1) ||
                                        takenSteps.find((elemnet) => elemnet === 2)
                                    }
                                />
                            </div>
                        </div>
                        <div className={`summaryBox ${currentStep === 2 ? 'highlighted' : ''}`}>
                            {!takenSteps.find((elemnet) => elemnet === 2) ? (
                                <div className="greyBox" />
                            ) : null}
                            <span className="title">
                                Sprawdź powyższe dane jeśli się zgadzają, kliknij przycisk po prawej
                                stronie
                            </span>
                            <SummaryButtonBox
                                handlePrevStepButtonClick={handlePrevStepButtonClick}
                                handleSendNotificationButton={handleSendNotificationButton}
                                disabled={!takenSteps.find((elemnet) => elemnet === 2)}
                                currentStep={currentStep}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SendNotification
