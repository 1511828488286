export const renderFieldName = (field) => {
    switch (field) {
        case 'state':
            return 'Stan'
        case 'city':
            return 'Miasto'
        case 'last_invoice_image':
            return 'Zdjęcie ostatniej faktury'
        case 'name_surname':
            return 'Imię i nazwisko'
        case 'is_company':
            return 'Czy firma'
        case 'last_invoice_number':
            return 'Numer ostatniej faktury'
        case 'company_name':
            return 'Nazwa firmy'
        case 'nip':
            return 'NIP'
        case 'client_number':
            return 'Numer klienta'
        default:
            return field
    }
}

export const renderFieldValue = (value) => {
    switch (value) {
        case 'new':
            return 'Nowy'
        case 'verified':
            return 'Zweryfikowano'
        case 'rejected':
            return 'Odrzucono'
        case 'true':
            return 'Tak'
        case 'false':
            return 'Nie'
        default:
            return value
    }
}
