import { Dialog, Table } from 'evergreen-ui'

import dateConvert from '../utils/formatDate'
import '../styles/components/button.scss'
import '../styles/components/dialog.scss'
import { renderFieldName, renderFieldValue } from '../utils/historyLogs'

const TableRows = ({ changes }) => {
    const rows = {}

    changes.forEach(([key, value, type]) => {
        if (key === 'reason') {
            return
        }
        if (!(key in rows)) {
            rows[key] = {}
        }
        if (type === 'new_value') {
            if (value.length === 0) rows[key][type] = '-'
            else rows[key][type] = value
        } else if (type === 'old_value') {
            if (value.length === 0) rows[key][type] = '-'
            else rows[key][type] = value
        }
    })
    return Object.entries(rows).map((el) => {
        return <Rows key={el} items={el} />
    })
}

const Rows = ({ items }) => {
    return (
        <Table.Row>
            <Table.TextCell>{renderFieldName(items[0])}</Table.TextCell>
            <Table.TextCell>{renderFieldValue(items[1].new_value)}</Table.TextCell>
            <Table.TextCell>{renderFieldValue(items[1].old_value)}</Table.TextCell>
        </Table.Row>
    )
}

export const UserHistoryDialog = ({ isShown, setIsShown, userLogs }) => {
    const handleDialogClose = () => {
        setIsShown(false)
    }

    const customDialogFooter = () => {
        return (
            <div className="dialogCustomFooter">
                <button type="button" className="cancelButton" onClick={handleDialogClose}>
                    Zamknij
                </button>
            </div>
        )
    }

    return (
        <Dialog
            isShown={isShown}
            title="Historia zmian użytkownika"
            onCloseComplete={handleDialogClose}
            cancelLabel="Anuluj"
            width={700}
            footer={customDialogFooter}
        >
            {userLogs.map((value) => {
                return (
                    <div key={value}>
                        <p>Data aktualizacji: {dateConvert(value.createdAt)}</p>
                        <Table>
                            <Table.Head>
                                <Table.TextHeaderCell>Pole</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Nowa wartość</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Stara wartość</Table.TextHeaderCell>
                            </Table.Head>
                            <Table.Body>
                                <TableRows changes={value?.changes} />
                            </Table.Body>
                        </Table>
                    </div>
                )
            })}
        </Dialog>
    )
}

export default UserHistoryDialog
