const FormSteps = ({ currentStep, steps }) => {
    const getClassName = (index) => {
        let className = 'circle '

        if (index < currentStep) className += 'doneCircle'
        if (currentStep === index) className += 'highlightedCircle'

        return className
    }

    return (
        <div className="notify_title_form_steps">
            {steps.map((step, index) => {
                return (
                    <div className="stepContainer" key={step}>
                        <div className="circle_box">
                            <div className={getClassName(index)}>{index + 1}</div>
                            <span>{step}</span>
                        </div>
                        {steps.length !== index + 1 && <hr />}
                    </div>
                )
            })}
        </div>
    )
}
export default FormSteps
