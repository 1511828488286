const topics = [
    {
        title: 'Wystawiono nową fakturę',
        content:
            'Odwiedź nasza aplikację aby ją zobaczyć. Zapłać w terminie aby utrzymać zniżki 😊',
        key: 'Faktura'
    },
    {
        title: 'Termin twojej płatności niedługo minie',
        content: 'Zapłać fakturę w terminie! 😉',
        key: 'Płatność'
    }
]
export default topics
