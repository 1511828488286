import { Tooltip, Pill } from 'evergreen-ui'

const renderMeasurementPills = (objList, navigate) => {
    return objList.map((measurement) => {
        return (
            <Tooltip content="Przejdź do odbiorcy" showDelay={500} key={measurement}>
                <Pill
                    className="box-pills__notification"
                    isInteractive
                    onClick={() => navigate(`/readings?reading_id=${measurement}`)}
                >
                    {measurement}
                </Pill>
            </Tooltip>
        )
    })
}

export default renderMeasurementPills
