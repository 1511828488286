import { useRef } from 'react'
import { Dialog } from 'evergreen-ui'
import '../../styles/components/button.scss'
import '../../styles/components/dialog.scss'

export const MeasurementAcceptDialog = ({ isShown, setIsShown, onSendClick }) => {
    const inputFile = useRef()

    const handleDialogClose = () => {
        setIsShown(false)
    }
    const handleVerifyClick = () => {
        setIsShown(false)
        onSendClick()
    }

    const customDialogFooter = () => {
        return (
            <div className="dialogCustomFooter">
                <button type="button" className="cancelButton" onClick={handleDialogClose}>
                    Anuluj
                </button>
                <button type="button" className="button" onClick={handleVerifyClick}>
                    Zatwierdź
                </button>
            </div>
        )
    }

    return (
        <Dialog
            isShown={isShown}
            title="Zatwierdzenie odczytu"
            onCloseComplete={handleDialogClose}
            confirmLabel="Wybierz plik"
            onConfirm={() => inputFile.current.click()}
            cancelLabel="Anuluj"
            width={700}
            contentContainerProps={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            topOffset={60}
            footer={customDialogFooter}
        >
            <p>Czy na pewno chcesz zatwierdzić dane przesłane przez odbiorcę?</p>
        </Dialog>
    )
}

export default MeasurementAcceptDialog
