/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CgProfile } from 'react-icons/cg'
import { FiLogOut } from 'react-icons/fi'
import { Avatar, toaster } from 'evergreen-ui'

import TopBar from '../components/TopBar/TopBar'
import LeftMenu from '../components/LeftMenu'
import ProfileSide from '../components/profileSideViews/profileSide'
import ProfileEditSide from '../components/profileSideViews/profileEditSide'
import ProfileEditPasswordSide from '../components/profileSideViews/profileEditPassword'
import Footer from '../components/Footer'

import useApi from '../hooks/useApi'

import { changeEditedProfile, setUserData } from '../redux/slices/profileSlice'

import eventEmitter from '../utils/eventEmitter'
import {
    fromGetUserDataApi,
    toUpdateUserDataApi,
    fromResetPasswordApi,
    toResetPasswordApi
} from '../utils/Adapters/ProfileApiAdapter'

import '../styles/views/profile.scss'
import usePrompt from '../hooks/useBackListener'
import fromPasswordChangeApiCallError from '../utils/Adapters/errorAdpters/ProfileErrorApiAdapter'

const passwordModel = {
    actualPassword: '',
    newPassword: '',
    repeatNewPassword: ''
}
const Profile = () => {
    const dispatch = useDispatch()
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const userID = useSelector((state) => state.profile.profile.id)
    const profile = useSelector((state) => state.profile.profile)
    const editedProfile = useSelector((state) => state.profile.editedProfile)

    const [passwordData, setPasswordData] = useState(passwordModel)
    const [isFormDuringEdition, setIsFormDuringEdition] = useState(false)
    const [viewName, setViewName] = useState('profile')
    const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState('')
    const [newPasswordErrorText, setNewPasswordErrorText] = useState('')
    const [repeatNewPasswordErrorText, setRepeatNewPasswordErrorText] = useState('')

    usePrompt('Wprowadzone zmiany zostaną usunięte', isFormDuringEdition)

    useApi({
        url: `/app/tenants/user/${userID}/`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromGetUserDataApi,
        queryName: ['UserData'],
        onSuccess: (data) => dispatch(setUserData(data))
    })

    const updateProfile = useApi({
        method: 'PUT',
        apiKey,
        toApiAdapter: toUpdateUserDataApi,
        fromApiAdapter: fromGetUserDataApi
    })

    const updatePassword = useApi({
        method: 'POST',
        apiKey,
        toApiAdapter: toResetPasswordApi,
        fromApiAdapter: fromResetPasswordApi,
        fromApiErrorAdapter: fromPasswordChangeApiCallError
    })

    const checkIfPasswordsAreValid = () => {
        if (passwordData.actualPassword.length === 0) {
            setCurrentPasswordErrorText('To pole musi zostać uzupełnione!')
        } else {
            setCurrentPasswordErrorText('')
        }
        if (passwordData.newPassword.length === 0) {
            setNewPasswordErrorText('To pole musi zostać uzupełnione!')
        } else {
            setNewPasswordErrorText('')
        }
        if (passwordData.repeatNewPassword.length === 0) {
            setRepeatNewPasswordErrorText('To pole musi zostać uzupełnione!')
        } else {
            setRepeatNewPasswordErrorText('')
        }
        if (
            passwordData.actualPassword.length > 0 &&
            passwordData.newPassword.length > 0 &&
            passwordData.repeatNewPassword.length > 0
        ) {
            return true
        }
        return false
    }

    const handlePasswordSaveClick = async () => {
        if (checkIfPasswordsAreValid())
            updatePassword
                .mutateAsync({
                    data: passwordData,
                    url: `/app/users/change_password/`
                })
                .then(() => {
                    toaster.success('Hasło zmieniono pomyślnie')
                    setPasswordData(passwordModel)
                    setViewName('profile')
                })
    }

    const handleLogoutButtonClick = () => {
        eventEmitter.emit('showLogout')
    }

    const handleSaveUserInfo = async () => {
        if (profile.email !== editedProfile.email) {
            toaster.warning('Dalej, dalej drugi endpoint gadżeta')
        }

        updateProfile
            .mutateAsync({
                data: { ...editedProfile },
                url: `/app/tenants/user/${userID}/`
            })
            .then((response) => {
                toaster.success('Pomyslnie edytowano profil')
                dispatch(setUserData(fromGetUserDataApi(response)))
                dispatch(changeEditedProfile(fromGetUserDataApi(response)))
                setViewName('profile')
            })
    }

    const handleChangedTextUserInfo = (e) => {
        setIsFormDuringEdition(true)
        let { value } = e.target
        if (value.length === 0) value = profile[e.target.name]
        dispatch(changeEditedProfile({ [e.target.name]: value }))
    }

    const handleChangePasswordTextChanged = (e) => {
        setIsFormDuringEdition(true)
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value })
    }

    const restetStates = () => {
        setCurrentPasswordErrorText('')
        setNewPasswordErrorText('')
        setRepeatNewPasswordErrorText('')
        setPasswordData(passwordModel)
    }

    const handleProfileEditReturnClick = () => {
        if (!isFormDuringEdition) {
            setViewName('profile')
            restetStates()
        } else {
            const confirmation = confirm('Wprowadzone zmiany zostaną usunięte')
            if (confirmation) {
                setViewName('profile')
                setIsFormDuringEdition(false)
                restetStates()
            }
        }
    }

    const renderViewSwitch = (Name) => {
        switch (Name) {
            case 'profile':
                return (
                    <ProfileSide
                        onEditInfoClick={() => setViewName('editProfile')}
                        onChangePasswordClick={() => setViewName('editPassword')}
                        user={profile}
                    />
                )
            case 'editProfile':
                return (
                    <ProfileEditSide
                        onSaveClick={handleSaveUserInfo}
                        onReturnClick={handleProfileEditReturnClick}
                        onTextChanged={handleChangedTextUserInfo}
                        user={profile}
                    />
                )
            case 'editPassword':
                return (
                    <ProfileEditPasswordSide
                        onSaveClick={handlePasswordSaveClick}
                        onReturnClick={handleProfileEditReturnClick}
                        onTextChanged={handleChangePasswordTextChanged}
                        currentPasswordErrorText={currentPasswordErrorText}
                        newPasswordErrorText={newPasswordErrorText}
                        repeatNewPasswordErrorText={repeatNewPasswordErrorText}
                        user={profile}
                    />
                )
            default:
                return null
        }
    }

    useEffect(() => {
        dispatch(changeEditedProfile(profile))
    }, [profile])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu />
            <div className="profile_content">
                <div className="profileBox">
                    <div className="profileBox_info">
                        <div className="profileBox_info_avatar">
                            <Avatar
                                name={`${profile.name} ${profile.surname}`}
                                size={150}
                                className="avatar"
                            />
                        </div>
                        <div className="profileBox_info_title">
                            {profile.name} {profile.surname}
                        </div>
                    </div>

                    <div className="profileBox_options">
                        <button type="button" onClick={() => setViewName('profile')}>
                            <CgProfile /> Profil
                        </button>
                        <button type="button" id="logout" onClick={() => handleLogoutButtonClick()}>
                            <FiLogOut /> Wyloguj
                        </button>
                    </div>
                </div>
                <div className="settingsBox"> {renderViewSwitch(viewName)} </div>
            </div>
            <Footer />
        </div>
    )
}

export default Profile
