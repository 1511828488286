import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Pill, Position, Tooltip } from 'evergreen-ui'

import renderDocumentType from '../../../utils/renderDocumentType'

import dateConvert from '../../../utils/formatDate'

import 'react-medium-image-zoom/dist/styles.css'
import '../../../styles/components/documentDetailDialog.scss'

const DocumentExpandedRow = ({ isExpanded, document }) => {
    const navigate = useNavigate()

    const handleSwitchView = (view) => {
        navigate(view)
    }

    const renderMeasurementItem = () => {
        return document?.measurement.map((elem) => {
            return (
                <Tooltip
                    content="Przejdź do odczytu"
                    showDelay={500}
                    position={Position.BOTTOM_LEFT}
                    key={elem}
                >
                    <Pill
                        className="box-pills__notification"
                        isInteractive
                        onClick={() => handleSwitchView(`/readings?reading_id=${elem}`)}
                    >
                        {elem}
                    </Pill>
                </Tooltip>
            )
        })
    }

    const renderMeasurementsList = () => {
        const elem = document?.measurement.join(',')
        return (
            <Tooltip
                content="Wszystkie odczyty"
                showDelay={500}
                position={Position.BOTTOM_LEFT}
                key={elem}
            >
                <Pill
                    className="box-pills__measurement-list"
                    isInteractive
                    onClick={() => handleSwitchView(`/readings?reading_ids=${elem}`)}
                >
                    Lista odczytów
                </Pill>
            </Tooltip>
        )
    }

    return (
        <div className={`more_info ${isExpanded ? 'expanded' : 'hidden'}`}>
            <div className="more_info_content">
                <div className="column column_fixed">
                    <div className="data_grid">
                        <span>Typ:</span>
                        <span>{renderDocumentType(document?.type)} </span>

                        <span>Data utworzenia:</span>
                        <span>{dateConvert(document?.createdAt)} </span>

                        <span>Numer dokumentu:</span>
                        <span>{document?.number} </span>

                        <span>Wystawia:</span>
                        <span>{document?.moderatorName} </span>

                        <span>Kwota faktury:</span>
                        <span>{document?.amount.toString().replace('.', ',')} zł </span>

                        <span>Odczyty:</span>
                        <div className="box-pills">{renderMeasurementItem()}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentExpandedRow
