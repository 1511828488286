const getPercentageValues = (x, y) => {
    return Math.ceil((x * 100) / y)
}

export const getCountCompanyUsers = (statistics) => {
    return (
        statistics.verifiedCompanyTenantMembers +
        statistics.rejectedCompanyTenantMembers +
        statistics.newCompanyTenantMembers
    )
}

export const getCountIndividualUsers = (statistics) => {
    return (
        statistics.verifiedIndividualTenantMembers +
        statistics.rejectedIndividualTenantMembers +
        statistics.newIndividualTenantMembers
    )
}

export const getCountAllUsers = (statistics) => {
    return (
        statistics.verifiedTenantMembers +
        statistics.rejectedTenantMembers +
        statistics.newTenantMembers
    )
}

export const getCompanyUserChartValues = (statistics) => {
    const usersCount = getCountCompanyUsers(statistics)

    return [
        {
            value: getPercentageValues(statistics.newCompanyTenantMembers, usersCount),
            color: 'blue'
        },
        {
            value: getPercentageValues(statistics.verifiedCompanyTenantMembers, usersCount),
            color: 'green'
        },
        {
            value: getPercentageValues(statistics.rejectedCompanyTenantMembers, usersCount),
            color: 'red'
        }
    ]
}

export const getIndividualUserChartValues = (statistics) => {
    const usersCount = getCountIndividualUsers(statistics)

    return [
        {
            value: getPercentageValues(statistics.newIndividualTenantMembers, usersCount),
            color: 'blue'
        },
        {
            value: getPercentageValues(statistics.verifiedIndividualTenantMembers, usersCount),
            color: 'green'
        },
        {
            value: getPercentageValues(statistics.rejectedIndividualTenantMembers, usersCount),
            color: 'red'
        }
    ]
}

export const getAllUserChartValues = (statistics) => {
    const AllUsersCount = getCountAllUsers(statistics)
    const CompanyUsersCount = getCountCompanyUsers(statistics)
    const IndividualUsersCount = getCountIndividualUsers(statistics)

    return [
        {
            value: getPercentageValues(CompanyUsersCount, AllUsersCount),
            color: 'green'
        },
        {
            value: getPercentageValues(IndividualUsersCount, AllUsersCount),
            color: 'red'
        }
    ]
}
