import { useSelector } from 'react-redux'

import { AiOutlineHistory } from 'react-icons/ai'

import 'react-medium-image-zoom/dist/styles.css'

import dateConvert from '../../../utils/formatDate'
import {
    renderBody,
    renderChannel,
    renderData,
    renderMeasurement,
    renderRecipient,
    renderTenantMember,
    renderTitle,
    renderUsers
} from '../../../utils/notificationLogs'

const HistoryExpandedRow = ({
    log,
    users,
    navigate,
    isExpanded,
    setIsDetailsShown,
    setLogDetailId
}) => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    const handleShowDetails = () => {
        setLogDetailId(log.pk)
        setIsDetailsShown(true)
    }

    return (
        <div className={`more_info ${isExpanded ? 'expanded' : 'hidden'}`}>
            <div className="more_info_content">
                <div className="column">
                    <div className="data_grid">
                        <p>Nadawca:</p>
                        <p>{log.createdBy === null ? 'System' : log.createdBy}</p>
                        {renderTitle(log.title)}
                        {renderBody(log.body)}
                        {renderData(log.data)}
                        {renderChannel(log.channel)}
                        {isModerator && renderUsers(users, navigate)}
                        <p>Data utworzenia:</p>
                        <p>{dateConvert(log.createdAt)}</p>
                        {renderMeasurement(log.measurement, navigate)}
                        {isModerator && renderRecipient(log.type, log.recipient)}
                    </div>
                </div>
                <div className="column">
                    <p id="title">Dostępne działania </p>
                    {log.type !== 'notification' ? (
                        <button type="button" className="button" onClick={handleShowDetails}>
                            <AiOutlineHistory size="18" /> Podgląd zmian
                        </button>
                    ) : (
                        <p>- Brak -</p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HistoryExpandedRow
