/* eslint-disable no-restricted-syntax */

import { xml2json } from 'xml-js'

const getNodeValue = (xml, name) => {
    try {
        return xml.getElementsByTagName(name)[0].childNodes[0].nodeValue
    } catch {
        return null
    }
}

const getElement = (xml, name) => {
    try {
        return xml.getElementsByTagName(name)[0]
    } catch {
        return null
    }
}

const getPodatnik = (XML) => {
    const recipient = getElement(XML, 'Podmiot1')

    const getAdres = () => {
        return {
            KodKraju: getNodeValue(recipient, 'KodKraju'),
            Ulica: getNodeValue(recipient, 'Ulica'),
            NrDomu: getNodeValue(recipient, 'NrDomu'),
            Miejscowosc: getNodeValue(recipient, 'Miejscowosc'),
            KodPocztowy: getNodeValue(recipient, 'KodPocztowy'),
            Poczta: getNodeValue(recipient, 'Poczta')
        }
    }

    const data = {
        NIP: getNodeValue(recipient, 'NIP'),
        PelnaNazwa: getNodeValue(recipient, 'PelnaNazwa'),
        ImiePierwsze: getNodeValue(recipient, 'ImiePierwsze'),
        Nazwisko: getNodeValue(recipient, 'Nazwisko'),
        Adres: getAdres()
    }

    return data
}

const getFormaPlatnosci = (text) => {
    switch (text) {
        case '1':
            return 'gotówka'
        case '2':
            return 'karta'
        case '3':
            return 'bon'
        case '4':
            return 'czek'
        case '5':
            return 'kredyt'
        case '6':
            return 'przelew'
        case '7':
            return 'mobilna'
        default:
            return 'inna'
    }
}

const getFA = (XML) => {
    const Fa = getElement(XML, 'Fa')

    const getWiersze = () => {
        const FaWiersze = getElement(Fa, 'FaWiersze')
        const rows = []

        if (!FaWiersze) return {}

        for (const child of FaWiersze.childNodes) {
            if (child.nodeName.startsWith('FaWiersz')) rows.push(child)
        }

        const data = rows.map((row) => {
            return {
                Nazwa: getNodeValue(row, 'P_7'),
                Miara: getNodeValue(row, 'P_8A'),
                Ilosc: getNodeValue(row, 'P_8B'),
                KwotaJednostkowa: getNodeValue(row, 'P_9A'),
                KwotaNetto: getNodeValue(row, 'P_11'),
                Podatek: getNodeValue(row, 'P_12')
            }
        })

        return data
    }

    const data = {
        KodWaluty: getNodeValue(Fa, 'KodWaluty'),
        DataWystawienia: getNodeValue(Fa, 'P_1'),
        NumerFaktury: getNodeValue(Fa, 'P_2'),
        KwotaNetto: getNodeValue(Fa, 'P_13_1'),
        KwotaPodatku: getNodeValue(Fa, 'P_14_1'),
        KwotaNaleznosci: getNodeValue(Fa, 'P_15'),
        RodzajFaktury: getNodeValue(Fa, 'RodzajFaktury'),
        LiczbaWierszy: getNodeValue(Fa, 'LiczbaWierszyFaktury'),
        WartoscWierszy: getNodeValue(Fa, 'WartoscWierszyFaktury1'),
        TerminyPlatnosci: getNodeValue(Fa, 'TerminPlatnosci'),
        Wiersze: getWiersze()
    }

    return data
}

const getPlatnosc = (XML) => {
    const Platnosc = getElement(XML, 'Platnosc')

    const FormaPlatnosci = getNodeValue(Platnosc, 'FormaPlatnosci')

    return {
        TerminPlatnosci: getNodeValue(Platnosc, 'TerminPlatnosci'),
        FormaPlatnosci: getFormaPlatnosci(FormaPlatnosci),
        NrRBpl: getNodeValue(Platnosc, 'NrRBPL'),
        NrRBzagr: getNodeValue(Platnosc, 'NrRBZagr'),
        SWIFT: getNodeValue(Platnosc, 'SWIFT'),
        NazwaBanku: getNodeValue(Platnosc, 'NazwaBanku')
    }
}

export const getXMLdata = (XML, asObject = false) => {
    const XMLparser = new DOMParser()
    const XMLdata = new TextDecoder().decode(XML.content)
    const XMLdoc = XMLparser.parseFromString(XMLdata, 'text/xml')

    if (asObject) return XMLdata

    return {
        Podatnik: getPodatnik(XMLdoc),
        Faktura: getFA(XMLdoc),
        Platnosc: getPlatnosc(XMLdoc),
        KodSystemowy: getNodeValue(XMLdoc, 'KodFormularza')
    }
}

export const getJSONdata = (XML) => {
    const XMLobj = getXMLdata(XML, true)
    return xml2json(XMLobj, { compact: true, spaces: 4 })
}

export default { getXMLdata, getJSONdata }
