import { createSlice } from '@reduxjs/toolkit'

export const UserSlice = createSlice({
    name: 'user',
    initialState: {
        users: {},
        nextPage: null,
        previousPage: null,
        count: 0
    },
    reducers: {
        addUsers: (state, action) => {
            state.users = action.payload.users
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        },
        verifyUser: (state, action) => {
            state.users[action.payload.pk] = action.payload
        }
    }
})

export const { addUsers, verifyUser } = UserSlice.actions
export default UserSlice.reducer
