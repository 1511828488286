import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { SelectMenu, Button, toaster, TextInput, Checkbox, Spinner } from 'evergreen-ui'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { AiOutlineCloudUpload, AiOutlineDelete } from 'react-icons/ai'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'
import { captureException } from '@sentry/react'

import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import FormSteps from '../../components/Notifications/FormSteps'

import useApi from '../../hooks/useApi'

import { toMeasurementInvoiceApi } from '../../utils/Adapters/MeasurementApiAdapter'
import dateConvert from '../../utils/formatDate'
import { getXMLdata, getJSONdata } from '../../utils/ksef'
import '../../styles/views/documents.scss'
import usePrompt from '../../hooks/useBackListener'
import Footer from '../../components/Footer'
import DataGridRow from '../../components/Documents/DataGridRow'
import SelectUserDialog from '../../components/Documents/SelectUserDialog'
import SelectMeasurementsDialog from '../../components/Documents/SelectMeasurementsDialog'

import { renderUserData } from '../../utils/renderUsers'

GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`

const AddDocument = () => {
    const { id } = useParams()
    const inputFile = useRef()

    const [isFileOver, setIsFileOver] = useState(false)
    const [isFileSet, setIsFileSet] = useState(false)
    const [attachments, setAttachments] = useState(null)
    const [selectedXML, setSelectedXML] = useState(null)
    const [XMLdata, setXMLdata] = useState([])
    const [JSONdata, setJSONdata] = useState(null)

    const [step, setStep] = useState(0)
    const [isUserDialogOpen, setIsUserDialogOpen] = useState(false)
    const [isMeasurementDialogOpen, setIsMeasurementDialogOpen] = useState(false)

    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedMeasurements, setSelectedMeasurements] = useState([])
    const [accountNumber, setAccountNumber] = useState('')
    const [selectedType, setSelectedType] = useState(null)
    const [selectedNumber, setSelectedNumber] = useState('')
    const [selectedAmount, setSelectedAmount] = useState('')
    const [isValidSelectedAmount, setisValidSelectedAmount] = useState(true)
    const [isValidAccountNumber, setisValidAccountNumber] = useState(true)
    const [isValidDocumentNumber, setisValidDocumentNumber] = useState(true)
    const [isFormDuringEdition, setIsFormDuringEdition] = useState(false)

    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const users = useSelector((state) => state.user.users)
    const measurements = useSelector((state) => state.measurement.measurements)

    usePrompt('Wprowadzone zmiany zostaną usunięte', isFormDuringEdition)

    const documentTypes = [
        {
            label: 'Faktura',
            value: 'invoice'
        },
        {
            label: 'Przypomnienie o płatności',
            value: 'payment_reminder'
        },
        {
            label: 'Inny',
            value: 'other'
        }
    ]

    const sendInvoiceCall = useApi({
        method: 'POST',
        apiKey,
        isFile: true,
        url: '/app/meters/moderator/document/',
        toApiAdapter: toMeasurementInvoiceApi
    })

    // set default form values
    useEffect(() => {
        if (XMLdata?.Faktura?.NumerFaktury) setSelectedNumber(XMLdata?.Faktura?.NumerFaktury)

        if (XMLdata?.Platnosc?.NrRBpl) setAccountNumber(XMLdata?.Platnosc?.NrRBpl)
        else if (XMLdata?.Platnosc?.NrRBzagr) setAccountNumber(XMLdata?.Platnosc?.NrRBzagr)

        if (XMLdata?.Faktura?.KwotaNaleznosci) setSelectedAmount(XMLdata?.Faktura?.KwotaNaleznosci)

        if (selectedXML) setSelectedType(documentTypes[0])
    }, [XMLdata])

    // validate step 3
    useEffect(() => {
        if (selectedNumber !== '' && selectedType && selectedMeasurements.length > 0) setStep(3)
        else if (step >= 3) setStep(2)
    }, [selectedNumber, selectedType, selectedMeasurements])

    // validate step 4
    useEffect(() => {
        const checkInputSelectedAmount = () => {
            const regex = /^\d+(\.\d{1,2})?$/
            return regex.test(selectedAmount)
        }

        const checkInputAccountNumber = () => {
            const regex = /^\d{26}$/
            return regex.test(accountNumber)
        }
        if (step >= 2 && selectedNumber === '') {
            setisValidDocumentNumber(false)
        } else setisValidDocumentNumber(true)
        if (step === 3 && checkInputSelectedAmount() && checkInputAccountNumber()) setStep(4)
        if (step >= 3 && (selectedAmount === '' || checkInputSelectedAmount() === false)) {
            setStep(3)
            setisValidSelectedAmount(false)
        } else setisValidSelectedAmount(true)
        if (step >= 3 && (accountNumber === '' || checkInputAccountNumber() === false)) {
            setStep(3)
            setisValidAccountNumber(false)
        } else setisValidAccountNumber(true)
    }, [step, accountNumber, selectedAmount, selectedNumber])

    const clearForm = () => {
        inputFile.current.value = ''
        setSelectedUser(null)
        setSelectedMeasurements([])
        setSelectedType(null)
        setSelectedXML(null)
        setSelectedAmount('')
        setSelectedNumber('')
        setAccountNumber('')

        setIsFileSet(false)
        setAttachments(null)
        setXMLdata(null)
        setStep(0)
    }

    const handleDragEnter = (e) => {
        setIsFileOver(true)
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragLeave = (e) => {
        setIsFileOver(false)
        e.preventDefault()
        e.stopPropagation()
    }

    const handleFileChange = async (event) => {
        setIsFileSet(true)

        // get XML files outta PDF
        const file = inputFile.current.files[0]
        const reader = new FileReader()
        reader.onload = async (e) => {
            try {
                const loadingTask = getDocument(reader.result)
                const pdf = await loadingTask.promise
                const pdfAttachments = await pdf.getAttachments()
                if (pdfAttachments) {
                    const xmls = Object.values(pdfAttachments).filter((el) =>
                        el.filename.endsWith('.xml')
                    )
                    setAttachments(xmls)
                } else {
                    setAttachments(null)
                    setStep(1)
                }
            } catch (error) {
                toaster.danger('Użyj innego pliku')
                captureException(error)
            }
        }
        reader.readAsArrayBuffer(file)
    }

    const handleAttSelect = (xml) => {
        setJSONdata(null)
        setXMLdata(null)
        setSelectedUser(null)
        setSelectedXML(xml)
        setXMLdata(getXMLdata(xml))
        setJSONdata(getJSONdata(xml))
        setStep(1)
        setAccountNumber('')
        setSelectedAmount('')
        setSelectedNumber('')
        setSelectedMeasurements([])
    }

    const handleDrop = (e) => {
        setIsFormDuringEdition(true)
        setIsFileOver(false)
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer && e.dataTransfer.files.length !== 0) {
            const { files } = e.dataTransfer
            const file = files[0]

            if (file.name.split('.').pop() !== 'pdf') {
                toaster.danger('Zły format pliku')
                inputFile.current.value = ''
                setIsFileSet(false)
                return
            }

            const dataTransfer = new DataTransfer()
            dataTransfer.items.add(file)

            inputFile.current.files = dataTransfer.files

            handleFileChange()
        }
    }

    const handleRemoveFileClick = () => {
        clearForm()
    }

    const handleUserSelect = (user) => {
        setSelectedUser(user)
        setStep(2)
    }

    const handeSendInvoiceClick = () => {
        sendInvoiceCall
            .mutateAsync({
                data: {
                    file: inputFile.current.files[0],
                    measurements: selectedMeasurements,
                    type: selectedType,
                    user: users[id === ':id' ? selectedUser : id],
                    number: selectedNumber,
                    amount: selectedAmount,
                    ksefData: JSONdata
                }
            })
            .then(() => {
                toaster.success('Pomyślnie dodano nowy dokument')
                clearForm()
            })
    }

    const handleRowSelected = (pk) => {
        if (selectedMeasurements.indexOf(pk) !== -1)
            setSelectedMeasurements((current) => current.filter((elem) => elem !== pk))
        else setSelectedMeasurements((current) => [...current, pk])
    }

    const handleUserDialogOpen = () => {
        setSelectedUser(null)
        setSelectedMeasurements([])
        setStep(1)
        setIsUserDialogOpen(true)
    }

    const renderAttachments = () => {
        if (!attachments) return null

        return (
            <>
                <hr width="90%" />
                <p>Znaleziono kilka plików *.xml, którego użyć?</p>
                <div id="checkbox_container">
                    {attachments?.map((el) => {
                        return (
                            <Checkbox
                                label={el.filename}
                                checked={selectedXML === el}
                                onChange={() => handleAttSelect(el)}
                                key={el.filename}
                            />
                        )
                    })}
                </div>
            </>
        )
    }

    const renderFileInput = () => {
        if (isFileOver) {
            return (
                <>
                    <AiOutlineCloudUpload size={30} />
                    <span>Upuść plik</span>
                </>
            )
        }

        if (isFileSet) {
            return (
                <>
                    <AiOutlineCloudUpload size={30} />
                    <span>{inputFile.current.files[0].name}</span>
                    <button type="button" className="clearButton" onClick={handleRemoveFileClick}>
                        <AiOutlineDelete size={20} /> Usuń plik
                    </button>
                    {renderAttachments()}
                </>
            )
        }

        return (
            <>
                <span>Upuść pliki tutaj</span>
                <span>- lub -</span>
                <button
                    type="button"
                    className="custom-file-upload"
                    onClick={() => inputFile.current.click()}
                >
                    <i className="fa fa-cloud-upload" />
                    Wybierz z dysku&nbsp;
                    <AiOutlineCloudUpload size={20} />
                </button>
            </>
        )
    }

    const getStepClassName = (stepValue) => {
        let className = 'file_details '

        if (step > stepValue) className += 'done '
        if (step === stepValue) className += 'current '

        return className
    }

    const getInputAccountNumber = (nameInp) => {
        let className = ''

        if (nameInp >= 3 && isValidAccountNumber === true) className = 'input_valid'
        if (nameInp >= 3 && isValidAccountNumber === false) className = 'input_invalid'

        return className
    }

    const getInputSelectedAmount = (nameInp) => {
        let className = ''

        if (nameInp >= 3 && isValidSelectedAmount === true) className = 'input_valid'
        if (nameInp >= 3 && isValidSelectedAmount === false) className = 'input_invalid'

        return className
    }

    const getInputDocumentNumber = (nameInp) => {
        let className = ''

        if (nameInp >= 2 && isValidDocumentNumber === true) className = 'input_valid'
        if (nameInp >= 2 && isValidDocumentNumber === false) className = 'input_invalid'

        return className
    }

    const getSelectedType = (labelOnly = false) => {
        if (selectedXML) {
            if (labelOnly) return documentTypes[0].label
            return documentTypes[0].value
        }

        if (labelOnly) {
            if (selectedType) return selectedType.label
            return 'Wybierz typ'
        }

        return null
    }

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="messages" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">
                        <span>Dokumenty</span>
                        <FormSteps
                            currentStep={step}
                            steps={[
                                'Wybór pliku',
                                'Wybór odbiorcy',
                                'Edycja danych',
                                'Płatność',
                                'Podsumowanie'
                            ]}
                        />
                        <Link to="/documents">
                            <button className="exit_button" type="button" name="meters">
                                <BiLeftArrowAlt size={16} /> Powrót
                            </button>
                        </Link>
                    </div>
                    <div className="title_route">
                        <Link to="/documents">Dokumenty</Link>
                        <span>&gt;</span>
                        <Link to="/documents/add">Nowy dokument</Link>
                    </div>
                </div>

                <div className="container container_mod">
                    <div className="add_invoice">
                        <div className="row">
                            <div
                                className={`file_box ${step > 0 ? 'done' : ''}`}
                                onDrop={(e) => handleDrop(e)}
                                onDragEnter={(e) => handleDragEnter(e)}
                                onDragLeave={(e) => handleDragLeave(e)}
                                onDragOver={(e) => {
                                    e.preventDefault()
                                }}
                            >
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept=".pdf"
                                    ref={inputFile}
                                    onChange={handleFileChange}
                                />
                                {renderFileInput()}
                            </div>
                            <div className={getStepClassName(1)}>
                                {step <= 0 && <div className="disabled" />}
                                <p className="tittle_margin">
                                    Odbiorca<span className="star">*</span>
                                </p>
                                <Button onClick={handleUserDialogOpen}>
                                    {selectedUser
                                        ? `${
                                              users[selectedUser]?.isCompany
                                                  ? users[selectedUser]?.companyName
                                                  : users[selectedUser]?.nameSurname
                                          }`
                                        : 'Wybierz Odbiorcę'}
                                </Button>
                                {id !== ':id' && !selectedUser ? (
                                    <>
                                        <p>Wybrany odbiorca:</p>
                                        {renderUserData(users[id], true)}
                                        {step === 1 && setStep(2)}
                                    </>
                                ) : (
                                    selectedUser && (
                                        <>
                                            <p>Wybrany odbiorca:</p>
                                            {renderUserData(users[selectedUser], true)}
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className={getStepClassName(2)}>
                                {step <= 1 && <div className="disabled" />}
                                <p className="tittle_margin">
                                    Typ dokumentu<span className="star">*</span>
                                </p>
                                <SelectMenu
                                    title="Wybierz typ"
                                    options={documentTypes}
                                    onSelect={(item) => setSelectedType(item)}
                                    selected={selectedType?.label}
                                    closeOnSelect
                                    hasFilter={false}
                                >
                                    <Button disabled={selectedXML}>{getSelectedType(true)}</Button>
                                </SelectMenu>
                                <p className="tittle_margin">
                                    Numer dokumentu<span className="star">*</span>
                                </p>
                                <TextInput
                                    className={getInputDocumentNumber(step)}
                                    onChange={(e) => setSelectedNumber(e.target.value)}
                                    width="auto"
                                    value={selectedNumber}
                                />
                                {!isValidDocumentNumber && (
                                    <span className="input_warrning">Brak nazwy dokumentu</span>
                                )}
                                <p className="tittle_margin">
                                    Odczyty<span className="star">*</span>
                                </p>
                                <Button onClick={() => setIsMeasurementDialogOpen(true)}>
                                    {selectedMeasurements.length > 0
                                        ? `Wybrano odczyty (${selectedMeasurements.length})`
                                        : 'Wybierz odczyty'}
                                </Button>
                                {selectedMeasurements.length > 0 && <p>Wybrane odczyty:</p>}
                                <div className="data_grid">
                                    {selectedMeasurements.map((measurement, index) => {
                                        return [
                                            <span key="key">{index + 1}:</span>,
                                            <span key="value">
                                                {dateConvert(measurements[measurement]?.createdAt)}
                                            </span>
                                        ]
                                    })}
                                </div>
                            </div>
                            <div className={getStepClassName(3)}>
                                {step <= 2 && <div className="disabled" />}
                                <p className="tittle_margin">
                                    Numer rachunku<span className="star">*</span>
                                </p>
                                <TextInput
                                    className={getInputAccountNumber(step)}
                                    onChange={(e) => setAccountNumber(e.target.value)}
                                    width="auto"
                                    value={accountNumber}
                                />
                                {step === 3 && !isValidAccountNumber && (
                                    <span className="input_warrning">
                                        Nieprawidłowy numer konta
                                    </span>
                                )}
                                <p className="tittle_margin">
                                    Kwota do zapłaty<span className="star">*</span>
                                </p>
                                <TextInput
                                    className={getInputSelectedAmount(step)}
                                    onChange={(e) => setSelectedAmount(e.target.value)}
                                    width="auto"
                                    value={selectedAmount}
                                />
                                {step === 3 && !isValidSelectedAmount && (
                                    <span className="input_warrning">Nieprawidłowa wartość</span>
                                )}
                                {XMLdata?.KodSystemowy === 'FA' && (
                                    <>
                                        <p>Dodatkowe informacje:</p>
                                        <div className="data_grid">
                                            <DataGridRow
                                                name="Forma płatności"
                                                data={XMLdata?.Platnosc?.FormaPlatnosci}
                                            />
                                            <DataGridRow
                                                name="Nazwa banku"
                                                data={XMLdata?.Platnosc?.NazwaBanku}
                                            />
                                            <DataGridRow
                                                name="SWIFT"
                                                data={XMLdata?.Platnosc?.SWIFT}
                                            />
                                            <DataGridRow
                                                name="Kod waluty"
                                                data={XMLdata?.Faktura?.KodWaluty}
                                            />
                                            <DataGridRow
                                                name="Termin płatności"
                                                data={XMLdata?.Faktura?.TerminyPlatnosci}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={getStepClassName(4)}>
                                {step <= 3 && <div className="disabled" />}
                                <p>Sprawdź wprowadzone dane przed wysłaniem formularza</p>
                                <button
                                    type="button"
                                    className="buttonComponent send_button"
                                    onClick={handeSendInvoiceClick}
                                    disabled={sendInvoiceCall.isLoading}
                                >
                                    {sendInvoiceCall.isLoading ? <Spinner size={30} /> : 'Wyślij'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <SelectUserDialog
                isUserDialogOpen={isUserDialogOpen}
                setIsUserDialogOpen={setIsUserDialogOpen}
                XMLdata={XMLdata}
                onSelect={handleUserSelect}
            />
            <SelectMeasurementsDialog
                isOpen={isMeasurementDialogOpen}
                setIsOpen={setIsMeasurementDialogOpen}
                user={id === ':id' ? selectedUser : id}
                onRowSelected={handleRowSelected}
                selectedMeasurements={selectedMeasurements}
            />
        </div>
    )
}

export default AddDocument
