const RightButtonBox = ({
    handlePrevStepButtonClick,
    handleNextStepButtonClick,
    disabled,
    currentStep
}) => {
    return (
        <div className="button_box">
            <button type="button" name="1" onClick={handlePrevStepButtonClick} disabled={disabled}>
                Cofnij
            </button>
            <button
                type="button"
                name="2"
                className={`${currentStep === 1 ? 'highlightedButton' : ''}`}
                onClick={handleNextStepButtonClick}
                disabled={disabled}
            >
                Dalej
            </button>
        </div>
    )
}
export default RightButtonBox
