import { AiFillDelete } from 'react-icons/ai'

const SelectedElements = ({ selected, elements, setSelected, title, isDisabled }) => {
    const filterSelectedUsers = () => {
        if (Array.isArray(selected)) {
            return Object.entries(elements)
                .filter(([key, val]) => {
                    const t = selected.filter((element) => key === element)
                    return t.length > 0
                })
                .map((el) => el[1])
        }
        return selected
    }

    const removeUserFromSelectedList = (selectedId) => {
        const selectedItems = selected.filter((i) => parseInt(i, 10) !== selectedId)
        setSelected(selectedItems)
    }

    const renderElements = () => {
        if (Array.isArray(selected)) {
            return filterSelectedUsers().map((user) => {
                return (
                    <button
                        type="button"
                        key={user?.pk}
                        onClick={() => removeUserFromSelectedList(user?.pk)}
                        disabled={isDisabled}
                    >
                        {user?.isCompany ? user?.companyName : user?.nameSurname}
                        <AiFillDelete className="icon" />
                    </button>
                )
            })
        }
        return <div className="user">{selected}</div>
    }

    return (
        <div className="selectedUsers">
            <div className="titlebox">
                <div className="title_bottom">{title}</div>
            </div>
            <div className="selectedUsers_box">{renderElements()}</div>
        </div>
    )
}
export default SelectedElements
