import { InlineAlert } from 'evergreen-ui'

const RadioButtonAlert = ({ isRadioButtonError }) => {
    if (isRadioButtonError) {
        return (
            <InlineAlert intent="danger" className="alert_inline">
                Najpierw wybierz odbiorców!
            </InlineAlert>
        )
    }
    return <div className="no_alert_div" />
}
export default RadioButtonAlert
