import { createSlice } from '@reduxjs/toolkit'

export const NotificationLogSlice = createSlice({
    name: 'notificationLog',
    initialState: {
        notificationLogs: {},
        count: 0,
        nextPage: null,
        previousPage: null
    },
    reducers: {
        addNotificationLogs: (state, action) => {
            state.notificationLogs = action.payload.logs
            state.count = action.payload.count
            state.nextPage = action.payload.nextPage
            state.previousPage = action.payload.previousPage
        }
    }
})

export const { addNotificationLogs } = NotificationLogSlice.actions
export default NotificationLogSlice.reducer
