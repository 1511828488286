import { Link } from 'react-router-dom'
import '../styles/components/footer.scss'
import logo from '../assets/logo_ngcode.png'
import { store } from '../redux/store'

const Footer = (glass = false) => {
    const isLogin = store.getState()?.profile?.loggedIn

    return (
        <div className={`footer ${glass ? 'glass' : ''}`}>
            <div className="company_group">
                <img src={logo} alt="NGCode logo" />
                Copyright 2023 NG Code
            </div>
            <div className="href_group">
                {isLogin && (
                    <>
                        <Link to="/contact">
                            <span>Kontakt i wsparcie techniczne</span>
                        </Link>
                        <Link to="/report">
                            <span>Zgłoś błąd</span>
                        </Link>
                    </>
                )}
                <Link to="/faq">
                    <span>FAQ</span>
                </Link>
                <Link to="/regulations">
                    <span>Regulamin</span>
                </Link>
                <Link to="/privacy">
                    <span>Polityka prywatności</span>
                </Link>
            </div>
        </div>
    )
}

export default Footer
