import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Routes, Route } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import PrivateRoute from './ProtectedRoute'

import { setIsMobile } from './redux/slices/profileSlice'

import Login from './views/Login.view'
import Home from './views/Home/Home.view'
import Users from './views/Users/Users.view'
import Meters from './views/Meters/Meters.view'
import Documents from './views/Documents/Documents.view'
import AddDocument from './views/Documents/AddDocument.view'
import Profile from './views/Profile.view'
import Measurements from './views/Measurements/Measurement.view'
import Notifications from './views/Notifications/Notifications.view'
import SendNotification from './views/Notifications/SendNotification.view'
import PageNotFound from './views/error/PageNotFound.view'
import Contact from './views/Contact'
import Report from './views/Report'
import Faq from './views/Faq.view'
import PrivacyPolicy from './views/PrivacyPolicy.view'
import Regulations from './views/Regulations.view'
import History from './views/History/History.view'
import { setAppVersion } from './redux/slices/applicationSlice'
import appInfo from '../package.json'

const App = () => {
    const currentAppVersion = appInfo.version

    const versionAppInRedux = useSelector((state) => state.application?.appVersion)
    const dispatch = useDispatch()

    const [mobileFlag, setMobileFlag] = useState(false)

    const handleResize = () => {
        setMobileFlag(window.innerWidth < 600)
    }

    const handleReduxUpdate = () => {
        localStorage.removeItem('persist:root')
        window.location.reload(false)
    }

    useEffect(() => {
        dispatch(setIsMobile(window.innerWidth < 600))
    }, [mobileFlag])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (versionAppInRedux === null) dispatch(setAppVersion(currentAppVersion.toString()))
        else if (currentAppVersion !== versionAppInRedux) handleReduxUpdate()
    }, [])

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/regulations" element={<Regulations />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/meters" element={<Meters />} />
                    <Route path="/documents" element={<Documents />} />
                    <Route path="/documents/add/:id" element={<AddDocument />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/readings" element={<Measurements />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/notifications/send" element={<SendNotification />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/report" element={<Report />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/history" element={<History />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
        </GoogleOAuthProvider>
    )
}

export default App
