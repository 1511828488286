import { useSelector } from 'react-redux'

import MetersModerator from './Meters.moderator.view'
import MetersUser from './Meters.user.view'

const Meters = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? <MetersModerator /> : <MetersUser />
}

export default Meters
