import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Avatar, Pane, Dialog, Popover } from 'evergreen-ui'
import { IoIosArrowDown, IoMdNotificationsOutline } from 'react-icons/io'
import { FiMenu } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import { addNotificationLogs } from '../../redux/slices/notificationLogSlice'
import fromNotificationLogApi from '../../utils/Adapters/NotificationLogApiAdapter'
import dateConvert from '../../utils/formatDate'

import { logoutUser } from '../../redux/slices/profileSlice'

import PopupMenu from './PopupMenu'
import UserNotification from '../Notifications/HomeUserView/UserNotification'

import eventEmitter from '../../utils/eventEmitter'

import logo from '../../assets/logo-bezlicznik.svg'

import '../../styles/components/topbar.scss'

const RenderNotifications = ({ notification, setIsShownNotification, setDetailNotification }) => {
    if (Object.values(notification).length === 0) {
        return (
            <div className="topBar_notification noHover">
                <span className="topBar_notification_noresult">Brak powiadomień.</span>
            </div>
        )
    }
    return Object.entries(notification)
        .reverse()
        .map(([key, value]) => {
            return (
                <UserNotification
                    notificationRow={value}
                    key={key}
                    setIsShownNotification={setIsShownNotification}
                    setDetailNotification={setDetailNotification}
                />
            )
        })
}

const TopBar = () => {
    const dispatch = useDispatch()

    const user = useSelector((state) => state.profile.profile)
    const notification = useSelector((state) => state.notificationLog.notificationLogs)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isShown, setIsShown] = useState(false)
    const [isShownNotification, setIsShownNotification] = useState(false)
    const [isDetailNotification, setDetailNotification] = useState(false)

    useApi({
        enabled: !isModerator,
        url: `/app/notifications/notification/?limit=5`,
        method: 'GET',
        apiKey,
        fromApiAdapter: fromNotificationLogApi,
        queryName: ['notification'],
        onSuccess: (data) => dispatch(addNotificationLogs(data)),
        keepPreviousData: true
    })

    useEffect(() => {
        eventEmitter.on('showLogout', () => setIsShown(true))

        return () => {
            eventEmitter.off('showLogout')
        }
    }, [])

    const handleMenuClick = () => {
        setIsMenuOpen((current) => !current)

        if (isMenuOpen) eventEmitter.emit('TopBarCloseMenu')
        else eventEmitter.emit('TopBarOpenMenu')
    }

    return (
        <>
            <div className="topBar">
                <div className="topBar_menu_mobile">
                    <button type="button" onClick={handleMenuClick}>
                        <FiMenu size="30" />
                    </button>
                </div>
                <div className="topBar_logo">
                    <Link to="/home">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                <div className="topBar_account">
                    {!isModerator && (
                        <Popover
                            content={
                                <Pane
                                    minWidth={240}
                                    maxHeight={500}
                                    overflowY="scroll"
                                    display="flex"
                                    flexDirection="column"
                                    paddingTop={20}
                                >
                                    <RenderNotifications
                                        notification={notification}
                                        setIsShownNotification={setIsShownNotification}
                                        setDetailNotification={setDetailNotification}
                                    />
                                </Pane>
                            }
                        >
                            <button type="button" className="buttonNotification">
                                <IoMdNotificationsOutline size="40" />
                                <span
                                    className={
                                        Object.values(notification).length !== 0
                                            ? 'newnotification'
                                            : ''
                                    }
                                >
                                    {Object.values(notification).length !== 0
                                        ? Object.values(notification).length
                                        : ''}
                                </span>
                            </button>
                        </Popover>
                    )}
                    <Link to="/profile">
                        <Avatar
                            name={`${user.name} ${user.surname}`}
                            size={50}
                            className="avatar"
                        />
                    </Link>
                    <span className="avatar_tittle">
                        {user.name} {user.surname}
                    </span>
                    <PopupMenu>
                        <button type="button">
                            <IoIosArrowDown size="20" />
                        </button>
                    </PopupMenu>
                </div>
                <div className="topBar_account_mobile">
                    <PopupMenu>
                        <button type="button">
                            <Avatar
                                name={`${user.name} ${user.surname}`}
                                size={50}
                                className="avatar"
                            />
                        </button>
                    </PopupMenu>
                </div>
            </div>
            <Pane>
                <Dialog
                    isShown={isShown}
                    title="Czy na pewno chcesz się wylogować?"
                    intent="danger"
                    onCloseComplete={() => setIsShown(false)}
                    onConfirm={() => dispatch(logoutUser())}
                    minHeightContent={0}
                    confirmLabel="Wyloguj"
                    cancelLabel="Anuluj"
                >
                    <span />
                </Dialog>
            </Pane>
            <Pane>
                <Dialog
                    isShown={isShownNotification}
                    title={isDetailNotification.title}
                    onCloseComplete={() => setIsShownNotification(false)}
                    minHeightContent={100}
                    hasFooter={false}
                    width={700}
                >
                    <div className="topBar_notification_dilog">
                        <p className="topBar_notification_text">{isDetailNotification.body}</p>
                        <span>{dateConvert(isDetailNotification.createdAt)}</span>
                    </div>
                </Dialog>
            </Pane>
        </>
    )
}

export default TopBar
