/* eslint-disable camelcase */
class Statistic {
    constructor(data = {}) {
        const {
            coldWaterMeters,
            gardenWaterMeters,
            hotWaterMeters,
            newCompanyTenantMembers,
            newIndividualTenantMembers,
            newMeasurements,
            newTenantMembers,
            rejectedCompanyTenantMembers,
            rejectedIndividualTenantMembers,
            rejectedMeasurements,
            rejectedTenantMembers,
            verifiedCompanyTenantMembers,
            verifiedIndividualTenantMembers,
            verifiedMeasurements,
            verifiedTenantMembers
        } = data

        this.coldWaterMeters = coldWaterMeters || 0
        this.gardenWaterMeters = gardenWaterMeters || 0
        this.hotWaterMeters = hotWaterMeters || 0
        this.newCompanyTenantMembers = newCompanyTenantMembers || 0
        this.newIndividualTenantMembers = newIndividualTenantMembers || 0
        this.newMeasurements = newMeasurements || 0
        this.newTenantMembers = newTenantMembers || 0
        this.rejectedCompanyTenantMembers = rejectedCompanyTenantMembers || 0
        this.rejectedIndividualTenantMembers = rejectedIndividualTenantMembers || 0
        this.rejectedMeasurements = rejectedMeasurements || 0
        this.rejectedTenantMembers = rejectedTenantMembers || 0
        this.verifiedCompanyTenantMembers = verifiedCompanyTenantMembers || 0
        this.verifiedIndividualTenantMembers = verifiedIndividualTenantMembers || 0
        this.verifiedMeasurements = verifiedMeasurements || 0
        this.verifiedTenantMembers = verifiedTenantMembers || 0
    }
}

export default Statistic
