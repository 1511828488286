import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Table, Tooltip } from 'evergreen-ui'
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'

import HistoryExpandedRow from './HistoryExpandedRow'

import dateConvert from '../../../utils/formatDate'
import { renderChannel } from '../../../utils/notificationLogs'

const HistoryDetailRow = ({ log, isExpanded, setIsExpanded }) => {
    const renderTitle = (title) => {
        if (log.type === 'notification') return title
        if (log.type === 'tenant_member') return 'Modyfikacja odbiorcy'
        if (log.type === 'measurement') return 'Zmiana stanu odczytu'
        return null
    }

    const renderTypes = (type) => {
        if (type === 'notification') return 'Powiadomienie'
        if (type === 'tenant_member') return 'Modyfikacja odbiorcy'
        if (type === 'measurement') return 'Modyfikacja odczytu'
        return null
    }

    return (
        <Table.Row className={`row ${isExpanded ? 'in_color' : ''}`}>
            <Table.TextCell>{renderTitle(log.title)}</Table.TextCell>
            <Table.TextCell>{renderChannel(log.channel, true, true)}</Table.TextCell>
            <Table.TextCell>{renderTypes(log.type)}</Table.TextCell>
            <Table.TextCell flexBasis="180px" flexShrink={0} flexGrow={0}>
                <span> {dateConvert(log.createdAt)}</span>
            </Table.TextCell>
            <Table.TextCell className="gap" />
            <Table.TextCell className="alone_row">
                <Tooltip content="Szczegóły" showDelay={500} key={!isExpanded ? 0 : 1}>
                    <button
                        type="button"
                        onClick={() => setIsExpanded((current) => !current)}
                        className="clickable"
                    >
                        {isExpanded ? (
                            <BiUpArrowAlt size="20" className="icon_white" />
                        ) : (
                            <BiDownArrowAlt size="20" />
                        )}
                    </button>
                </Tooltip>
            </Table.TextCell>
        </Table.Row>
    )
}

const HistoryRow = ({ log, setIsDetailsShown, setLogDetailId }) => {
    const navigate = useNavigate()

    const [isExpanded, setIsExpanded] = useState(false)
    const [notificationUsers, setNotificationUsers] = useState(log.users)

    const documents = useSelector((state) => state.document.documents)
    const measurements = useSelector((state) => state.measurement.measurements)
    const meters = useSelector((state) => state.meter.meters)
    const users = useSelector((state) => state.user.users)

    const handleExpandChange = () => {
        setIsExpanded((current) => !current)
    }

    const [urlParams, setUrlParams] = useSearchParams()

    useEffect(() => {
        const userParam = parseInt(urlParams.get('meter_id'), 10)
        if (!Number.isNaN(userParam)) {
            if (userParam === log.pk) setIsExpanded(true)
        }

        if (log.document) {
            const document = documents[log.document]
            const measurement = measurements[documents[document?.id]?.measurement[0]]
            const meter = meters[measurement?.meter]
            setNotificationUsers([users[meter?.tenantMember]?.pk])
        } else if (log.measurement) {
            const measurement = measurements[log.measurement]
            const meter = meters[measurement?.meter]
            setNotificationUsers([users[meter?.tenantMember]?.pk])
        } else if (log.tenantMember) {
            setNotificationUsers([users[log.tenantMember]?.pk])
        }
    }, [users, measurements, meters])

    return (
        <>
            <HistoryDetailRow
                log={log}
                isExpanded={isExpanded}
                setIsExpanded={handleExpandChange}
            />
            <HistoryExpandedRow
                log={log}
                users={notificationUsers}
                navigate={navigate}
                isExpanded={isExpanded}
                setIsDetailsShown={setIsDetailsShown}
                setLogDetailId={setLogDetailId}
            />
        </>
    )
}

export default HistoryRow
