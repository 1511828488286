import { createSlice } from '@reduxjs/toolkit'

export const ApplicationSlice = createSlice({
    name: 'application',
    initialState: {
        appVersion: null
    },
    reducers: {
        setAppVersion: (state, action) => {
            state.appVersion = action.payload
        }
    }
})

export const { setAppVersion } = ApplicationSlice.actions
export default ApplicationSlice.reducer
