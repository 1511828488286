import { Combobox, InlineAlert, Textarea, TextInput } from 'evergreen-ui'
import { useEffect, useState } from 'react'

const NotificationsEdit = ({
    topics,
    handleTopicChanged,
    notifyTitle,
    notifyContent,
    setNotifyTitle,
    setNotifyContent,
    isNotifyAlert,
    isBoxDisabled = false,
    isError = false
}) => {
    const [isTitleError, setIsTitleError] = useState(false)
    const [isContentError, setIsContentError] = useState(false)

    const handleTitleOnBlur = (e) => {
        if (e.target.value.length === 0) setIsTitleError(true)
    }

    const handleContentOnBlur = (e) => {
        if (e.target.value.length === 0) setIsContentError(true)
    }

    useEffect(() => {
        if (isError) {
            setIsContentError(true)
            setIsTitleError(true)
        }
    }, [isError])

    useEffect(() => {
        if (notifyTitle.length > 0) setIsTitleError(false)
        if (notifyContent.length > 0) setIsContentError(false)
    }, [notifyTitle, notifyContent])

    return (
        <>
            <div className="titlebox">
                <div className="title">Edycja powiadomienia</div>
            </div>
            <div className="notification_body">
                <Combobox
                    openOnFocus
                    width="100%"
                    height={40}
                    items={topics.map((el) => el.key)}
                    onChange={(selected) => handleTopicChanged(selected)}
                    placeholder="Wybierz gotowy szablon powiadomienia"
                    disabled={isBoxDisabled}
                />
                {isTitleError ? (
                    <div className="notification_body_alert">To pole jest wymagane</div>
                ) : (
                    <div className="notification_body_no_alert" />
                )}
                <TextInput
                    required
                    placeholder="Wpisz tytuł"
                    className={`notification_body_title${isTitleError ? '_error' : ''}`}
                    onChange={(e) => {
                        setNotifyTitle(e.target.value)
                        setIsTitleError(false)
                    }}
                    onBlur={handleTitleOnBlur}
                    value={notifyTitle}
                    disabled={isBoxDisabled}
                />
                {isContentError ? (
                    <div className="notification_body_alert">To pole jest wymagane</div>
                ) : (
                    <div className="notification_body_no_alert" />
                )}

                <Textarea
                    placeholder="Wpisz treść"
                    className={`notification_body_content${isContentError ? '_error' : ''}`}
                    onChange={(e) => {
                        setNotifyContent(e.target.value)
                        setIsContentError(false)
                    }}
                    onBlur={handleContentOnBlur}
                    disabled={isBoxDisabled}
                    value={notifyContent}
                />
                {isNotifyAlert ? (
                    <InlineAlert intent="warning" className="alert_inline">
                        Musi być wybrany chociaż jeden typ powiadomnienia
                    </InlineAlert>
                ) : (
                    <div className="no_alert_div" />
                )}
            </div>
        </>
    )
}
export default NotificationsEdit
