/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react'

import { MapInteractionCSS } from 'react-map-interaction'
import { Dialog, Pill } from 'evergreen-ui'

import { getState } from '../utils/renderState'

import '../styles/components/button.scss'
import '../styles/components/dialog.scss'

export const UserDataVerificationDialog = ({
    isShown,
    setIsShown,
    selectedUser,
    setIsVerifyUserPopup,
    setIsDeclineUserPopup,
    setSendingData
}) => {
    const [user, setUser] = useState(selectedUser)
    const [error, setError] = useState({})

    const handleFormOnChange = (e) => {
        const { name, value } = e.target
        setUser((state) => {
            return {
                ...state,
                [name]: value
            }
        })
        setError((state) => {
            return {
                ...state,
                [name]: false
            }
        })
    }

    const handleInputOnBlur = (e) => {
        const { name } = e.target
        if (user[name].length === 0) {
            setError((state) => {
                return {
                    ...state,
                    [name]: true
                }
            })
        }
    }

    const setErrorFunc = (name) => {
        setError((state) => {
            return {
                ...state,
                [name]: true
            }
        })
    }

    const handleInputOnBlurNew = (e, length) => {
        const { name } = e.target
        if (!user[name].length || user[name].length > length) {
            setErrorFunc(name)
        }
    }

    const handleDialogClose = () => {
        setIsShown(false)
        setError({})
    }

    const isDataValid = () => {
        if (
            user.isCompany &&
            (user.lastInvoiceNumber.length === 0 ||
                user.clientNumber.length === 0 ||
                user.clientNumber.length > 50 ||
                user.companyName.length === 0 ||
                user.nip.length === 0 ||
                user.nip.length > 10)
        )
            return false

        if (
            !user.isCompany &&
            (user.lastInvoiceNumber.length === 0 ||
                user.clientNumber.length === 0 ||
                user.clientNumber.length > 50 ||
                user.city.length === 0 ||
                user.city.length > 30 ||
                user.street.length === 0 ||
                user.street.length > 50 ||
                user.zipCode.length === 0 ||
                user.zipCode.length > 6 ||
                user.nameSurname.length === 0)
        )
            return false

        return true
    }

    const setUserData = () => {
        if (user.isCompany) {
            setSendingData({
                lastInvoiceNumber: user.lastInvoiceNumber,
                clientNumber: user.clientNumber,
                companyName: user.companyName,
                nip: user.nip
            })
        } else {
            setSendingData({
                lastInvoiceNumber: user.lastInvoiceNumber,
                clientNumber: user.clientNumber,
                city: user.city,
                street: user.street,
                zipCode: user.zipCode,
                nameSurname: user.nameSurname
            })
        }
    }

    const handleVerifyClick = () => {
        if (isDataValid()) {
            setUserData()
            setIsVerifyUserPopup((current) => !current)
        } else {
            setError(() => {
                return {
                    lastInvoiceNumber: user.lastInvoiceNumber.length === 0,
                    clientNumber: user.clientNumber.length === 0 || user.clientNumber.length > 50,
                    city: user.city.length === 0 || user.city.length > 30,
                    street: user.street.length === 0 || user.street.length > 50,
                    zipCode: user.zipCode.length === 0 || user.zipCode.length > 6,
                    nameSurname: user.nameSurname.length === 0,
                    nip: user.nip.length === 0 || user.nip.length > 10,
                    companyName: user.companyName.length === 0
                }
            })
        }
    }

    const handleRejectClick = () => {
        setIsDeclineUserPopup((current) => !current)
    }

    const customDialogFooter = () => {
        return (
            <div className="dialogCustomFooter">
                <button type="button" className="cancelButton" onClick={handleDialogClose}>
                    Anuluj
                </button>
                <button
                    type="button"
                    className="rejectButton"
                    onClick={handleRejectClick}
                    disabled={user.state === 'rejected'}
                >
                    Odrzuć
                </button>
                <button type="button" className="button" onClick={handleVerifyClick}>
                    Weryfikuj
                </button>
            </div>
        )
    }

    const getClassName = (state) => {
        switch (state) {
            case 'verified':
                return 'pill_green'
            case 'rejected':
                return 'pill_red'
            case 'new':
                return 'pill_blue'
            default:
                return ''
        }
    }

    useEffect(() => {
        setUser(selectedUser)
    }, [selectedUser])

    return (
        <Dialog
            isShown={isShown}
            title="Sprawdź dane przesłane przez użytkownika"
            onCloseComplete={handleDialogClose}
            width="max-content"
            topOffset={60}
            footer={customDialogFooter}
        >
            <div className="user_information_box">
                <div className="picture_box">
                    <MapInteractionCSS minScale={0.5}>
                        <div className="image_container">
                            <img src={selectedUser.lastInvoiceImage} alt="meter" />
                        </div>
                    </MapInteractionCSS>
                    <span>Użyj kółka myszy, aby przybliżyć zdjęcie</span>
                </div>
                <div className="user_verification_column">
                    <div className="user_data_box">
                        <span>Stan weryfikacji:</span>
                        <Pill className={`pill ${getClassName(user.state)}`}>
                            {getState(user.state)}
                        </Pill>
                        <span>Typ odbiorcy:</span>
                        {user?.isCompany ? <span>Instytucjonalny</span> : <span>Indywidualny</span>}
                    </div>
                    <div className="data_box">
                        <span>Uzupełnij dane rozliczeniowe:</span>
                        {user.isCompany ? (
                            <>
                                <label> Nazwa firmy: * </label>
                                <input
                                    type="text"
                                    value={user?.companyName}
                                    name="companyName"
                                    onChange={handleFormOnChange}
                                    onBlur={handleInputOnBlur}
                                    className={error.companyName ? 'errorInput' : ''}
                                    placeholder={error.companyName ? 'To pole jest wymagane' : ''}
                                />

                                <label> NIP: * </label>
                                <input
                                    type="text"
                                    value={user?.nip}
                                    name="nip"
                                    onChange={handleFormOnChange}
                                    onBlur={(e) => handleInputOnBlurNew(e, 10)}
                                    className={error.nip ? 'errorInput' : ''}
                                    placeholder={error.nip ? 'To pole jest wymagane' : ''}
                                />
                            </>
                        ) : (
                            <>
                                <label> Imię i nazwisko: * </label>
                                <input
                                    type="text"
                                    value={user?.nameSurname}
                                    name="nameSurname"
                                    onChange={handleFormOnChange}
                                    onBlur={handleInputOnBlur}
                                    className={error.nameSurname ? 'errorInput' : ''}
                                    placeholder={error.nameSurname ? 'To pole jest wymagane' : ''}
                                />

                                <label> Ulica, numer domu/mieszkania: * </label>
                                <input
                                    type="text"
                                    value={user?.street}
                                    name="street"
                                    onChange={handleFormOnChange}
                                    onBlur={(e) => handleInputOnBlurNew(e, 50)}
                                    className={error.street ? 'errorInput' : ''}
                                    placeholder={error.street ? 'To pole jest wymagane' : ''}
                                />

                                <label> Kod pocztowy: * </label>
                                <input
                                    type="text"
                                    value={user?.zipCode}
                                    name="zipCode"
                                    onChange={handleFormOnChange}
                                    onBlur={(e) => handleInputOnBlurNew(e, 6)}
                                    className={error.zipCode ? 'errorInput' : ''}
                                    placeholder={error.zipCode ? 'To pole jest wymagane' : ''}
                                />

                                <label> Miasto: * </label>
                                <input
                                    type="text"
                                    value={user?.city}
                                    name="city"
                                    onChange={handleFormOnChange}
                                    onBlur={(e) => handleInputOnBlurNew(e, 30)}
                                    className={error.city ? 'errorInput' : ''}
                                    placeholder={error.city ? 'To pole jest wymagane' : ''}
                                />
                            </>
                        )}

                        <label> Numer ostatniej faktury: * </label>
                        <input
                            type="text"
                            value={user?.lastInvoiceNumber}
                            name="lastInvoiceNumber"
                            onChange={handleFormOnChange}
                            onBlur={handleInputOnBlur}
                            className={error.lastInvoiceNumber ? 'errorInput' : ''}
                            placeholder={error.lastInvoiceNumber ? 'To pole jest wymagane' : ''}
                        />

                        <label> Numer klienta: * </label>
                        <input
                            type="text"
                            value={user?.clientNumber}
                            name="clientNumber"
                            onChange={handleFormOnChange}
                            onBlur={(e) => handleInputOnBlurNew(e, 50)}
                            className={error.clientNumber ? 'errorInput' : ''}
                            placeholder={error.clientNumber ? 'To pole jest wymagane' : ''}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default UserDataVerificationDialog
