import { encode } from 'js-base64'
import Fernet from 'fernet-web'

export default async function fernetDecrypt(data, userToken) {
    const userSecret = encode(userToken.slice(0, 32))
    const fernet = await Fernet.create(userSecret)

    data.p24_pos_id = await fernet.decrypt(data?.p24_pos_id)
    data.p24_crc = await fernet.decrypt(data?.p24_crc)
    data.p24_secret_id = await fernet.decrypt(data?.p24_secret_id)

    return data
}
