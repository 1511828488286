import Measurement from '../../redux/models/Measurement'

export const fromMeasurementApi = (apiObj) => {
    const responseData = apiObj.data

    const parsedData = Object.assign(
        {},
        ...responseData.results.map((measurement, index) => {
            return {
                [measurement.pk]: new Measurement({ ...measurement, index })
            }
        })
    )

    return {
        measurements: parsedData,
        count: responseData.count,
        nextPage: responseData.next,
        previousPage: responseData.previous
    }
}

export const toMeasurementApi = (uiObj) => {
    return {
        meter: uiObj.meter,
        value: uiObj.value,
        image: uiObj.image,
        state: uiObj.state
    }
}

export const toMeasurementInvoiceApi = (uiObj) => {
    const formData = new FormData()
    const data = {
        user: uiObj.user.user,
        type: uiObj.type.value,
        file: uiObj.file,
        number: uiObj.number,
        amount: uiObj.amount.replace(',', '.'),
        ksef_data: uiObj.ksefData
    }

    Object.entries(data).forEach(([key, value]) => formData.append(key, value))
    uiObj.measurements.forEach((el) => formData.append('measurements', el))

    return formData
}

export const toVerifyMeasurementeApi = (uiObj) => {
    return {
        state: uiObj.state,
        value: uiObj.value
    }
}

export const toRejectMeasurementeApi = (uiObj) => {
    return {
        state: uiObj.state,
        reason: uiObj.reason
    }
}

export const fromVerifyMeasurementApi = (apiObj, index) => {
    return new Measurement({ ...apiObj.data, index })
}

export const toBulkVerifyMeasurementApi = (uiObj) => {
    return {
        ids: uiObj.ids,
        state: uiObj.state
    }
}

export const fromBulkVerifyMeasurementApi = (apiObj) => {
    return apiObj.data
}

export const fromMeasurementLogApi = (apiObj) => {
    return apiObj.data
}
