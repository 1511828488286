import { Table } from 'evergreen-ui'

import renderMeterIconType from '../../../utils/renderMeterIconType'
import dateConvert from '../../../utils/formatDate'

const MeterUserRow = ({ row, selectedRow, onRowSelect }) => {
    const size = 16

    const onlyYear = () => {
        return dateConvert(row.createdAt).split(',')[0]
    }

    const handleSelectedClick = (e) => {
        if (selectedRow !== row.pk) {
            onRowSelect(row.pk)
        }
    }

    return (
        <Table.Row
            type="button"
            className={`row cursor-pointer ${selectedRow === row.pk ? 'selected' : ''}`}
            onClick={(e) => handleSelectedClick(e)}
        >
            <Table.TextCell>{row.name}</Table.TextCell>
            <Table.TextCell className="meter_type">
                <span className="meter_type">{renderMeterIconType(row, size)}</span>
            </Table.TextCell>
            <Table.TextCell>{row.serialNumber ? row.serialNumber : '-'}</Table.TextCell>
            <Table.TextCell>{onlyYear()}</Table.TextCell>
        </Table.Row>
    )
}

export default MeterUserRow
