const toNotificationApi = (uiObj) => {
    const { isEmail, isPush, isSMS, value, selectedItems, notifyTitle, notifyContent } = uiObj
    const channels = [isEmail ? 'email' : ' ', isPush ? 'push' : ' ', isSMS ? 'sms' : ' '].filter(
        (str) => {
            return /\S/.test(str)
        }
    )
    if (value === 'group')
        return {
            title: notifyTitle,
            body: notifyContent,
            channel: channels,
            recipient: selectedItems
        }
    return {
        title: notifyTitle,
        body: notifyContent,
        channel: channels,
        recipient: value,
        tenant_members: selectedItems
    }
}
export default toNotificationApi
