import { useState } from 'react'

import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'

import FaqExpandedQuestion from './FaqExpandedQuestion'

const FaqQuestion = ({ questionElement }) => {
    const { questionTitle, questionContent } = questionElement
    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <>
            <li>
                <button
                    type="button"
                    className="question_title"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <span>{questionTitle}</span>
                    {isExpanded ? (
                        <BiUpArrowAlt size="20" className="icon" />
                    ) : (
                        <BiDownArrowAlt size="20" className="icon" />
                    )}
                </button>
            </li>
            <div style={{ padding: 10 }}>
                <FaqExpandedQuestion content={questionContent} isExpanded={isExpanded} />
            </div>
        </>
    )
}
export default FaqQuestion
