import { Table } from 'evergreen-ui'

const LoadingRows = ({ elements }) => {
    return Object.entries(elements).map(([key, value]) => {
        return (
            <Table.Row className="row" key={key} data-testid="loading-indicator">
                <Table.Cell>
                    <div className="loading gradient" />
                </Table.Cell>
                <Table.Cell>
                    <div className="loading gradient" />
                </Table.Cell>
                <Table.Cell>
                    <div className="loading gradient" />
                </Table.Cell>
                <Table.Cell>
                    <div className="loading gradient" />
                </Table.Cell>
                <Table.Cell>
                    <div className="loading gradient" />
                </Table.Cell>
            </Table.Row>
        )
    })
}

export default LoadingRows
