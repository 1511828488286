import { Spinner, Table } from 'evergreen-ui'

const LoadingCell = () => {
    return (
        <Table.Cell>
            <Spinner size={16} data-testid="spinner" />
        </Table.Cell>
    )
}

export default LoadingCell
