import '../../styles/components/sides.scss'

const ProfileEditSide = ({ onSaveClick, onReturnClick, onTextChanged, user }) => {
    return (
        <div className="profileSide_content">
            <div className="title">
                <span> Aktualizacja profilu </span>
            </div>
            <div className="optionsBox">
                <div className="optionsBox_info">
                    <div className="text">
                        <span className="text_title">Imię:</span>
                        <input
                            type="text"
                            onChange={onTextChanged}
                            placeholder={user.name}
                            name="name"
                        />
                    </div>
                    <div className="text">
                        <span className="text_title">Nazwisko:</span>
                        <input
                            type="text"
                            onChange={onTextChanged}
                            placeholder={user.surname}
                            name="surname"
                        />
                    </div>
                    <div className="text">
                        <span className="text_title">E-mail:</span>
                        <input
                            type="text"
                            onChange={onTextChanged}
                            placeholder={user.email}
                            name="email"
                        />
                    </div>
                </div>
                <div className="optionsBox_buttons">
                    <button type="button" onClick={onReturnClick}>
                        Anuluj
                    </button>
                    <button type="button" onClick={onSaveClick} className="EditButton">
                        Zapisz zmiany
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProfileEditSide
