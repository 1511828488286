import { useState } from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Tooltip } from 'evergreen-ui'
import countPages from '../utils/countPages'
import '../styles/components/paginationInput.scss'

export const PaginationInput = ({ pageCount, onBlur, resultCount, apiLimit }) => {
    const [value, setValue] = useState('')

    const handlePaginationInputn = (e) => {
        let val = value
        if (val.length === 0) {
            val = ''
        } else {
            if (val < 1) val = 1
            if (val > countPages(apiLimit, resultCount)) val = countPages(apiLimit, resultCount)
            onBlur(parseInt(val, 10))
            setValue('')
        }
    }

    const handleKeyDownPressed = (event) => {
        if (event.keyCode === 13) {
            handlePaginationInputn()
        }
    }
    const handleButtonClick = () => {
        handlePaginationInputn()
    }

    return (
        <div className="pagination_input">
            <div className="search_input">
                <input
                    className="input"
                    type="number"
                    placeholder="Numer strony..."
                    onKeyDown={handleKeyDownPressed}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                <Tooltip content="Przejdź do wybranej strony" showDelay={500}>
                    <button type="button" className="clearButton" onClick={handleButtonClick}>
                        <AiOutlineArrowRight size="15" />
                    </button>
                </Tooltip>
            </div>
        </div>
    )
}

export default PaginationInput
