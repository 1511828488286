import { Pane, Radio } from 'evergreen-ui'

const RadioPane = ({ handleRadioStateChanged, isLeftBoxDisabled, value }) => {
    return (
        <Pane
            className="radio_box"
            role="group"
            onChange={(event) => handleRadioStateChanged(event.target.value)}
        >
            <Radio
                name="group"
                size={16}
                label="Odbiorcy"
                value="users"
                disabled={isLeftBoxDisabled}
                checked={value === 'users'}
            />
            <Radio
                name="group"
                size={16}
                label="Grupy"
                value="group"
                disabled={isLeftBoxDisabled}
                checked={value === 'group'}
            />
            <Radio
                name="group"
                size={16}
                label="Wszyscy"
                value="all"
                disabled={isLeftBoxDisabled}
                checked={value === 'all'}
            />
        </Pane>
    )
}
export default RadioPane
