import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { IoClose, IoSearchOutline, IoCaretDown } from 'react-icons/io5'
import { HiOutlineSortDescending } from 'react-icons/hi'
import { VscChecklist } from 'react-icons/vsc'

import { SelectMenu, Button, toaster } from 'evergreen-ui'

import { optionChoices, optionValueChoices, filterNames } from '../static/filterOptions'
import '../styles/components/activeFilters.scss'
import AddMeterDialog from './AddContentDialogs/AddMeterDialog'
import AddMeasurementDialog from './AddContentDialogs/AddMeasurementDialog'

const Param = ({ name, value, onRemoveClick }) => {
    return (
        <div className="active_filter">
            <span>
                {filterNames[name] ?? 'Nieznany filtr: '}
                {optionValueChoices[value] ?? (value === 'undefined' ? 'nie znaleziono' : value)}
            </span>
            <button type="button" className="clearButton" onClick={() => onRemoveClick(name)}>
                <IoClose size={17} color="#FFF" />
            </button>
        </div>
    )
}

const FilterField = ({ name, options }) => {
    const [selectedValue, setSelectedValue] = useState(null)

    const [urlParams, setUrlParams] = useSearchParams()

    useEffect(() => {
        if (!urlParams.has(options[0].filter)) {
            setSelectedValue(null)
        }
    }, [urlParams])
    const handleOptionClick = (item) => {
        setSelectedValue(item)

        urlParams.delete(item.filter)
        urlParams.append(item.filter, item.value)

        setUrlParams(urlParams)
    }

    return (
        <SelectMenu
            hasFilter={false}
            hasTitle={false}
            options={options}
            height={options.length * 33}
            onSelect={handleOptionClick}
            closeOnSelect
        >
            <Button className="option" iconAfter={IoCaretDown}>
                {selectedValue === null ? name : selectedValue.label}
            </Button>
        </SelectMenu>
    )
}

const FilterSearchField = ({ name }) => {
    const [value, setValue] = useState('')
    const [urlParams, setUrlParams] = useSearchParams()

    const handleConfirm = (text) => {
        urlParams.delete('search')
        urlParams.append('search', text)
        setUrlParams(urlParams)
    }

    const handleKeyDownPressed = (event) => {
        if (event.keyCode === 13) {
            if (value === '') {
                toaster.warning('Nie podano wartości')
            } else {
                handleConfirm(value)
            }
        }
    }

    const handleButtonClick = () => {
        if (value === '') {
            toaster.warning('Nie podano wartości')
        } else {
            handleConfirm(value)
        }
    }

    return (
        <div className="search_input">
            <input
                type="text"
                placeholder={name}
                onKeyDown={handleKeyDownPressed}
                onChange={(e) => setValue(e.target.value)}
            />
            <button type="button" className="clearButton" onClick={handleButtonClick}>
                <IoSearchOutline size="17" />
            </button>
        </div>
    )
}

const ActiveFilters = ({
    onRemoveClick = () => {},
    userFilters = false,
    measurementFilters = false,
    meterFilters = false,
    documentFilters = false,
    notificationFilters = false,
    historyFilters = false,
    onMassActionSelect
}) => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    const [urlParams, setUrlParams] = useSearchParams()
    const [entries, setEntries] = useState({})
    const [massActionOptions, setMassActionOptions] = useState([])
    const [sortOptions, setSortOptions] = useState([])
    const [selectedSorting, setSelectedSorting] = useState(null)

    const [showAddMeterDialog, setShowAddMeterDialog] = useState(false)
    const [showAddUserDialog, setShowAddUserDialog] = useState(false)
    const [showAddMeasurementDialog, setShowAddMeasurementDialog] = useState(false)

    const handleRemoveParamClick = (key) => {
        urlParams.delete(key)
        setUrlParams(urlParams)
        onRemoveClick()
    }

    const handleSelectSortingClick = (item) => {
        urlParams.delete(item.sorting)
        if (item.value !== 'default') {
            urlParams.append(item.sorting, item.value)
            setSelectedSorting(item.label)
        } else setSelectedSorting(null)
        setUrlParams(urlParams)
    }

    useEffect(() => {
        setEntries(
            Object.fromEntries([...urlParams.entries()].filter((elem) => elem[0] !== 'sorting'))
        )
    }, [urlParams])

    useEffect(() => {
        if (userFilters) {
            setMassActionOptions(optionChoices.userMassActionOptions)
            setSortOptions(optionChoices.userSortOptions)
        } else if (meterFilters) {
            setMassActionOptions(optionChoices.meterMassActionOptions)
            setSortOptions(optionChoices.meterSortOptions)
        } else if (measurementFilters) {
            setMassActionOptions(optionChoices.measuerementMassActionOptions)
            setSortOptions(optionChoices.measuerementSortOptions)
        } else if (documentFilters) {
            setMassActionOptions(optionChoices.documentMassActionOptions)
            setSortOptions(optionChoices.documentSortOptions)
        } else if (notificationFilters) {
            setSortOptions(optionChoices.notificationSortOptions)
        } else if (historyFilters) {
            setSortOptions(optionChoices.historySortOptions)
        }
    }, [])

    const renderFilters = () => {
        return Object.entries(entries).map(([key, value]) => (
            <Param key={key} name={key} value={value} onRemoveClick={handleRemoveParamClick} />
        ))
    }

    const checkActivities = isModerator
        ? !notificationFilters &&
          !historyFilters &&
          !measurementFilters &&
          !meterFilters &&
          !userFilters
        : !notificationFilters &&
          !historyFilters &&
          !measurementFilters &&
          !meterFilters &&
          !userFilters

    return (
        <div>
            <div className="filters_container">
                <div id="actions">
                    <div>
                        {checkActivities && (
                            <SelectMenu
                                hasFilter={false}
                                hasTitle={false}
                                options={massActionOptions}
                                height={massActionOptions.length * 33}
                                onSelect={onMassActionSelect}
                                closeOnSelect
                            >
                                <Button className="option" iconBefore={VscChecklist}>
                                    Działania
                                </Button>
                            </SelectMenu>
                        )}

                        <SelectMenu
                            hasFilter={false}
                            hasTitle={false}
                            options={sortOptions}
                            height={sortOptions.length * 33}
                            onSelect={handleSelectSortingClick}
                            closeOnSelect
                        >
                            <Button
                                className="option"
                                iconBefore={HiOutlineSortDescending}
                                iconAfter={IoCaretDown}
                            >
                                {selectedSorting === null ? 'Sortowanie' : selectedSorting}
                            </Button>
                        </SelectMenu>
                    </div>
                </div>
                {userFilters && (
                    <div className="filters_row">
                        <div className="choose_row">
                            <FilterField
                                name="Stan weryfikacji"
                                options={optionChoices.userStateOptions}
                            />
                            {isModerator && (
                                <FilterField
                                    name="Typ odbiorcy"
                                    options={optionChoices.userTypeOptions}
                                />
                            )}
                            {isModerator && (
                                <FilterField
                                    name="Data utworzenia"
                                    options={optionChoices.userCreatedAtOptions}
                                />
                            )}
                            {isModerator && (
                                <FilterField
                                    name="Data modyfikacji"
                                    options={optionChoices.userUpdatedAtOptions}
                                />
                            )}
                        </div>
                        <div className="search_row">
                            {isModerator && (
                                <Button
                                    onClick={() => setShowAddMeterDialog(true)}
                                    className="button"
                                >
                                    Dodaj odbiorcę
                                </Button>
                            )}
                            <FilterSearchField name="Szukaj odbiorcy..." />
                        </div>
                    </div>
                )}
                {measurementFilters && (
                    <div className="filters_row">
                        <div className="choose_row">
                            <FilterField
                                name="Stan weryfikacji"
                                options={optionChoices.measurementStateOptions}
                            />
                            {isModerator && (
                                <FilterField
                                    name="Data utworzenia"
                                    options={optionChoices.measurementCreatedAtOptions}
                                />
                            )}
                        </div>
                        <Button
                            onClick={() => setShowAddMeasurementDialog(true)}
                            className="button"
                        >
                            Dodaj odczyt
                        </Button>
                    </div>
                )}
                {meterFilters && (
                    <div className="filters_row">
                        <div className="choose_row">
                            <FilterField
                                name="Typ licznika"
                                options={optionChoices.meterTypeOptions}
                            />
                            {isModerator && (
                                <FilterField
                                    name="Data utworzenia"
                                    options={optionChoices.meterCreatedAtOptions}
                                />
                            )}
                        </div>
                        <div className="search_row">
                            <Button onClick={() => setShowAddMeterDialog(true)} className="button">
                                Dodaj licznik
                            </Button>
                            <FilterSearchField name="Szukaj licznika..." />
                        </div>
                    </div>
                )}
                {documentFilters && (
                    <div className="filters_row">
                        <div className="choose_row">
                            {isModerator && (
                                <FilterField
                                    name="Data utworzenia"
                                    options={optionChoices.documentCreatedAtOptions}
                                />
                            )}
                            <FilterField
                                name="Płatności"
                                options={optionChoices.documentPaymentsOptions}
                            />
                        </div>
                    </div>
                )}
                {notificationFilters && (
                    <div className="filters_row">
                        <div className="choose_row">
                            <FilterField
                                name="Wysłane automatycznie"
                                options={optionChoices.notificationIsAutomaticOptions}
                            />
                            <FilterField
                                name="Kanał"
                                options={optionChoices.notificationChannelOptions}
                            />
                            <FilterField
                                name="Odbiorca"
                                options={optionChoices.notificationRecipientOptions}
                            />
                            <FilterField
                                name="Data utworzenia"
                                options={optionChoices.notificationCreatedAtOptions}
                            />
                        </div>
                    </div>
                )}
                {historyFilters && (
                    <div className="filters_row">
                        <div className="choose_row">
                            <FilterField
                                name="Wykluczenie"
                                options={optionChoices.historyExcludeOptions}
                            />
                            {isModerator && (
                                <FilterField
                                    name="Data utworzenia"
                                    options={optionChoices.historyCreatedAtOptions}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="filters_container">
                {Object.keys(entries).length === 0 ? null : <>{renderFilters()}</>}
            </div>
            <AddMeterDialog
                isShown={showAddMeterDialog}
                setIsShown={() => setShowAddMeterDialog(false)}
            />
            <AddMeasurementDialog
                isShown={showAddMeasurementDialog}
                setIsShown={() => setShowAddMeasurementDialog(false)}
            />
        </div>
    )
}

export default ActiveFilters
