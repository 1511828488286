import { createSlice } from '@reduxjs/toolkit'

export const PaymentSlice = createSlice({
    name: 'payment',
    initialState: {
        documentPK: null,
        userPK: null,
        measurementID: null,
        amount: null,
        tenant: null
    },
    reducers: {
        addPaymentData: {
            reducer(state, action) {
                state.documentPK = action.payload.documentID
                state.userPK = action.payload.userID
                state.measurementID = action.payload.measurementID
                state.amount = action.payload.amount * 100
                state.tenant = action.payload.tenant
            },
            prepare(documentID, userID, measurementID, amount, tenant) {
                return {
                    payload: {
                        documentID,
                        userID,
                        measurementID,
                        amount,
                        tenant
                    }
                }
            }
        }
    }
})

export const { addPaymentData } = PaymentSlice.actions
export default PaymentSlice.reducer
