const SummaryButtonBox = ({
    handlePrevStepButtonClick,
    handleSendNotificationButton,
    takenSteps,
    currentStep,
    disabled
}) => {
    return (
        <div className="button_box">
            <button type="button" name="2" onClick={handlePrevStepButtonClick} disabled={disabled}>
                Cofnij
            </button>
            <button
                type="button"
                className={currentStep === 2 ? 'highlightedButton' : ''}
                onClick={handleSendNotificationButton}
                disabled={disabled}
            >
                Zatwierdź i wyślij
            </button>
        </div>
    )
}
export default SummaryButtonBox
