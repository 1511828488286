import SelectMenuCustom from './SelectMenuCustom'
import SelectedElements from './SelectedElements'
import groups from '../../../static/groupTypes'

const RenderSelects = ({ value, users, selected, setSelected, isDisabled }) => {
    if (value === 'group') {
        return (
            <>
                <SelectMenuCustom
                    elemenets={Object.entries(groups).map(([label, val]) => ({
                        label: val.label,
                        value: val.value,
                        disabled: isDisabled
                    }))}
                    title="Wybierz grupę"
                    selected={selected}
                    setSelected={setSelected}
                    closeOnSelect
                />
                <SelectedElements
                    selected={
                        Object.values(groups).filter((ele) => ele.value === selected)[0]?.label
                    }
                    elements={users}
                    setSelected={setSelected}
                    title="Wybrana grupa: "
                />
            </>
        )
    }
    if (value === 'users') {
        return (
            <>
                <SelectMenuCustom
                    isMultiSelect
                    elemenets={Object.entries(users).map(([index, user]) => ({
                        label: user?.isCompany ? user.companyName : user.nameSurname,
                        value: index,
                        disabled: isDisabled
                    }))}
                    selected={selected}
                    setSelected={setSelected}
                    title="Wybierz odbiorcę"
                />
                <SelectedElements
                    selected={selected}
                    elements={users}
                    setSelected={setSelected}
                    title="Wybrane konta: "
                    isDisabled={isDisabled}
                />
            </>
        )
    }
    if (value === 'all') {
        return (
            <div className="selectedUsers">
                <div className="titlebox_bottom">
                    <div className="title_bottom">Wybrano wszystkich odbiorców systemu</div>
                </div>
            </div>
        )
    }
    return null
}
export default RenderSelects
